import React, { useContext, useEffect, useReducer } from 'react'
import { ConfigContext } from '../../../context/configContext'
import { Button, message, Modal } from 'antd'
import { simpleReducer } from '../../../helper'
import {
  useMutateAdminPartnerTake,
  useMutateAdminPartnerUntake
} from '../../../api/react-query/adminPartners'
import { apiErrorCodes } from '../../../AppConfig'

const mode = {
  take: 'take',
  untake: 'untake'
}

const labels = {
  [mode.take]: 'Взять анкету',
  [mode.untake]: 'Высвободить анкету'
}

const initialState = {
  currentMode: null,
  isOpenModal: false,
  modalError: null
}

function TakePartnerButton({ partnerId, profile, verifier }) {
  const { isSecurity, user } = useContext(ConfigContext)
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    let currentMode = null
    if (profile?.allowSecurityTake && verifier?.id === user?.id) {
      currentMode = mode.untake
    }
    if (profile?.allowSecurityTake && verifier?.id !== user?.id) {
      currentMode = mode.take
    }
    setState({ currentMode })
  }, [profile?.allowSecurityTake, verifier, user])

  const {
    mutate: takePartner,
    data: dataPartnerTake,
    isSuccess: isSuccessTake,
    isError: isErrorTake,
    isLoading: isLoadingTake
  } = useMutateAdminPartnerTake()

  useEffect(() => {
    if (isSuccessTake && dataPartnerTake?.isSuccess) {
      setState(initialState)
    } else if (
      !dataPartnerTake?.isSuccess &&
      dataPartnerTake?.errorCode === apiErrorCodes.partnerTaken
    ) {
      message.error('Ошибка получения данных о партнере')
      setState({ modalError: dataPartnerTake?.errorMessage, isOpenModal: true })
    } else if (isErrorTake && !dataPartnerTake?.isSuccess) {
      message.error(dataPartnerTake?.errorMessage || 'Ошибка получения данных о партнере')
    }
  }, [isSuccessTake, isErrorTake, dataPartnerTake])

  const {
    mutate: untakePartner,
    data: dataPartnerUntake,
    isSuccess: isSuccessUntake,
    isError: isErrorUntake,
    isLoading: isLoadingUntake
  } = useMutateAdminPartnerUntake()

  useEffect(() => {
    if (isSuccessUntake && dataPartnerUntake?.isSuccess) {
      /* empty */
    } else if (isErrorUntake && !dataPartnerUntake?.isSuccess) {
      message.error(dataPartnerUntake?.errorMessage || 'Ошибка получения данных о партнере')
    }
  }, [isSuccessUntake, isErrorUntake, dataPartnerUntake])

  const handleClick = () => {
    if (state.currentMode === mode.take) {
      takePartner({ id: partnerId, data: { force: false } })
    }
    if (state.currentMode === mode.untake) {
      untakePartner({ id: partnerId })
    }
  }

  const handleOk = () => {
    if (state.currentMode === mode.take) {
      takePartner({ id: partnerId, data: { force: true } })
    }
  }

  const handleCancel = () => {
    setState({ ...initialState, currentMode: state.currentMode })
  }

  if (!isSecurity || !profile?.allowSecurityTake) {
    return null
  }

  return (
    <>
      {labels[state.currentMode] && (
        <Button
          loading={isLoadingTake || isLoadingUntake}
          onClick={handleClick}
          style={
            state.currentMode === mode.take
              ? { backgroundColor: 'transparent', color: '#19bb4f', borderColor: '#19bb4f' }
              : null
          }
        >
          {labels[state.currentMode]}
        </Button>
      )}
      <Modal
        visible={state.isOpenModal}
        title="Анкета в работе"
        okText="Взять в работу"
        onCancel={handleCancel}
        onOk={handleOk}
        okButtonProps={{ loading: isLoadingTake || isLoadingTake }}
      >
        {state.modalError}
      </Modal>
    </>
  )
}

export default TakePartnerButton
