import { Form, Modal } from 'antd'
import React, { useEffect, useMemo } from 'react'

import { InnFormItem } from '../../../Common'
import {
  validateInn,
  validateFullInn,
  isShownSberIcon,
  isShownTinkoffIcon
} from '../../../../helper'
import { errors, profileScreen } from '../../../../translates'

const layout = {
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  },
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 }
  }
}

const EditInnDetailsData = ({
  visible = false,
  onCancel = () => {},
  loading = false,
  innDetails,
  partnerId,
  onChange,
  profile,
  backendFieldsErrors = {}
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (innDetails) {
      form.setFieldsValue({ innDetails: innDetails })
    }
  }, [innDetails])

  useEffect(() => {
    if (Object.keys(backendFieldsErrors).length > 0) {
      form.setFields(
        Object.keys(backendFieldsErrors).map(key => ({
          name: ['innDetails', key],
          errors: backendFieldsErrors[key]
        }))
      )
    }
  }, [backendFieldsErrors])

  const handleOnSave = () => {
    form
      .validateFields()
      .then(fields => {
        if (!form.isFieldsTouched()) return onCancel()
        onChange({ data: fields.innDetails, id: partnerId })
      })
      .catch(() => {})
  }

  const okButtonProps = useMemo(() => ({ className: 'green', loading }), [loading])

  return (
    <Modal
      width={700}
      visible={visible}
      title="Редактирование ИНН данных партнера"
      onCancel={onCancel}
      okText="Сохранить"
      cancelText="Отменить"
      okButtonProps={okButtonProps}
      onOk={handleOnSave}
      destroyOnClose
    >
      <Form {...layout} name="editPartnerInnDetailsData" form={form} scrollToFirstError>
        <InnFormItem
          size="middle"
          rules={[
            { required: true, message: profileScreen.input.inn.message, validator: validateInn },
            {
              message: errors.innCheckSum,
              validator: (rule, value) => validateFullInn({ rule, inn: value })
            }
          ]}
          classNameInput="noScrollNumber"
          showSberIcon={isShownSberIcon('inn', profile)}
          showTinkoffIcon={isShownTinkoffIcon('inn', profile)}
        />
      </Form>
    </Modal>
  )
}
export default EditInnDetailsData
