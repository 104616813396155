import { decorate, observable, action } from 'mobx'
import moment from 'moment-timezone'
import env from '../../env'
import { individual, partnerStatusCodes, selfEmployed } from '../../AppConfig'
import { formatDateForFrontend, formatDrivingNumber, formatSnils } from '../../helper'

export const getListByFileId = data => {
  if (!data?.fileId) return []
  return [
    {
      uid: '-1',
      name: `${data.fileId}.png`,
      status: 'done',
      response: {
        fileId: data.fileId
      },
      url: `${env.apiEndpoint}partner/file/${data.fileId}`
    }
  ]
}
const getErrors = profile => [
  profile.personalData?.statusReason,
  profile.bankDetails?.statusReason,
  profile.innDetails?.statusReason,
  profile.passportPhoto1?.statusReason,
  profile.passportPhoto2?.statusReason,
  profile.passportPhoto3?.statusReason,
  profile.passportPhotoTmpReg?.statusReason,
  profile.passportPhotoExtraReg?.statusReason,
  profile.snilsPhoto?.statusReason,
  profile.drivingLicencePhoto1?.statusReason,
  profile.drivingLicencePhoto2?.statusReason,
  profile.lmkMainPhoto?.statusReason,
  profile.lmkAllowancePhoto?.statusReason,
  profile.lmkAttestationPhoto?.statusReason,
  profile?.personalData?.legalForm === selfEmployed && profile.mnregPhoto?.statusReason,
  profile?.personalData?.legalForm === individual && profile.egripPhoto?.statusReason,
  profile.trusteeAgreement?.statusReason,
  profile.trusteeConfirmation?.statusReason,
  profile.legalCapacityConfirmation?.statusReason,
  profile.trusteePassport?.statusReason,
  profile?.personalData?.vaccinated === true && profile?.vaccinacionPhoto?.statusReason,
  profile.form?.statusReason
]
class partnerProfile {
  id = null
  profile = {}
  documents = []
  form = {}
  errors = []
  visible = {}
  required = {}
  isCorrectionStatus = false
  updateForm = form => {
    this.form = form
  }

  setPartnerProfile = ({ id, profile = {}, documents = [] }) => {
    this.id = id
    this.profile = profile
    this.documents = documents

    const {
      personalData = {},
      bankDetails = {},
      lmkDetails = {},
      bankDetailsPhoto = {},
      passportPhoto1 = {},
      passportPhoto2 = {},
      passportPhoto3 = {},
      passportPhotoTmpReg = {},
      passportPhotoExtraReg = {},
      innPhoto = {},
      mnregPhoto = {},
      egripPhoto = {},
      trusteeAgreement = {},
      trusteeConfirmation = {},
      legalCapacityConfirmation = {},
      trusteePassport = {},
      vaccinacionPhoto = {},
      snilsPhoto = {},
      drivingLicencePhoto1 = {},
      drivingLicencePhoto2 = {},
      lmkMainPhoto = {},
      lmkAllowancePhoto = {},
      lmkAttestationPhoto = {},
      form,
      ...rest
    } = profile

    this.form = {
      personalData: {
        ...personalData,
        passport: {
          ...personalData?.passport,
          issueDate: personalData?.passport?.issueDate
            ? moment(personalData.passport.issueDate).format('DD.MM.yyyy')
            : ''
        },
        drivingLicence: {
          ...personalData?.drivingLicence,
          seriesNumber: formatDrivingNumber(personalData?.drivingLicence?.seriesNumber),
          issueDate: personalData?.drivingLicence?.issueDate
            ? moment(personalData.drivingLicence.issueDate).format('DD.MM.yyyy')
            : ''
        },
        snils: formatSnils(personalData?.snils),
        birthDate: personalData?.birthDate
          ? moment(personalData?.birthDate).format('DD.MM.yyyy')
          : ''
      },
      bankDetails,
      lmkDetails: {
        ...lmkDetails,
        examinationDate: formatDateForFrontend(lmkDetails?.examinationDate),
        examinationExpireDate: formatDateForFrontend(lmkDetails?.examinationExpireDate),
        attestationDate: formatDateForFrontend(lmkDetails?.attestationDate),
        attestationExpireDate: formatDateForFrontend(lmkDetails?.attestationExpireDate)
      },
      bankDetailsPhoto: getListByFileId(bankDetailsPhoto),
      passportPhoto1: getListByFileId(passportPhoto1),
      passportPhoto2: getListByFileId(passportPhoto2),
      passportPhoto3: getListByFileId(passportPhoto3),
      passportPhotoTmpReg: getListByFileId(passportPhotoTmpReg),
      passportPhotoExtraReg: getListByFileId(passportPhotoExtraReg),
      innPhoto: getListByFileId(innPhoto),
      mnregPhoto: getListByFileId(mnregPhoto),
      egripPhoto: getListByFileId(egripPhoto),
      trusteeAgreement: getListByFileId(trusteeAgreement),
      trusteeConfirmation: getListByFileId(trusteeConfirmation),
      legalCapacityConfirmation: getListByFileId(legalCapacityConfirmation),
      trusteePassport: getListByFileId(trusteePassport),
      vaccinacionPhoto: getListByFileId(vaccinacionPhoto),
      snilsPhoto: getListByFileId(snilsPhoto),
      drivingLicencePhoto1: getListByFileId(drivingLicencePhoto1),
      drivingLicencePhoto2: getListByFileId(drivingLicencePhoto2),
      lmkMainPhoto: getListByFileId(lmkMainPhoto),
      lmkAllowancePhoto: getListByFileId(lmkAllowancePhoto),
      lmkAttestationPhoto: getListByFileId(lmkAttestationPhoto),
      editInfo: {
        ...form,
        personalData: {
          ...form?.personalDetails,
          passport: {
            ...form?.personalDetails?.passport,
            issueDate: form?.personalDetails?.passport?.issueDate
              ? moment(form?.personalDetails?.passport?.issueDate).format('DD.MM.yyyy')
              : ''
          },
          drivingLicence: {
            ...form?.personalDetails?.drivingLicence,
            seriesNumber: formatDrivingNumber(form?.personalDetails?.drivingLicence?.seriesNumber),
            issueDate: form?.personalDetails?.drivingLicence?.issueDate
              ? moment(form?.personalDetails?.drivingLicence?.issueDate).format('DD.MM.yyyy')
              : ''
          }
        },
        ...form?.bankDetails,
        passportPhoto1: getListByFileId(form?.passportPhoto1),
        passportPhoto2: getListByFileId(form?.passportPhoto2),
        passportPhoto3: getListByFileId(form?.passportPhoto3),
        passportPhotoTmpReg: getListByFileId(form?.passportPhotoTmpReg),
        passportPhotoExtraReg: getListByFileId(form?.passportPhotoExtraReg),
        innPhoto: getListByFileId(form?.innPhoto),
        bankDetailsPhoto: getListByFileId(form?.bankDetailsPhoto),
        egripPhoto: getListByFileId(form?.egripPhoto),
        trusteeAgreement: getListByFileId(form?.trusteeAgreement),
        trusteeConfirmation: getListByFileId(form?.trusteeConfirmation),
        legalCapacityConfirmation: getListByFileId(form?.legalCapacityConfirmation),
        trusteePassport: getListByFileId(form?.trusteePassport),
        vaccinacionPhoto: getListByFileId(form?.vaccinacionPhoto),
        snilsPhoto: getListByFileId(form?.snilsPhoto),
        drivingLicencePhoto1: getListByFileId(form?.drivingLicencePhoto1),
        drivingLicencePhoto2: getListByFileId(form?.drivingLicencePhoto2),
        lmkMainPhoto: getListByFileId(form?.lmkMainPhoto),
        lmkAllowancePhoto: getListByFileId(form?.lmkAllowancePhoto),
        lmkAttestationPhoto: getListByFileId(form?.lmkAttestationPhoto),
        visibleInForm: Object.keys(form || {}).reduce((prev, curr) => {
          if (typeof form?.[curr] !== 'object') return prev
          prev[curr] = form?.[curr]?.visible
          return prev
        }, {})
      },
      ...rest
    }
    this.errors = getErrors(profile)
    this.visible = Object.keys(profile).reduce((prev, curr) => {
      if (typeof profile[curr] !== 'object') return prev
      prev[curr] = profile[curr]?.visible && profile[curr]?.allowEdit
      return prev
    }, {})

    this.required = Object.keys(profile).reduce((prev, curr) => {
      if (typeof profile[curr] !== 'object') return prev
      prev[curr] = profile[curr]?.required
      return prev
    }, {})
    this.isCorrectionStatus = profile?.statusCode === partnerStatusCodes.correction
  }
}

export default decorate(partnerProfile, {
  id: observable,
  profile: observable,
  documents: observable,
  form: observable,
  visible: observable,
  isCorrectionStatus: observable,
  required: observable,
  setPartnerProfile: action,
  updateForm: action
})
