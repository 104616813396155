import React from 'react'
import { Col, List } from 'antd'
import moment from 'moment-timezone'
import { AppConfig } from '../../../AppConfig'
import { CheckCircleTwoTone, CloseSquareTwoTone } from '@ant-design/icons'
import { sortByParam } from '../../../helper'

const countCity = 2
const coordinatorGroups = [
  // AppConfig.groups.externalcoordinators,
  // AppConfig.groups.coordinators,
  AppConfig.groups.coordinator
]
const coordinatorLabels = {
  // [AppConfig.groups.externalcoordinators]: 'Внешний координатор',
  // [AppConfig.groups.coordinators]: 'Координатор',
  [AppConfig.groups.coordinator]: 'Координатор'
}

function CoordinatorItem({ item, cityButton = null, onClickPriority }) {
  const getPriorityCities = () => {
    return sortByParam(item.priorityCities, 'cityName')
      ?.slice(0, countCity)
      ?.map(city => city?.cityName)
      .join(', ')
  }

  const DISABLE_COLS = true

  return (
    <List.Item>
      <Col span={9}>
        {item.userName}
        {coordinatorGroups.includes(item.userGroup) && (
          <>
            <br />({coordinatorLabels[item.userGroup]})
          </>
        )}
      </Col>
      <Col span={5}>
        {item.loginTimestamp
          ? moment(item.loginTimestamp).format(AppConfig.formats.shortDateAndTime)
          : ''}
      </Col>
      <Col span={5}>
        {item.isOnline ? (
          <CheckCircleTwoTone twoToneColor="#00AF64" className="iconTime" />
        ) : (
          <CloseSquareTwoTone twoToneColor="red" className="iconTime" />
        )}
        {item.isOnlineTimestamp
          ? moment(item.isOnlineTimestamp).format(AppConfig.formats.shortDateAndTime)
          : ''}
      </Col>
      <Col span={5}>{item.legalForm}</Col>
      {!DISABLE_COLS && (
        <Col span={6}>
          {!item?.priorityCities?.length && 'не задано '}
          {item?.priorityCities?.length > 0 && getPriorityCities()}
          {item?.priorityCities?.length > countCity && '...'} {cityButton !== null && cityButton}
        </Col>
      )}
      {!DISABLE_COLS && (
        <Col span={3}>
          <div>
            {item?.partnerVerificationSelector === AppConfig.coordinatorTakePriority.new ? (
              <CheckCircleTwoTone twoToneColor="#00AF64" className="iconTime" />
            ) : (
              <span className="emptyIcon" />
            )}
            {item?.partnerVerificationSelector === AppConfig.coordinatorTakePriority.new ? (
              <>Новая</>
            ) : (
              <a onClick={() => onClickPriority(item, AppConfig.coordinatorTakePriority.new)}>
                Новая
              </a>
            )}
          </div>
          <div>
            {item?.partnerVerificationSelector === AppConfig.coordinatorTakePriority.old ? (
              <CheckCircleTwoTone twoToneColor="#00AF64" className="iconTime" />
            ) : (
              <span className="emptyIcon" />
            )}
            {item?.partnerVerificationSelector === AppConfig.coordinatorTakePriority.old ? (
              <>Старая</>
            ) : (
              <a onClick={() => onClickPriority(item, AppConfig.coordinatorTakePriority.old)}>
                Старая
              </a>
            )}
          </div>
          {item.userGroup !== AppConfig.groups.externalcoordinators && (
            <div>
              {item?.partnerVerificationSelector ===
              AppConfig.coordinatorTakePriority.bankRequisite ? (
                <CheckCircleTwoTone twoToneColor="#00AF64" className="iconTime" />
              ) : (
                <span className="emptyIcon" />
              )}
              {item?.partnerVerificationSelector ===
              AppConfig.coordinatorTakePriority.bankRequisite ? (
                <>Анкета БР</>
              ) : (
                <a
                  onClick={() =>
                    onClickPriority(item, AppConfig.coordinatorTakePriority.bankRequisite)
                  }
                >
                  Анкета БР
                </a>
              )}
            </div>
          )}
        </Col>
      )}
    </List.Item>
  )
}

export default CoordinatorItem
