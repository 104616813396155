import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'

import { AppConfig } from '../../../../AppConfig'
import { CreateActWithAssets } from './'
import { Button, message } from 'antd'
import { useQueryAdminPartnerAssets } from '../../../../api/react-query/adminPartners'

function ReturnAssetsModalButton({ partner }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [assets, setAssets] = useState([])

  const handleShowModal = () => setIsModalVisible(true)
  const handleHideModal = () => setIsModalVisible(false)

  const { data, isError, isSuccess, isLoading } = useQueryAdminPartnerAssets(partner.id, {
    enabled: !!partner.id && !!isModalVisible
  })

  useEffect(() => {
    if (isSuccess) {
      const mappedData = data?.result?.map(a => ({
        ...a,
        id: a.assetId,
        price: a.assetPrice,
        existQuantity: a.quantity,
        name: a?.assetName,
        title: a?.assetName + ' (выдано партнеру ' + a?.quantity + 'шт)'
      }))
      setAssets(mappedData)
    } else if (isError) {
      console.error(data)
      message.error('Ошибка получения списка имущества')
    }
  }, [isSuccess, isError, data])

  return (
    <>
      <Button
        type="primary"
        onClick={handleShowModal}
        size="small"
        disabled={!partner?.profile?.assets?.allowReturn}
      >
        Вернуть имущество
      </Button>
      <CreateActWithAssets
        visible={isModalVisible}
        loading={isLoading}
        onClose={handleHideModal}
        list={assets}
        partner={partner}
        actType={AppConfig.assetActTypes.return}
        title="Вернуть имущество"
        confirmTitle="Создать акт возврата имущества?"
        listTitle="Список возвращаемого партнером имущества:"
        content={`Акт возврата имущества от ${moment().format(AppConfig.formats.shortDate)}`}
      />
    </>
  )
}

export default ReturnAssetsModalButton
