import { Form, Input } from 'antd'
import React, { useMemo } from 'react'
import SberIcon from '../SberIcon'
import TinkoffIcon from '../TinkoffIcon'

const NameFormItem = ({
  label = 'ФИО партнера',
  rules = undefined,
  requiredRule = true,
  messageRule = 'Пожалуйста, укажите ФИО партнера',
  size = 'large',
  placeholder = 'Иванов Иван Иванович',
  name = 'name',
  wrapperClassName = '',
  disabled = false,
  showSberIcon = false,
  showTinkoffIcon = false,
  ...rest
}) => {
  const labelParam = useMemo(() => {
    return showSberIcon || showTinkoffIcon ? (
      <>
        {label}
        {showSberIcon && <SberIcon style={{ marginLeft: '0.5rem' }} />}
        {showTinkoffIcon && <TinkoffIcon style={{ marginLeft: '0.5rem' }} />}
      </>
    ) : (
      label
    )
  }, [label, showSberIcon, showTinkoffIcon])

  return (
    <div className={wrapperClassName}>
      <Form.Item
        {...rest}
        name={name}
        label={labelParam}
        rules={rules ?? [{ required: requiredRule, message: messageRule }]}
      >
        <Input size={size} placeholder={placeholder} disabled={disabled} />
      </Form.Item>
    </div>
  )
}
export default NameFormItem
