import env from './env.json'
import { getHeaders } from './api/react-query/api'
import { ADMIN_AUTH_PAGE, ADMIN_PARTNERS_PAGE } from './routes'
import { message } from 'antd'
import { AuthenticationContext } from './plugins/react-adal/react-adal'

const showError = (msg = 'Неудачная попытка авторизации') => message.error(msg)

export const adalConfig = {
  popUp: true,
  instance: env.ADALInstance,
  redirectUri: env.ADALRedirectUri,
  clientId: env.ADALClientId,
  cacheLocation: 'localStorage',
  tenant: 'adfs',
  extraQueryParameter: 'scope=openid+email',
  endpoints: {
    api: '/'
  },
  callback: (errorDesc, token, error) => {
    if (error || !token) {
      showError()
    } else {
      fetch(env.apiEndpoint + 'admin/auth/adfs', {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
          token: localStorage.getItem('adal.access.token.key')
        })
      })
        .then(r => {
          if (r.status === 401) {
            window.location.pathname = ADMIN_AUTH_PAGE
            return Promise.resolve({})
          }
          return r.json()
        })
        .then(r => {
          if (r.isSuccess) {
            if (process.env.NODE_ENV === 'development') {
              document.cookie = 'user_a=' + token
            }
            window.location.pathname = ADMIN_PARTNERS_PAGE
          } else {
            showError(r?.errorMessage)
          }
        })
        .catch(e => showError(e?.errorMessage))
    }
  }
}
export const ADALAuthContext = new AuthenticationContext(adalConfig)
