import React, { useMemo, useState, useEffect, useReducer, useRef } from 'react'
import { PageHeader, Alert } from 'antd'
import { Link } from 'react-router-dom'

import { profileScreen } from '../../../translates'
import FormUploadImage from '../FormUploadImage/FormUploadImage'
import { AppConfig } from '../../../AppConfig'
import { PROFILE_MAIN_PAGE } from '../../../routes'
import { simpleReducer } from '../../../helper'
import ModalUploadPhoto from '../ModalUploadPhoto/ModalUploadPhoto'

const initialState = {
  isVisibleUploadModal: false,
  isHideOkUploadModal: false
}

const passportRegList = [
  profileScreen.input.passportPhoto2.name,
  profileScreen.input.passportPhotoExtraReg.name
]

const Documents = ({
  title = profileScreen.title3,
  form,
  trigger,
  documents = AppConfig.documentNameList,
  required = {},
  filterDocuments = null,
  onUpdate = () => {},
  onUploading = () => {},
  onDelete = () => {},
  onlyJpegForPassport = false,
  onPhotoDoc = () => {},
  onInnIconClick = () => {},
  disabledUploadModal = false,
  hidePhotoDoc = false
}) => {
  const [state, setState] = useReducer(simpleReducer, initialState)
  const buttonRef = useRef(null)

  const visibleDocuments = useMemo(
    () => (filterDocuments ? documents.filter(filterDocuments) : documents),
    [documents, filterDocuments]
  )

  const [inForm, setInForm] = useState([])

  useEffect(() => {
    setInForm(
      visibleDocuments.filter(d => form.getFieldValue(profileScreen.input[d]?.name)?.length)
    )
  }, [form, visibleDocuments, trigger])

  const handleUpdate = (item, info) => {
    onUpdate(item, info)
  }

  const handleUploading = (item, info) => {
    const inputs = profileScreen.input
    if (
      item?.name === inputs.passportPhoto2.name &&
      info?.file?.percent === 0 &&
      !inForm?.includes(inputs.passportPhotoExtraReg.name) &&
      !disabledUploadModal
    ) {
      handleOpenModal()
    }
    onUploading(item, info)
  }

  const handleOpenModal = (isHideOk = false) => {
    setState({ isVisibleUploadModal: true, isHideOkUploadModal: isHideOk })
  }

  const handleCloseModal = () => setState({ isVisibleUploadModal: false })

  const handleUploadExtraReg = () => {
    buttonRef?.current?.click()
    handleCloseModal()
  }

  const handleExtraRegIconClick = (item, uploading) => {
    if (item?.name === profileScreen.input.passportPhotoExtraReg.name && !uploading) {
      handleOpenModal(true)
    }
  }

  if (!visibleDocuments.length) return null

  return (
    <>
      {title && <PageHeader title={title} />}
      {!hidePhotoDoc && (
        <Alert
          style={{ marginBottom: '1rem' }}
          message="Что важно учесть"
          description={
            <ul className="photo-doc-alert">
              <li>Тексты, печати, росписи и фотографии чётко видны</li>
              <li>Нет бликов и засветов</li>
              <li>
                <Link
                  to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.photoDoc}`}
                  onClick={onPhotoDoc}
                >
                  Образец и инструкция как правильно сделать фотографии
                </Link>
              </li>
            </ul>
          }
          type="warning"
          showIcon
        />
      )}
      {visibleDocuments.map(d => (
        <FormUploadImage
          key={d}
          item={profileScreen.input[d] || {}}
          required={required[d]}
          disabled={inForm.includes(d)}
          onUpdate={handleUpdate}
          onUploading={handleUploading}
          onDelete={params => {
            const index = inForm.indexOf(params.name)
            const inFormCopy = [...inForm]
            if (index !== -1) {
              inFormCopy.splice(index, 1)
            }
            onDelete(params)
            setInForm(inFormCopy)
          }}
          onlyJpegForPassport={onlyJpegForPassport}
          markPassportRegBlock={
            visibleDocuments.includes(profileScreen.input.passportPhotoExtraReg.name) &&
            passportRegList.includes(d)
          }
          onExtraRegIconClick={handleExtraRegIconClick}
          onInnIconClick={onInnIconClick}
          {...(d === profileScreen.input.passportPhotoExtraReg.name && { ref: buttonRef })}
        />
      ))}
      <ModalUploadPhoto
        isVisible={state.isVisibleUploadModal && !disabledUploadModal}
        onCancel={handleCloseModal}
        onOk={handleUploadExtraReg}
        isHideOk={state.isHideOkUploadModal}
      />
    </>
  )
}
export default Documents
