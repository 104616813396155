import React from 'react'
import { Form, Select } from 'antd'
import { profileScreen } from '../../../../translates'
import { AppConfig } from '../../../../AppConfig'

const { Option } = Select

function RegistrationSelectorFormItem({ onChange, required = false }) {
  return (
    <Form.Item
      name={['personalData', profileScreen.input.registrationKind.name]}
      label={profileScreen.input.registrationKind.label}
      rules={[{ required, message: profileScreen.input.registrationKind.message }]}
    >
      <Select
        size="large"
        onChange={onChange}
        placeholder={profileScreen.input.registrationKind.placeholder}
      >
        {Object.keys(AppConfig.registrationTypes).map(key => (
          <Option key={key} value={key}>
            {AppConfig.registrationTypeLabels[key]}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default RegistrationSelectorFormItem
