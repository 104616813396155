import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import MobileHeader from '../../../components/Partner/MobileHeader/MobileHeader'
import { Alert, Button, Checkbox, Form, Layout, message, Row, Spin, Typography } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import ErrorBlock from '../../../components/Partner/Profile/ErrorBlock'
import {
  partnerStatusCodes,
  employmentTypeStatusCodes,
  individual,
  selfEmployed,
  AppConfig
} from '../../../AppConfig'
import { errors, offerScreen, profileContractScreen, profileScreen } from '../../../translates'
import {
  createApiDataFromForm,
  createFormDataFromApi,
  digestMessage,
  simpleReducer,
  handleMyTax,
  validateAllPhotosUploading
} from '../../../helper'
import './ProfileEditInfoScreenStyle.css'
import '../../../components/Partner/Profile/ProfileStyle.css'
import { LeftOutlined } from '@ant-design/icons'
import { useStores } from '../../../Store/Store'
import {
  useGetAssemblyDeliveryPolicy,
  useGetCyberSecurityPolicyQuery,
  useGetEditEmploymentTypeForm,
  useGetOffer,
  useMutatePostEmploymentTypeForm
} from '../../../api/react-query/partners'
import { formLayout, nbsp, partnerRoles4MultiOffer, policyTypes } from '../../../Constants'
import { PROFILE_MAIN_PAGE } from '../../../routes'
import BankDetails from '../../../components/Partner/Profile/BankDetails'
import Documents from '../../../components/Partner/Profile/Documents'
import { EmailFormItem } from '../../../components/Common'
import { PROFILE_EMPLOYMENT_TYPE_STATE_INIT } from './ProfileMainScreen'

const { Content } = Layout
const { Title, Text } = Typography

const initialOffersData = {
  roleOffer: null,
  cyberSecurityPolicy: null,
  assemblyDeliveryPolicy: null
}

function ProfileEditEmploymentTypeScreen({ extState, setExtState }) {
  const {
    partnerProfile: {
      profile,
      profile: { employmentTypeFormStatus },
      setPartnerProfile
    }
  } = useStores()
  const history = useHistory()
  const [form] = Form.useForm()
  const [isTouched, setIsTouched] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})
  const [offersData, setOffersData] = useReducer(simpleReducer, initialOffersData)

  const role = useMemo(() => profile?.personalData?.roleCode, [profile?.personalData?.roleCode])

  const { data: dataRoleOffer, isError: isErrorRoleOffer } = useGetOffer(role, {
    enabled: Boolean(role)
  })
  const { data: dataCyberSecurityPolicy, isError: isErrorCyberSecurityPolicy } =
    useGetCyberSecurityPolicyQuery()
  const { data: dataAssemblyDeliveryPolicy, isError: isErrorAssemblyDeliveryPolicy } =
    useGetAssemblyDeliveryPolicy()

  useEffect(() => {
    if (isErrorRoleOffer || isErrorCyberSecurityPolicy || isErrorAssemblyDeliveryPolicy) {
      message.error('Ошибка получения данных о соглашениях об оказании услуг')
    }
  }, [isErrorRoleOffer, isErrorCyberSecurityPolicy, isErrorAssemblyDeliveryPolicy])

  useEffect(() => {
    const getHash = async dataRoleOffer => {
      return await digestMessage(
        dataRoleOffer?.offer?.subject +
          dataRoleOffer?.offer?.body +
          dataRoleOffer?.offer?.agreementTerm
      )
    }
    if (dataRoleOffer) {
      getHash(dataRoleOffer).then(hash => {
        setOffersData({
          roleOffer: {
            offerId: dataRoleOffer?.offer?.id,
            offerHash: hash
          }
        })
      })
    }
    if (dataCyberSecurityPolicy) {
      setOffersData({
        cyberSecurityPolicy: { agreementId: dataCyberSecurityPolicy?.agreement?.id }
      })
    }
    if (dataAssemblyDeliveryPolicy) {
      setOffersData({
        assemblyDeliveryPolicy: { agreementId: dataAssemblyDeliveryPolicy?.agreement?.id }
      })
    }
  }, [dataRoleOffer, dataCyberSecurityPolicy, dataAssemblyDeliveryPolicy])

  const { data: editEmploymentTypeFormData } = useGetEditEmploymentTypeForm({
    enabled: [employmentTypeStatusCodes.declined, employmentTypeStatusCodes.declinedSmz].includes(
      employmentTypeFormStatus
    )
  })

  const { mutate, data, isLoading, isSuccess, isError } = useMutatePostEmploymentTypeForm()

  const handleUpdateForm = useCallback(
    (isFirstLaunch = false) => {
      if (!isTouched && isFirstLaunch !== true) {
        setIsTouched(true)
      }
    },
    [isTouched, profile?.personalData]
  )

  const newLegalForm = useMemo(() => {
    return profile?.personalData?.legalForm && profile?.personalData?.legalForm === individual
      ? selfEmployed
      : individual
  }, [profile?.personalData?.legalForm])

  const fillForm = useCallback(
    formData => {
      if (Object.keys(formData || {}).length) {
        const data = createFormDataFromApi({
          personalData: {
            [profileScreen.input.legalForm.name]: formData?.legalForm,
            [profileScreen.input.email.name]: formData?.email
          },
          bankDetails: {
            ...formData?.bankDetails,
            [profileScreen.input.bankDetailsPhoto.name]: formData?.bankDetailsPhoto
          },
          [profileScreen.input.egripPhoto.name]: formData?.egripPhoto
        })
        data[profileScreen.input.bankDetailsPhoto.name] = data.bankDetails.bankDetailsPhoto
        delete data.bankDetails.bankDetailsPhoto
        form.setFieldsValue(extState?.form ? extState.form : data)
        setTrigger(value => !value)
      }
    },
    [form, extState]
  )

  useEffect(() => {
    if (editEmploymentTypeFormData?.form) {
      fillForm(editEmploymentTypeFormData?.form)
    } else if (newLegalForm && !editEmploymentTypeFormData?.form) {
      fillForm({ legalForm: newLegalForm })
    }
  }, [fillForm, newLegalForm, editEmploymentTypeFormData])

  useEffect(() => {
    if (isError) {
      message.error('Ошибка отправки данных')
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      if (data?.errorMessage) {
        return message.error(data?.errorMessage || 'Ошибка отправки данных')
      }
      if (Object.keys(data?.profile || {}).length) {
        setPartnerProfile({ profile: data.profile })
      }
      message.success('Ваш запрос отправлен на проверку.')
      setExtState(PROFILE_EMPLOYMENT_TYPE_STATE_INIT)
    }
  }, [data, isSuccess, setExtState])

  useEffect(() => {
    if (Object.keys(backendFieldsErrors).length > 0) {
      form.setFields(
        Object.keys(backendFieldsErrors).map(key => ({
          name: key,
          errors: backendFieldsErrors[key]
        }))
      )
    }
  }, [backendFieldsErrors])

  const onFinish = values => {
    if (!values.accept) return

    setBackendFieldsErrors({})
    if (!validateAllPhotosUploading(values)) {
      message.error(errors.documentsUploading)
      return
    }

    form.validateFields().then(values => {
      const data = createApiDataFromForm({
        ...values
      })
      mutate({
        data: {
          [profileScreen.input.legalForm.name]: AppConfig.legalFormLabelCodes[newLegalForm],
          ...(data?.personalData?.[profileScreen.input.email.name]?.length
            ? {
                [profileScreen.input.email.name]:
                  data?.personalData?.[profileScreen.input.email.name]
              }
            : {}),
          bankDetails: {
            [profileScreen.input.bankAccount.name]:
              data?.bankDetails[profileScreen.input.bankAccount.name],
            [profileScreen.input.bankBik.name]: data?.bankDetails[profileScreen.input.bankBik.name],
            [profileScreen.input.bankName.name]:
              data?.bankDetails[profileScreen.input.bankName.name]
          },
          [profileScreen.input.bankDetailsPhoto.name]:
            data?.bankDetails[profileScreen.input.bankDetailsPhoto.name],
          ...(data?.[profileScreen.input.egripPhoto.name]?.length
            ? { [profileScreen.input.egripPhoto.name]: data[profileScreen.input.egripPhoto.name] }
            : {})
        },
        offer: offersData?.roleOffer,
        assemblyAndDeliveryPolicyId: offersData?.assemblyDeliveryPolicy?.agreementId,
        cyberSecurityPolicyId: offersData?.cyberSecurityPolicy?.agreementId
      })
    })
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const handleBack = () => {
    setExtState(PROFILE_EMPLOYMENT_TYPE_STATE_INIT)
    history.goBack()
  }

  const handleGoOutFromPage = () => {
    setExtState({
      form: form.getFieldsValue()
    })
  }

  const handleUpdateImage = (item, info) => {
    form.setFieldsValue({ ...form.getFieldsValue(), [item.name]: info.fileList })
    setTrigger(value => !value)
  }
  const handleDeleteImage = item => {
    form.setFieldsValue({ ...form.getFieldsValue(), [item.name]: [] })
    setTrigger(value => !value)
  }

  const documentList = useMemo(() => {
    return newLegalForm === selfEmployed
      ? [profileScreen.input.bankDetailsPhoto.name]
      : [profileScreen.input.bankDetailsPhoto.name, profileScreen.input.egripPhoto.name]
  }, [newLegalForm])

  const documentRequiredList = useMemo(() => {
    return newLegalForm === selfEmployed
      ? { [profileScreen.input.bankDetailsPhoto.name]: true }
      : {
          [profileScreen.input.bankDetailsPhoto.name]: true,
          [profileScreen.input.egripPhoto.name]: true
        }
  }, [newLegalForm])

  const renderTaxInfo = (isCreate = false) => {
    return (
      <div className="myTaxAlert">
        {isCreate ? (
          <Title level={5}>
            Перед изменением формы занятости необходимо получить статус Самозанятого
          </Title>
        ) : (
          <Title level={5}>У вас нет статуса самозанятого</Title>
        )}
        {!isCreate && (
          <p>
            Для смены формы занятости получите статус самозанятого, в Федеральной Налоговой Службе,
            для этого:
          </p>
        )}

        <ul>
          <li>Перейдите в «Мой налог»{nbsp}— на сайт или в приложение</li>
          <li>Зарегистрируйтесь используя любой удобный метод</li>
          <li>Дождитесь сообщения о регистрации. Обычно это занимает не более 5 минут</li>
          {!isCreate && (
            <li>
              <b>Повторите отправку анкеты</b>
            </li>
          )}
        </ul>
        <p>
          Для получения выплат выберите СберМаркет в качестве партнёра в «Мой налог» (сайт или в
          приложении)
        </p>
        <p>
          Перейдите в меню, раздел «Партнёры» и нажмите «Разрешить» в появившемся запросе от
          СберМаркета
        </p>
        <div>
          <Button ghost size="large" type="primary" onClick={handleMyTax}>
            Перейти в «Мой налог»
          </Button>
        </div>
      </div>
    )
  }

  if (profile?.statusCode && profile?.statusCode !== partnerStatusCodes.loginIssued) {
    history.push(PROFILE_MAIN_PAGE)
  }

  return (
    <Layout className="ProfileEditInfoWrapper">
      <MobileHeader />

      <Spin spinning={!employmentTypeFormStatus || isLoading} size="large">
        <Content className="Profile">
          <Title level={4} className="mt-3">
            Смена формы занятости с {profile?.personalData?.legalForm} на {newLegalForm}
          </Title>
          <Row className="mt-3 mb-3">
            <Button type="text" onClick={handleBack}>
              <LeftOutlined /> {profileContractScreen.button.back}
            </Button>
          </Row>
          {employmentTypeFormStatus === employmentTypeStatusCodes.accepted && (
            <Title level={5} className="mt-4">
              Данные о смене формы занятости уже приняты
            </Title>
          )}
          {[employmentTypeStatusCodes.verification, employmentTypeStatusCodes.selfieCheck].includes(
            employmentTypeFormStatus
          ) ? (
            <Title level={5} className="mt-4">
              Ваш запрос отправлен на проверку
            </Title>
          ) : (
            employmentTypeFormStatus !== employmentTypeStatusCodes.accepted && (
              <div>
                {employmentTypeFormStatus === employmentTypeStatusCodes.none &&
                  newLegalForm === selfEmployed && (
                    <div style={{ textAlign: 'left' }}>{renderTaxInfo(true)}</div>
                  )}
                {[
                  employmentTypeStatusCodes.declined,
                  employmentTypeStatusCodes.declinedSmz
                ].includes(employmentTypeFormStatus) && (
                  <Alert
                    message={<Text type="secondary">Причины отказа</Text>}
                    description={
                      <>
                        <div className="pre-line">
                          {editEmploymentTypeFormData?.form?.statusReason}
                        </div>
                        {employmentTypeStatusCodes.declinedSmz === employmentTypeFormStatus &&
                          renderTaxInfo()}
                      </>
                    }
                    type="error"
                    style={{ marginBottom: '1rem' }}
                  />
                )}
                <Form
                  {...formLayout}
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  onFieldsChange={handleUpdateForm}
                  form={form}
                  layout="vertical"
                  scrollToFirstError
                >
                  <ErrorBlock />

                  <Form.Item
                    name={['personalData', profileScreen.input.legalForm.name]}
                    label={profileScreen.input.legalForm.label}
                    hidden
                  >
                    {/* <input /> */}
                  </Form.Item>

                  {newLegalForm === individual && <EmailFormItem />}

                  <BankDetails isVisible form={form} />

                  <Documents
                    trigger={trigger}
                    form={form}
                    required={documentRequiredList}
                    documents={documentList}
                    onUpdate={handleUpdateImage}
                    onDelete={handleDeleteImage}
                    onPhotoDoc={handleGoOutFromPage}
                  />

                  <Form.Item
                    name="accept"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        transform: value => value || undefined,
                        type: 'boolean',
                        message: offerScreen.offerText
                      }
                    ]}
                    className="offer"
                  >
                    <Checkbox disabled={!role}>
                      {offerScreen.input.accept.placeholder}:{' '}
                      <Link
                        disabled={!role}
                        to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offer}&role=${role}`}
                        onClick={handleGoOutFromPage}
                      >
                        {offerScreen.offerParts.roleOffer}
                      </Link>
                      {partnerRoles4MultiOffer.includes(role) && (
                        <>
                          ,{' '}
                          <Link
                            disabled={!role}
                            to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.cyberSecurityPolicy}`}
                            onClick={handleGoOutFromPage}
                          >
                            {offerScreen.offerParts.cyberSecurityPolicy}
                          </Link>
                          ,{' '}
                          <Link
                            disabled={!role}
                            to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.assemblyAndDeliveryPolicy}`}
                            onClick={handleGoOutFromPage}
                          >
                            {offerScreen.offerParts.assemblyAndDeliveryPolicy}
                          </Link>
                          .
                        </>
                      )}
                    </Checkbox>
                  </Form.Item>

                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    className="mt-3"
                    block
                    loading={isLoading}
                    disabled={!isTouched && !extState?.form}
                  >
                    Отправить
                  </Button>
                  <Button
                    block
                    size="large"
                    className="mt-3"
                    onClick={handleBack}
                    disabled={isLoading}
                  >
                    Отмена
                  </Button>
                </Form>
              </div>
            )
          )}
        </Content>
      </Spin>
    </Layout>
  )
}

export default ProfileEditEmploymentTypeScreen
