import { Form } from 'antd'
import React from 'react'
import { profileScreen } from '../../../../translates'
import { MaskedInput } from 'antd-masked-input'
import { validateDrivingNumber } from '../../../../helper'

const DrivingNumberFormItem = ({
  label = profileScreen.input.drivingLicenceSeriesNumber.label,
  rules = undefined,
  requiredRule = true,
  messageRule = profileScreen.input.drivingLicenceSeriesNumber.message,
  size = 'large',
  placeholder = profileScreen.input.drivingLicenceSeriesNumber.placeholder,
  validator,
  classNameInput = '',
  onChange = () => {},
  disabled = false
}) => {
  return (
    <Form.Item
      name={['personalData', 'drivingLicence', profileScreen.input.drivingLicenceSeriesNumber.name]}
      label={label}
      rules={
        rules ?? [
          {
            required: requiredRule,
            message: messageRule,
            ...(validator ? { validator } : { validator: validateDrivingNumber })
          }
        ]
      }
    >
      <MaskedInput
        size={size}
        placeholder={placeholder}
        mask="11 11 111111"
        className={classNameInput}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  )
}
export default DrivingNumberFormItem
