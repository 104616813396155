import React from 'react'
import { Form, Checkbox, Tooltip } from 'antd'
import { profileScreen } from '../../../translates'
import { QuestionCircleOutlined } from '@ant-design/icons'

function LegalAgeCapableItem({ onChange }) {
  const handleChange = check => {
    onChange?.(check)
  }
  return (
    <div className="ant-row ant-form-item" style={{ textAlign: 'left' }}>
      <label>
        <Form.Item
          name={['personalData', profileScreen.input.isLegalAgeCapable.name]}
          valuePropName="checked"
          noStyle
        >
          <Checkbox style={{ marginRight: '0.5rem' }} onChange={handleChange} />
        </Form.Item>
        {profileScreen.input.isLegalAgeCapable.label}
        <Tooltip
          placement="top"
          title={profileScreen.input.isLegalAgeCapable.hint}
          trigger="click"
          color="white"
          overlayInnerStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}
        >
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </label>
    </div>
  )
}

export default LegalAgeCapableItem
