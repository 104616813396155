import { Checkbox, Form, Input, PageHeader, Select, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import React, { useCallback, useEffect, useMemo } from 'react'

import { AppConfig, DISABLE_VACCINATED } from '../../../AppConfig'
import { profileScreen } from '../../../translates'
import { useStores } from '../../../Store/Store'
import {
  BirthDateFormItem,
  CitizenshipFormItem,
  NameFormItem,
  EmailFormItem,
  CitySelectorFormItem,
  // StoreChainFormItem,
  // StoreFormItem,
  PassportBlock
  // RegistrationAddressBlock
  // PlaceOfBirthFormItem
} from '../../Common'
import {
  createApiDataFromForm,
  trueFalseList,
  validateDate,
  validateName,
  validatePhone,
  getBirthDateRules
} from '../../../helper'
import {
  usePartnerRoles
  // useClothingSizes
} from '../../../hooks'
import { getHeaders } from '../../../api/react-query/api'
import MiddleNameFormItem from '../../Common/FormItems/MiddleNameFormItem'
import LegalAgeCapableItem from '../../Common/FormItems/LegalAgeCapableItem'
// import VehicleNumberFormItem from '../../Common/FormItems/VehicleNumberFormItem'
import { useMutateLog } from '../../../api/react-query/log'
import ModalChangePhone from './ModalChangePhone'
import GenderSelectFormItem from '../../Common/FormItems/GenderSelectFormItem'
// import MedicalBookNumberFormItem from '../../Common/FormItems/Medical/MedicalBookNumberFormItem'
// import MedicalExamDateFormItem from '../../Common/FormItems/Medical/MedicalExamDateFormItem'
// import MedicalAttestationDateFormItem from '../../Common/FormItems/Medical/MedicalAttestationDateFormItem'
// import MedicalAcceptOffer from '../../Common/FormItems/Medical/MedicalAcceptOffer'

const { Option } = Select

const PersonalData = ({
  form,
  doFetchProfileCheck,
  isLoading,
  setRole,
  trigger,
  hasNfc
  // disabledMedicalAcceptOffer,
  // onMedicalOfferShow
}) => {
  const {
    partnerProfile,
    partnerProfile: {
      visible,
      required,
      updateForm,
      profile: { personalData }
      // form: storeForm
    } = {}
  } = useStores()
  const { data: roles, isLoading: isLoadingRoles } = usePartnerRoles()
  // const { data: clothingSizes } = useClothingSizes()
  const { mutate: sendLog } = useMutateLog()

  const sendStartFillLog = useCallback(() => {
    sendLog({
      targetId: `partner/${partnerProfile?.id}`,
      action: 'ProfileFillingStarted'
    })
  }, [partnerProfile?.id, sendLog])

  const handleUpdateForm = () => updateForm({ ...form.getFieldsValue() })
  // const handleChangeCity = useCallback(() => {
  //   sendStartFillLog()
  //   const values = form.getFieldsValue()
  //   return updateForm({ ...values, personalData: { ...values.personalData, store: '', storeChain: '' } })
  // }, [form, updateForm, sendStartFillLog])
  // const handleChangeChain = () => {
  //   const values = form.getFieldsValue()
  //   return updateForm({ ...values, personalData: { ...values.personalData, store: '' } })
  // }

  const handleChangeLegal = () => {
    handleChangeFormAndCheck()
    sendStartFillLog()
  }

  const handleChangeFormAndCheck = () => {
    const values = form.getFieldsValue()
    values.personalData = {
      ...values.personalData,
      ...(hasNfc !== null && { hasNfc })
    }
    const info = createApiDataFromForm(values)

    doFetchProfileCheck('partner/profile/check', {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        info
      })
    })
  }

  const handleChangePhone = newPhone => {
    form.setFieldsValue({
      personalData: {
        mobilePhone: newPhone
      }
    })
  }

  useEffect(() => {
    if (personalData?.role && roles) {
      const role = roles.find(role => role.name === personalData?.role)
      setRole(role?.id)
    }
  }, [personalData?.role, roles, setRole])

  const isShowLegalAgeCapable = useMemo(() => {
    return personalData?.isLegalAgeCapable === false || personalData?.isLegalAgeCapable === true
  }, [personalData?.isLegalAgeCapable])

  if (!visible.personalData) return null

  const DISABLE_CHANGE_PHONE = true

  return (
    <>
      <PageHeader className="wrapAble" title={profileScreen.title} />

      <Form.Item
        name={['personalData', profileScreen.input.legalForm.name]}
        label={profileScreen.input.legalForm.label}
        rules={[
          { required: required?.personalData, message: profileScreen.input.legalForm.message }
        ]}
      >
        <Select
          size="large"
          placeholder={profileScreen.input.legalForm.placeholder}
          onChange={handleChangeLegal}
          loading={isLoading}
          // disabled
        >
          {AppConfig.legalForms.map(c => (
            <Option key={c} value={c}>
              {c}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item className="text-align-left">
        <div className="ant-col ant-form-item-label">
          <label className="ant-form-item-required">
            {profileScreen.input.notFormerCivilEmployee.label}
            <Tooltip
              placement="top"
              title={profileScreen.input.notFormerCivilEmployee.hint}
              trigger="click"
              color="white"
              overlayInnerStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}
            >
              <QuestionCircleOutlined className="ml-2 mr-3" />
            </Tooltip>
            <Form.Item
              noStyle
              name="notFormerCivilEmployee"
              valuePropName="checked"
              rules={[
                {
                  transform: value => value || undefined,
                  type: 'boolean'
                },
                {
                  required: true,
                  validator: (rule, value, callback) => {
                    if (value) {
                      return callback()
                    }
                    return callback(profileScreen.input.notFormerCivilEmployee.message)
                  }
                }
              ]}
            >
              <Checkbox />
            </Form.Item>
          </label>
        </div>
      </Form.Item>
      <NameFormItem
        name={['personalData', 'surname']}
        placeholder={profileScreen.input.surname.placeholder}
        rules={[
          { required: true, message: profileScreen.input.surname.message, validator: validateName }
        ]}
        label={profileScreen.input.surname.label}
      />
      <NameFormItem
        name={['personalData', 'firstName']}
        placeholder={profileScreen.input.firstName.placeholder}
        rules={[
          {
            required: true,
            message: profileScreen.input.firstName.message,
            validator: validateName
          }
        ]}
        label={profileScreen.input.firstName.label}
      />
      <MiddleNameFormItem form={form} onUpdate={handleUpdateForm} trigger={trigger} />
      <GenderSelectFormItem />

      <Form.Item
        name={['personalData', 'mobilePhone']}
        label={profileScreen.input.mobilePhone.label}
        rules={[
          {
            required: required?.personalData,
            message: profileScreen.input.mobilePhone.message,
            validator: validatePhone
          }
        ]}
        help={!DISABLE_CHANGE_PHONE && <ModalChangePhone onSuccess={handleChangePhone} />}
      >
        <Input
          size="large"
          disabled
          type="tel"
          placeholder={profileScreen.input.mobilePhone.placeholder}
        />
      </Form.Item>

      {personalData?.emailRequired && <EmailFormItem />}
      <Form.Item
        name={['personalData', 'role']}
        label={profileScreen.input.role.label}
        rules={[{ required: required?.personalData, message: profileScreen.input.role.message }]}
      >
        <Select
          size="large"
          placeholder={profileScreen.input.role.placeholder}
          onChange={(role, option) => {
            // TODO: Костыль пока не уберут роль курьера
            if (option.key === AppConfig.partnerRoles.courier) {
              setRole(AppConfig.partnerRoles.automotiveCourier)
            } else {
              setRole(option.key)
            }
            handleUpdateForm()
            handleChangeFormAndCheck()
            sendStartFillLog()
          }}
          loading={isLoadingRoles}
        >
          {roles.map(role => (
            <Option key={role.id} value={role.name}>
              {role.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {/* { */}
      {/*  personalData?.roleCode === AppConfig.partnerRoles.automotiveCourier && ( */}
      {/*    <VehicleNumberFormItem */}
      {/*      form={form} */}
      {/*      initValues={{ */}
      {/*        [profileScreen.input.isForeignVehicleNumber.name]: form.getFieldValue(['personalData', profileScreen.input.isForeignVehicleNumber.name]), */}
      {/*        [profileScreen.input.vehicleNumber.name]: form.getFieldValue(['personalData', profileScreen.input.vehicleNumber.name]) */}
      {/*      }} */}
      {/*    /> */}
      {/*  ) */}
      {/* } */}

      <CitySelectorFormItem
        rules={[{ required: required?.personalData, message: profileScreen.input.city.message }]}
        // onChange={handleChangeCity}
      />

      <CitizenshipFormItem
        label={profileScreen.input.citizenship.label}
        placeholder={profileScreen.input.citizenship.placeholder}
        rules={[
          { required: required?.personalData, message: profileScreen.input.citizenship.message }
        ]}
      />

      <BirthDateFormItem
        label={profileScreen.input.birthDate.label}
        placeholder={profileScreen.input.birthDate.placeholder}
        rules={[
          {
            required: required?.personalData,
            message: profileScreen.input.birthDate.message,
            validator: validateDate
          },
          ...getBirthDateRules()
        ]}
        onBlur={() => {
          form.validateFields([['personalData', 'birthDate']]).then(() => {
            handleChangeFormAndCheck()
          })
        }}
      />

      {isShowLegalAgeCapable && <LegalAgeCapableItem onChange={handleChangeFormAndCheck} />}

      {/* <PlaceOfBirthFormItem /> */}

      {/* <StoreChainFormItem */}
      {/*  city={storeForm?.personalData?.city} */}
      {/*  rules={[{ required: required?.personalData, message: profileScreen.input.storeChain.message }]} */}
      {/*  disabled={!form.getFieldValue(['personalData', 'city'])} */}
      {/*  onChange={handleChangeChain} */}
      {/* /> */}

      {/* <StoreFormItem */}
      {/*  city={storeForm?.personalData?.city} */}
      {/*  chain={storeForm?.personalData?.storeChain} */}
      {/*  rules={[{ required: required?.personalData, message: profileScreen.input.store.message }]} */}
      {/*  disabled={!form.getFieldValue(['personalData', 'storeChain'])} */}
      {/*  onChange={handleChangeFormAndCheck} */}
      {/* /> */}

      <PassportBlock />

      {/* <RegistrationAddressBlock /> */}

      {/* <Form.Item */}
      {/*  name={['personalData', 'clothingSize']} */}
      {/*  label={profileScreen.input.clothingSize.label} */}
      {/*  rules={[{ required: required?.personalData, message: profileScreen.input.clothingSize.message }]} */}
      {/* > */}
      {/*  <Select */}
      {/*    size='large' */}
      {/*    placeholder={profileScreen.input.clothingSize.placeholder} */}
      {/*  > */}
      {/*    { */}
      {/*      clothingSizes.map(c => <Option key={c.name} value={c.name}>{c.name}</Option>) */}
      {/*    } */}
      {/*  </Select> */}
      {/* </Form.Item> */}

      {/* <Form.Item */}
      {/*  name={['lmkDetails', profileScreen.input.hasMedicalBook.name]} */}
      {/*  label={profileScreen.input.hasMedicalBook.label} */}
      {/*  rules={[{ required: required?.personalData, message: profileScreen.input.hasMedicalBook.message }]} */}
      {/* > */}
      {/*  <Select */}
      {/*    size='large' */}
      {/*    placeholder={profileScreen.input.hasMedicalBook.placeholder} */}
      {/*    onSelect={() => { */}
      {/*      handleUpdateForm() */}
      {/*      handleChangeFormAndCheck() */}
      {/*    }} */}
      {/*  > */}
      {/*    { */}
      {/*      trueFalseList.map(c => <Option key={c.label} value={c.value}>{c.label}</Option>) */}
      {/*    } */}
      {/*  </Select> */}
      {/* </Form.Item> */}

      {/* {storeForm?.lmkDetails?.hasMedicalBook && ( */}
      {/*  <> */}
      {/*    <MedicalAcceptOffer disabled={disabledMedicalAcceptOffer} onMedicalOfferShow={onMedicalOfferShow} /> */}
      {/*    <MedicalBookNumberFormItem required={storeForm?.lmkDetails?.hasMedicalBook} /> */}
      {/*    <MedicalExamDateFormItem required={storeForm?.lmkDetails?.hasMedicalBook} /> */}
      {/*    <MedicalAttestationDateFormItem required={storeForm?.lmkDetails?.hasMedicalBook} /> */}
      {/*  </> */}
      {/* )} */}

      {!DISABLE_VACCINATED && (
        <Form.Item
          name={['personalData', profileScreen.input.vaccinated.name]}
          label={profileScreen.input.vaccinated.label}
          rules={[
            { required: required?.personalData, message: profileScreen.input.vaccinated.message }
          ]}
        >
          <Select
            size="large"
            placeholder={profileScreen.input.vaccinated.placeholder}
            onChange={handleChangeFormAndCheck}
          >
            {trueFalseList.map(c => (
              <Option key={c.label} value={c.value}>
                {c.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </>
  )
}
export default PersonalData
