import React, { useEffect, useState } from 'react'
import { profileScreen } from '../../../translates'
import { validateName } from '../../../helper'
import { Checkbox, Col, Form, Row, Typography } from 'antd'
import { NameFormItem } from '../index'

const { Text } = Typography
const fieldNames = {
  middleName: ['personalData', 'middleName'],
  withoutMiddleName: ['personalData', 'withoutMiddleName']
}
const MiddleNameFormItem = ({ form, size = 'large', onUpdate = () => {}, trigger }) => {
  const [without, setWithout] = useState(false)
  const handleChangeWithoutMiddleName = () => {
    form
      .validateFields([fieldNames.withoutMiddleName])
      .then(values => {
        setWithout(values?.personalData?.withoutMiddleName)
        if (values?.personalData?.withoutMiddleName) {
          form.setFields([
            {
              name: fieldNames.middleName,
              errors: [],
              value: ''
            }
          ])
        }
        onUpdate()
      })
      .catch(e => {
        console.log('%c e', 'color: green; font-weight: bold; font-size: 22px', e)
      })
  }

  useEffect(() => {
    if (trigger) {
      handleChangeWithoutMiddleName()
    }
  }, [trigger])

  return (
    <NameFormItem
      name={fieldNames.middleName}
      placeholder={
        without
          ? profileScreen.input.middleName.without
          : profileScreen.input.middleName.placeholder
      }
      rules={[
        {
          required: !without,
          message: profileScreen.input.middleName.message,
          validator: without ? () => Promise.resolve() : validateName
        }
      ]}
      shouldUpdate
      wrapperClassName="label-row"
      disabled={without}
      label={
        <Row justify="space-between" className="full-width">
          <Col>
            <Text>{profileScreen.input.middleName.label}</Text>
          </Col>
          <Col>
            <Text>{profileScreen.input.middleName.without}</Text>
            <Form.Item
              name={fieldNames.withoutMiddleName}
              valuePropName="checked"
              noStyle
              rules={[
                {
                  transform: value => value || undefined,
                  type: 'boolean'
                }
              ]}
            >
              <Checkbox className="ml-3" onChange={handleChangeWithoutMiddleName} />
            </Form.Item>
          </Col>
        </Row>
      }
      requiredRule={false}
      size={size}
    />
  )
}

export default MiddleNameFormItem
