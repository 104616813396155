import React, { useCallback, useEffect, useState } from 'react'
import { useParams, withRouter, generatePath } from 'react-router-dom'
import moment from 'moment-timezone'
import { message, Table, Drawer, Spin, Row } from 'antd'
import { useObserver } from 'mobx-react'

import Partner from '../Partners/Partner'
import { useStores } from '../../../Store/Store'
import { ADMIN_BANNED_PARTNER_PAGE, ADMIN_BANNED_PARTNERS_PAGE } from '../../../routes'
import getColumnSearchProps from '../../../components/SearchFilter/SearchFilter'

import '../Partners/Partner.css'
// import {
//   usePartnerYoudoStatusesQuery
// } from '../../../api/react-query/dict'
import { useQueryAdminBannedPartnersList } from '../../../api/react-query/adminPartners'
import {
  oneMinute,
  paginationPageSizeList,
  partnerListColKeys,
  initialBannedPartnerColKeys
} from '../../../Constants'
import AutoCompleteFilter from '../../../components/AutoCompleteFilter/AutoCompleteFilter'
import { useCities, usePartnerRoles } from '../../../hooks'
import TableSettings from '../../../components/Common/TableSettings/TableSettings'

const columnsKey = 'bannedPartnerColumns'

function BannedPartners({ history }) {
  const store = useStores()
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()
  const pagination = {
    current: store.adminBannedPartnerList.pageNumber,
    total: store.adminBannedPartnerList.resultsTotal,
    pageSize: store.adminBannedPartnerList.pageSize,
    pageSizeOptions: paginationPageSizeList
  }
  const partnersRequest = {
    pageNumber: pagination.current,
    pageSize: pagination.pageSize,
    ...JSON.parse(
      localStorage.getItem('partnersBannedRequest') || '{"orderBy":"registrationDate desc"}'
    )
  }
  // в localStorage может быть сохранено старое значение pageSize = 0 которое ломает запрос
  partnersRequest.pageSize = partnersRequest.pageSize || pagination.pageSize
  const [request, setRequest] = useState(partnersRequest)
  const { data: cities } = useCities()
  // const [youdoStatuses, setYoudoStatuses] = useState([])
  const [columnsKeys, setColumnsKeys] = useState(
    JSON.parse(localStorage.getItem(columnsKey)) || initialBannedPartnerColKeys
  )

  const { data: roles } = usePartnerRoles()

  const {
    data: adminPartnersListData,
    isError: isErrorAdminPartnersList,
    isLoading: isLoadingAdminPartnersList,
    isSuccess: isSuccessAdminPartnersList
  } = useQueryAdminBannedPartnersList(request, {
    refetchInterval: oneMinute
  })

  useEffect(() => {
    if (isSuccessAdminPartnersList) {
      store.adminBannedPartnerList = { ...adminPartnersListData }
      setIsLoading(false)
    }
  }, [isSuccessAdminPartnersList, adminPartnersListData])

  // const {
  //   data: partnerYoudoStatusesData,
  //   isError: isErrorPartnerYoudoStatuses,
  //   isSuccess: isSuccessPartnerYoudoStatuses
  // } = usePartnerYoudoStatusesQuery(
  //   {
  //     staleTime: twoMinutes
  //   })
  // useEffect(() => {
  //   if (isSuccessPartnerYoudoStatuses) {
  //     setYoudoStatuses([...(partnerYoudoStatusesData?.result || [])])
  //   }
  // }, [isSuccessPartnerYoudoStatuses, partnerYoudoStatusesData])

  useEffect(() => {
    if (
      isErrorAdminPartnersList
      // || isErrorPartnerYoudoStatuses
    ) {
      message.error('Ошибка доступа к данным')
    }
    if (isErrorAdminPartnersList) {
      setIsLoading(false)
    }
  }, [
    isErrorAdminPartnersList
    // isErrorPartnerYoudoStatuses
  ])

  const columns = [
    {
      title: 'Дата создания',
      dataIndex: 'registrationDate',
      key: partnerListColKeys.registrationDate,
      render: x => moment(x).format('DD.MM.yyyy'),
      sorter: true,
      sortOrder: request?.orderBy?.includes('registrationDate')
        ? request.orderBy.replace('registrationDate ', '') + 'end'
        : false
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: partnerListColKeys.statuses
    },
    {
      title: 'Статус в Юду',
      dataIndex: 'youdoStatus',
      key: partnerListColKeys.youdoStatuses
      // filters: youdoStatuses.map(s => ({ text: s.name, value: s.id })),
      // filteredValue: request?.filter?.youdoStatuses || null
    },
    {
      title: 'Услуга',
      dataIndex: 'role',
      key: partnerListColKeys.roles,
      filters: roles.map(r => ({ text: r.name, value: r.id })),
      filteredValue: request?.filter?.roles || null
    },
    {
      title: 'ФИО',
      dataIndex: 'name',
      key: partnerListColKeys.name,
      ...getColumnSearchProps('name', 'по ФИО'),
      filteredValue: request?.filter?.name ? [request.filter.name] : null
    },
    {
      title: 'Телефон',
      dataIndex: 'mobilePhone',
      key: partnerListColKeys.mobilePhone,
      ...getColumnSearchProps('mobilePhone', 'по телефону', /[\d\\+]/),
      filteredValue: request?.filter?.mobilePhone ? [request.filter.mobilePhone] : null
    },
    {
      title: 'Город',
      dataIndex: 'city',
      key: partnerListColKeys.cityIds,
      // filters: cities.map(c => ({ text: c.name, value: c.id })),
      filteredValue: request?.filter?.cityIds || null,
      filterDropdown: useCallback(
        props => (
          <AutoCompleteFilter
            values={cities.map(c => ({ text: c.name, value: c.id }))}
            {...props}
          />
        ),
        [cities]
      )
    },
    {
      title: 'Магазин',
      dataIndex: 'storeName',
      key: partnerListColKeys.storeName,
      ...getColumnSearchProps('storeName', 'по магазину'),
      filteredValue: request?.filter?.storeName ? [request.filter.storeName] : null
    },
    {
      title: 'Дата блокировки',
      dataIndex: 'statusTimestamp',
      key: partnerListColKeys.statusTimestamp,
      render: x => moment(x).format('DD.MM.yyyy'),
      sorter: true,
      sortOrder: request?.orderBy?.includes('statusTimestamp')
        ? request.orderBy.replace('statusTimestamp ', '') + 'end'
        : false
    }
  ]

  const handleTableChange = (pagination, filters, sorter) => {
    const filterArrType = ['cityIds', 'roles', 'youdoStatuses']
    let orderBy
    if (sorter?.order) {
      orderBy = `${sorter.field} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`
    }
    const request = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      filter: Object.entries(filters || {}).reduce(
        (r, c) => ({
          ...r,
          [c[0]]: c[1] ? (filterArrType.includes(c[0]) ? c[1] : c[1][0]) : undefined
        }),
        {}
      ),
      orderBy
    }
    setRequest(request)
    localStorage.setItem('partnersBannedRequest', JSON.stringify(request))
    setIsLoading(true)
  }

  const handleSelectRow = record => ({
    onClick: () => {
      history.replace(generatePath(ADMIN_BANNED_PARTNER_PAGE, { id: record.id }))
    }
  })

  return useObserver(() => (
    <>
      <Spin spinning={isLoading || isLoadingAdminPartnersList} size="large">
        <Row justify="end">
          <TableSettings
            options={initialBannedPartnerColKeys.map(key => ({
              label: columns.find(c => c.key === key)?.title,
              value: key
            }))}
            columnsKey={columnsKey}
            onSave={setColumnsKeys}
          />
        </Row>
        <Table
          columns={columns.filter(column => columnsKeys.includes(column.key))}
          loadind={isLoading || isLoadingAdminPartnersList}
          pagination={pagination}
          rowKey={record => record.id}
          dataSource={store.adminBannedPartnerList.result}
          onChange={handleTableChange}
          onRow={handleSelectRow}
        />
      </Spin>
      {!!id && (
        <Drawer
          width={800}
          placement="right"
          closable={false}
          onClose={() => {
            setIsLoading(true)
            history.replace(ADMIN_BANNED_PARTNERS_PAGE)
          }}
          visible={!!id}
        >
          <Partner partnerId={id} />
        </Drawer>
      )}
    </>
  ))
}

export default withRouter(BannedPartners)
