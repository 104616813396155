import { Form } from 'antd'
import React, { useMemo } from 'react'
import { MaskedInput } from 'antd-masked-input'
import { profileScreen } from '../../../../translates'
import { validateDate, getPassportIssueDateRules } from '../../../../helper'
import SberIcon from '../../SberIcon'

const PassportIssueDateFormItem = ({
  label = profileScreen.input.passportIssueDate.label,
  rules = undefined,
  requiredRule = true,
  messageRule = profileScreen.input.passportIssueDate.message,
  size = 'large',
  placeholder = profileScreen.input.passportIssueDate.placeholder,
  showCheckIcon = false
}) => {
  const labelParam = useMemo(() => {
    return showCheckIcon ? (
      <>
        {label}
        <SberIcon style={{ marginLeft: '0.5rem' }} />
      </>
    ) : (
      label
    )
  }, [label, showCheckIcon])

  return (
    <Form.Item
      name={['personalData', 'passport', profileScreen.input.passportIssueDate.name]}
      label={labelParam}
      rules={
        rules ?? [
          { required: requiredRule, message: messageRule, validator: validateDate },
          ...getPassportIssueDateRules()
        ]
      }
    >
      <MaskedInput size={size} placeholder={placeholder} mask="11.11.1111" />
    </Form.Item>
  )
}
export default PassportIssueDateFormItem
