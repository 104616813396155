import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MobileHeader from '../../../components/Partner/MobileHeader/MobileHeader'
import { Alert, Button, Form, Layout, message, PageHeader, Row, Spin, Typography } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import ErrorBlock from '../../../components/Partner/Profile/ErrorBlock'
import { partnerStatusCodes, lmkStatusCodes, AppConfig } from '../../../AppConfig'
import { errors, offerScreen, profileContractScreen, profileScreen } from '../../../translates'
import {
  createApiDataFromForm,
  createFormDataFromApi,
  digestMessage,
  getMedicalAcceptOfferErrors,
  getMedicalOfferParams,
  isDisabledMedicalAcceptOffer,
  isEmptyPassportData,
  renderMedicalOfferTemplate,
  validateAllPhotosUploading
} from '../../../helper'
import './ProfileEditInfoScreenStyle.css'
import '../../../components/Partner/Profile/ProfileStyle.css'
import { LeftOutlined } from '@ant-design/icons'
import { useStores } from '../../../Store/Store'
import {
  useGetEditLmkForm,
  useGetInitialEditLmkForm,
  useGetMedicalAcceptOffer,
  useMutateMedicalOfferAccept,
  useMutatePostLmkForm
} from '../../../api/react-query/partners'
import { formLayout, mdash, nbsp } from '../../../Constants'
import { PROFILE_MAIN_PAGE } from '../../../routes'
import Documents from '../../../components/Partner/Profile/Documents'
import { PROFILE_LMK_STATE_INIT } from './ProfileMainScreen'
import MedicalBookNumberFormItem from '../../../components/Common/FormItems/Medical/MedicalBookNumberFormItem'
import MedicalExamDateFormItem from '../../../components/Common/FormItems/Medical/MedicalExamDateFormItem'
import MedicalAttestationDateFormItem from '../../../components/Common/FormItems/Medical/MedicalAttestationDateFormItem'
import { RegistrationAddressBlock } from '../../../components/Common'
import MedicalAcceptOffer from '../../../components/Common/FormItems/Medical/MedicalAcceptOffer'
import { fromEditTypes } from '../../../components/Partner/PolicyAgreement/PolicyAgreement'

const { Content } = Layout
const { Title, Text } = Typography

function ProfileEditLmkScreen({ extState, setExtState }) {
  const {
    partnerProfile: {
      profile,
      profile: { lmkFormStatus },
      setPartnerProfile
    }
  } = useStores()
  const history = useHistory()
  const [form] = Form.useForm()
  const [isTouched, setIsTouched] = useState(false)
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})
  const [trigger, setTrigger] = useState(false)
  const [disabledMedicalAcceptOffer, setDisabledMedicalAcceptOffer] = useState(false)
  const [errorsMedicalAcceptOffer, setErrorsMedicalAcceptOffer] = useState([])
  const [isEmptyPassport, setIsEmptyPassport] = useState(false)

  const { data: editLmkFormData } = useGetEditLmkForm({
    enabled: [
      lmkStatusCodes.verification,
      lmkStatusCodes.declined,
      lmkStatusCodes.filling,
      lmkStatusCodes.accepted
    ].includes(lmkFormStatus)
  })

  const { data: initialLmkFormData } = useGetInitialEditLmkForm({
    enabled: lmkFormStatus === lmkStatusCodes.canCreate || lmkFormStatus === lmkStatusCodes.none
  })

  const { mutate, data, isLoading, isSuccess, isError } = useMutatePostLmkForm()

  const { data: dataMedicalOffer, isError: isErrorMedicalOffer } = useGetMedicalAcceptOffer()

  useEffect(() => {
    if (isErrorMedicalOffer) {
      message.error(offerScreen.errors.offer)
    }
  }, [isErrorMedicalOffer])

  const {
    mutateAsync: acceptMedicalOffer,
    data: dataAcceptMedicalOffer,
    isLoading: isLoadingAcceptMedicalOffer,
    isError: isErrorAcceptMedicalOffer
  } = useMutateMedicalOfferAccept()

  useEffect(() => {
    if (isErrorAcceptMedicalOffer || dataAcceptMedicalOffer?.errorMessage) {
      message.error(dataAcceptMedicalOffer?.errorMessage || errors.acceptLmkAgreement)
    }
  }, [isErrorAcceptMedicalOffer])

  const acceptMedicalOfferAsync = async function (offerData, profile, onSuccess) {
    const content = renderMedicalOfferTemplate(offerData?.template?.content, profile)
    const hash = await digestMessage(content)
    const result = await acceptMedicalOffer({
      templateId: offerData?.template?.id,
      signHash: hash,
      signData: getMedicalOfferParams(profile)
    })
    if (result?.isSuccess) {
      onSuccess?.()
    }
  }

  const handleUpdateForm = useCallback(
    (isFirstLaunch = false) => {
      const values = form.getFieldsValue()
      const medicalData = {
        personalData: {
          ...profile?.personalData,
          ...values?.personalData
        }
      }
      setDisabledMedicalAcceptOffer(isDisabledMedicalAcceptOffer(medicalData))
      setErrorsMedicalAcceptOffer(getMedicalAcceptOfferErrors(medicalData))
      setIsEmptyPassport(isEmptyPassportData(medicalData))

      if (!isTouched && isFirstLaunch !== true) {
        setIsTouched(true)
      }
    },
    [isTouched, profile?.personalData]
  )

  const fillForm = useCallback(
    formData => {
      if (
        Object.keys(formData?.form || {}).length &&
        !form.getFieldValue(['lmkDetails', profileScreen.input.medicalBookNumber.name])?.length
      ) {
        const data = createFormDataFromApi({
          lmkDetails: {
            ...formData.form
          },
          lmkMainPhoto: formData.form.lmkMainPhoto,
          lmkAllowancePhoto: formData.form.lmkAllowancePhoto,
          lmkAttestationPhoto: formData.form.lmkAttestationPhoto,
          personalData: {
            registrationAddress: formData.form.registrationAddress
          }
        })
        form.setFieldsValue(extState?.form ? extState.form : data)
        setTrigger(true)
      }
    },
    [form, extState]
  )

  useEffect(() => {
    fillForm(editLmkFormData)
  }, [editLmkFormData, fillForm])

  useEffect(() => {
    fillForm(initialLmkFormData)
    handleUpdateForm()
  }, [fillForm, handleUpdateForm, initialLmkFormData])

  useEffect(() => {
    if (isError) {
      message.error('Ошибка отправки данных ЛМК.')
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      if (data?.errorMessage) {
        return message.error(data?.errorMessage || 'Ошибка отправки данных ЛМК.')
      }
      if (Object.keys(data?.profile || {}).length) {
        setPartnerProfile({ profile: data.profile })
      }
      message.success('Ваша анкета отправлена на проверку.')
      setExtState(PROFILE_LMK_STATE_INIT)
    }
  }, [data, isSuccess, setExtState])

  useEffect(() => {
    if (Object.keys(backendFieldsErrors).length > 0) {
      form.setFields(
        Object.keys(backendFieldsErrors).map(key => ({
          name: key,
          errors: backendFieldsErrors[key]
        }))
      )
    }
  }, [backendFieldsErrors])

  const onFinish = values => {
    setBackendFieldsErrors({})
    if (!values.medicalAcceptOffer) {
      return
    }
    if (!validateAllPhotosUploading(values)) {
      message.error(errors.documentsUploading)
      return
    }
    form.validateFields().then(values => {
      const data = createApiDataFromForm(values)
      acceptMedicalOfferAsync(
        dataMedicalOffer,
        {
          personalData: {
            ...profile?.personalData,
            registrationAddress: data?.personalData?.registrationAddress
          }
        },
        () => {
          mutate({
            data: {
              [profileScreen.input.lmkMainPhoto.name]: data[profileScreen.input.lmkMainPhoto.name],
              [profileScreen.input.lmkAllowancePhoto.name]:
                data[profileScreen.input.lmkAllowancePhoto.name],
              [profileScreen.input.lmkAttestationPhoto.name]:
                data[profileScreen.input.lmkAttestationPhoto.name],
              [profileScreen.input.medicalBookNumber.name]:
                data.lmkDetails[profileScreen.input.medicalBookNumber.name],
              [profileScreen.input.medicalexaminationDate.name]:
                data.lmkDetails[profileScreen.input.medicalexaminationDate.name],
              [profileScreen.input.medicalattestationDate.name]:
                data.lmkDetails[profileScreen.input.medicalattestationDate.name],
              registrationAddress: data?.personalData?.registrationAddress
            }
          })
        }
      )
    })
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const handleBack = () => {
    setExtState(PROFILE_LMK_STATE_INIT)
    history.goBack()
  }

  const handleUpdateImage = (item, info) => {
    form.setFieldsValue({ ...form.getFieldsValue(), [item.name]: info.fileList })
    setTrigger(true)
  }
  const handleDeleteImage = item => {
    form.setFieldsValue({ ...form.getFieldsValue(), [item.name]: [] })
    setTrigger(true)
  }

  const handleGoOutFromPage = () => {
    setExtState({
      form: form.getFieldsValue()
    })
  }

  useEffect(() => {
    const country = form.getFieldValue(['personalData', 'registrationAddress', 'country'])
    form.setFieldsValue({
      personalData: {
        registrationAddress: {
          country: country ?? profileScreen.input.registrationAddressCountry.placeholder
        }
      }
    })

    const values = form.getFieldsValue()
    const medicalData = {
      personalData: {
        ...profile?.personalData,
        ...values?.personalData
      }
    }
    setDisabledMedicalAcceptOffer(isDisabledMedicalAcceptOffer(medicalData))
    setErrorsMedicalAcceptOffer(getMedicalAcceptOfferErrors(medicalData))
    setIsEmptyPassport(isEmptyPassportData(medicalData))
  }, [trigger, form, profile?.personalData])

  const offerErrors = useMemo(() => {
    const inputs = profileScreen.input
    if (errorsMedicalAcceptOffer?.length > 0 && (initialLmkFormData || editLmkFormData)) {
      return errorsMedicalAcceptOffer?.filter(err =>
        [
          inputs.surname.label,
          inputs.firstName.label,
          inputs.middleName.label,
          inputs.passportSeries.label,
          inputs.passportNumber.label,
          inputs.passportIssuerName.label,
          inputs.passportIssuerCode.label,
          inputs.passportIssueDate.label
        ].includes(err)
      )
    }
    return []
  }, [errorsMedicalAcceptOffer, initialLmkFormData, editLmkFormData])

  const renderOfferErrorAlert = () => {
    return (
      <Alert
        message={
          <Text type="danger">
            Внимание. Для заполнения анкеты вам сперва необходимо заполнить и подать следующие
            данные
          </Text>
        }
        description={
          <div className="pre-line">
            {offerErrors.map(err => `${err}\n`)}
            <p>
              Для этого перейдите и заполните{' '}
              <Link to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.editInfo}`}>
                Корректирующую анкету
              </Link>
              . После ее принятия{nbsp}
              {mdash} вы сможете подать данные ЛМК.
            </p>
          </div>
        }
        type="error"
      />
    )
  }

  if (profile?.statusCode && profile?.statusCode !== partnerStatusCodes.loginIssued) {
    history.push(PROFILE_MAIN_PAGE)
  }

  return (
    <Layout className="ProfileEditInfoWrapper">
      <MobileHeader />

      <Spin spinning={!lmkFormStatus || isLoading || isLoadingAcceptMedicalOffer} size="large">
        <Content className="Profile">
          <Title level={4} className="mt-3">
            Данные о личной медицинской книжке
          </Title>
          <Row className="mt-3 mb-3">
            <Button type="text" onClick={handleBack}>
              <LeftOutlined /> {profileContractScreen.button.back}
            </Button>
          </Row>
          {lmkFormStatus === lmkStatusCodes.verification ? (
            <Title level={5} className="mt-4">
              Ваша анкета отправлена на проверку
            </Title>
          ) : (
            lmkFormStatus !== lmkStatusCodes.accepted && (
              <div>
                {lmkFormStatus === lmkStatusCodes.declined && (
                  <Alert
                    message={<Text type="secondary">Причины отказа</Text>}
                    description={
                      <div className="pre-line">{editLmkFormData?.form?.statusReason}</div>
                    }
                    type="error"
                  />
                )}
                {offerErrors?.length > 0 && renderOfferErrorAlert()}
                <PageHeader title={profileScreen.title} />
                <Form
                  {...formLayout}
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  onFieldsChange={handleUpdateForm}
                  form={form}
                  layout="vertical"
                  scrollToFirstError
                >
                  <ErrorBlock />

                  <MedicalBookNumberFormItem required />
                  <MedicalExamDateFormItem required />
                  <MedicalAttestationDateFormItem required />

                  <Documents
                    trigger={trigger}
                    form={form}
                    required={{
                      lmkMainPhoto: true,
                      lmkAllowancePhoto: true,
                      lmkAttestationPhoto: true
                    }}
                    documents={['lmkMainPhoto', 'lmkAllowancePhoto', 'lmkAttestationPhoto']}
                    onUpdate={handleUpdateImage}
                    onDelete={handleDeleteImage}
                    onPhotoDoc={handleGoOutFromPage}
                  />

                  <RegistrationAddressBlock
                    required={{
                      zipCode: true,
                      country: true,
                      region: true,
                      street: true,
                      house: true
                    }}
                    disabled={{ country: true }}
                  />

                  <MedicalAcceptOffer
                    disabled={disabledMedicalAcceptOffer}
                    onMedicalOfferShow={handleGoOutFromPage}
                    errors={errorsMedicalAcceptOffer}
                    fromEdit={
                      isEmptyPassport ? fromEditTypes.editLmkWithoutPassport : fromEditTypes.editLmk
                    }
                  />

                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    className="mt-3"
                    block
                    loading={isLoading || isLoadingAcceptMedicalOffer}
                    disabled={!isTouched}
                  >
                    Отправить
                  </Button>
                  <Button
                    block
                    size="large"
                    className="mt-3"
                    onClick={handleBack}
                    disabled={isLoading}
                  >
                    Отмена
                  </Button>
                </Form>
              </div>
            )
          )}
        </Content>
      </Spin>
    </Layout>
  )
}

export default ProfileEditLmkScreen
