import React, { useEffect, useRef } from 'react'
import { Button, Layout, Row, Typography } from 'antd'
import { CheckCircleOutlined, LeftOutlined, CloseCircleOutlined } from '@ant-design/icons'
import MobileHeader from '../MobileHeader/MobileHeader'
import { agreementScreen } from '../../../translates'
import { useHistory } from 'react-router-dom'
import './PhotoDoc.css'
import { AppConfig } from '../../../AppConfig'
import { maxSizeUploadFileMB, nbsp } from '../../../Constants'

const { Title, Text } = Typography

function PhotoDoc() {
  const history = useHistory()

  const myRef = useRef(null)
  useEffect(() => {
    if (myRef.current) {
      document.body.scrollIntoView({ behavior: 'smooth' })
    }
  }, [myRef])

  return (
    <div ref={myRef}>
      <Layout>
        <MobileHeader />
        <Row className="mt-3">
          <Button type="text" onClick={() => history.goBack()}>
            <LeftOutlined /> {agreementScreen.button.back}
          </Button>
        </Row>
        <div className="photo-doc">
          <Title level={4}>
            Как правильно сделать фото или сканы документов для идентификации сотрудника
          </Title>
          <Title level={5}>Список документов</Title>
          <ul>
            <li>Основной разворот паспорта с фото и местом выдачи.</li>
            <li>Разворот паспорта с пропиской или временной регистрацией (даже если она пуста).</li>
          </ul>
          <Title level={5}>Внешний вид</Title>
          <Text>
            Цветные фото или сканы в форматах {Object.keys(AppConfig.uploadTypes).join(', ')} не
            {nbsp}больше {maxSizeUploadFileMB.passportPhoto1}
            {nbsp}МБ.
          </Text>
          <br />
          <Text>Текст, печати, подпись и фото сотрудника должны быть четко видны.</Text>
          <br />
          <br />
          <Text>
            Важно: фото ничто не должно перекрывать. Если на изображение попали скрепки или пальцы,
            убедитесь, что они не закрывают информацию на документе.
          </Text>

          <Title level={4} className="title-good">
            <CheckCircleOutlined />
            Правильно сделанные фото или сканы документов
          </Title>

          <Text>1. Основной разворот паспорта</Text>
          <br />
          <Text>Важно: машиночитаемая подпись внизу страницы должна быть хорошо видна.</Text>
          <img src="/img/photo-doc/photo-good-1.png" className="img-doc" />

          <Text>2. Разворот паспорта с пропиской</Text>
          <br />
          <Text>
            Важно: печать прописки/регистрации и страницы паспорта должны быть отчетливо видны.
          </Text>
          <img src="/img/photo-doc/photo-good-2.png" className="img-doc" />

          <br />
          <br />

          <Title level={4} className="title-bad">
            <CloseCircleOutlined />
            Неправильно сделанные фото или сканы документов
          </Title>

          <Text>1. На скане паспорта посторонний предмет, перекрывающий текст.</Text>
          <br />
          <Text>2. Палец, придерживающий паспорт, закрывает часть номера.</Text>
          <img src="/img/photo-doc/photo-bad-1.png" className="img-doc" />

          <Text>3. На скане не видно машиночитаемой надписи.</Text>
          <br />
          <Text>4. Скан засвечен, не видно части текста.</Text>
          <br />
          <Text>5. Блик на фотографии, сложно понять, кому принадлежит паспорт.</Text>
          <img src="/img/photo-doc/photo-bad-2.png" className="img-doc" />

          <Text>6. Страница сильно затемнена вверху, часть текста не видно.</Text>
          <img src="/img/photo-doc/photo-bad-3.png" className="img-doc" />

          <br />
          <br />
        </div>
      </Layout>
    </div>
  )
}

export default PhotoDoc
