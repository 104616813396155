import React, { useEffect, useReducer, useState } from 'react'
import './DocWithNotices.css'
import { Alert, Button } from 'antd'
import { EyeOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Parser, ProcessNodeDefinitions } from 'html-to-react'
import { simpleReducer } from '../../../helper'

function NoticeBlock({ text }) {
  const [showBlock, setShowBlock] = useState(true)
  const onClose = () => {
    setShowBlock(false)
  }
  const onShow = () => {
    setShowBlock(true)
  }

  return showBlock ? (
    <Alert
      style={{ marginBottom: '1rem' }}
      message={text}
      type="success"
      closable
      onClose={onClose}
      showIcon
      icon={<InfoCircleOutlined />}
    />
  ) : (
    <div style={{ textAlign: 'right' }}>
      <Button type="link" onClick={onShow} style={{ marginBottom: '1rem' }}>
        <EyeOutlined />
        Показать подсказку
      </Button>
    </div>
  )
}

function LinkBlock({ link, href }) {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {link}
    </a>
  )
}

const htmlParser = new Parser(React)
const processNodeDefinitions = new ProcessNodeDefinitions(React)
const isValidNode = () => true

const initialState = {
  body: null,
  customProps: {},
  customElements: {}
}

function DocWithNotices({ docData }) {
  const [state, setState] = useReducer(simpleReducer, initialState)

  const formatVarToTag = (text, validList) => {
    let clearedText = text
    validList.forEach(validName => {
      const clearedName = validName.replace('#', '')
      // format {notice#1} to <notice1></notice1>
      clearedText = clearedText.replaceAll(`{${validName}}`, `<${clearedName}></${clearedName}>`)
    })
    clearedText = clearedText.replaceAll(/{(.*)}/gi, '')

    return clearedText
  }

  const formatVarToTagName = name => {
    return name.replaceAll('#', '')
  }

  useEffect(() => {
    if (docData?.body) {
      const tagList = { ...docData?.notices }
      Object.keys({ ...docData?.links }).forEach(varName => {
        if (varName.startsWith('link')) {
          tagList[varName] = docData.links[varName]
        }
      })
      setState({
        body: formatVarToTag(docData.body, Object.keys(tagList)),
        ...(Object.keys(tagList).length
          ? {
              customProps: Object.keys(tagList).reduce((accumulator, varName) => {
                const tagName = formatVarToTagName(varName)
                return {
                  ...accumulator,
                  /* notice1: {text: text_value} */
                  [tagName]: tagName?.startsWith('link')
                    ? {
                        link: docData.links[varName],
                        href: docData.links[`href${varName.replace('link', '')}`]
                      }
                    : { text: docData.notices[varName] }
                }
              }, {}),
              customElements: Object.keys(tagList).reduce((accumulator, varName) => {
                const tagName = formatVarToTagName(varName)
                return {
                  ...accumulator,
                  /* notice1: Component */
                  [tagName]: tagName?.startsWith('link') ? LinkBlock : NoticeBlock
                }
              }, {})
            }
          : {})
      })
    }
  }, [docData])

  const processingInstructions = [
    {
      shouldProcessNode: node => node.name && state.customElements[node.name],
      processNode: node => {
        const CustomElement = state.customElements[node.name]
        const CustomElementProps = state.customProps[node.name]
        return <CustomElement key={node.name} {...CustomElementProps} />
      }
    },
    {
      shouldProcessNode: () => true,
      processNode: processNodeDefinitions.processDefaultNode
    }
  ]

  if (!state.body) {
    return null
  }

  return (
    <div className="docWithNotices">
      {htmlParser.parseWithInstructions(state?.body, isValidNode, processingInstructions)}
    </div>
  )
}

export default DocWithNotices
