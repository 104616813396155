import React, { useEffect, useState } from 'react'
import { Col, List, message, Row, Spin, Typography } from 'antd'

import { useQueryAdminPartnerAssets } from '../../../../api/react-query/adminPartners'
import { ruCurrency } from '../../../../helper'

const { Text, Title } = Typography

export default function PartnerAssets({ partner }) {
  const [assets, setAssets] = useState([])

  const {
    data: dataPartnerAssets,
    isError: isErrorPartnerAssets,
    isLoading: isLoadingPartnerAssets,
    isSuccess: isSuccessPartnerAssets
  } = useQueryAdminPartnerAssets(partner.id, { enabled: !!partner.id })

  useEffect(() => {
    if (isSuccessPartnerAssets) {
      setAssets(dataPartnerAssets?.result)
    } else if (isErrorPartnerAssets) {
      console.error(dataPartnerAssets)
      message.error('Ошибка получения имущества')
    }
  }, [isErrorPartnerAssets, isSuccessPartnerAssets, dataPartnerAssets])

  return (
    <>
      <Title className="mb-3" level={5}>
        Перечень переданного имущества:
      </Title>
      <Spin spinning={isLoadingPartnerAssets}>
        {assets.length > 0 ? (
          <>
            <List
              bordered
              className="list"
              itemLayout="horizontal"
              dataSource={assets}
              header={
                <Row>
                  <Col span={18}>Название</Col>
                  <Col span={3}>Количество</Col>
                  <Col span={3}>Стоимость</Col>
                </Row>
              }
              renderItem={item => (
                <List.Item>
                  <Col span={18}>
                    <List.Item.Meta title={item.assetName} />
                  </Col>
                  <Col span={3}>{item.quantity}</Col>
                  <Col span={3}>
                    {item.amount.toFixed(2)}
                    <small>
                      <Text type="secondary">{ruCurrency}</Text>
                    </small>
                  </Col>
                </List.Item>
              )}
            />
          </>
        ) : (
          <Text type="secondary" className="ml-3">
            Партнер вернул все имущество, либо оно было списано.
          </Text>
        )}
      </Spin>
    </>
  )
}
