import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Divider, message, Row, Space, Typography } from 'antd'
import Approval from '../../Approval/Approval'
import { useMutateAdminUpdatePartnerPersonalData } from '../../../../api/react-query/adminPartners'
import { ConfigContext } from '../../../../context/configContext'
import EditPersonalData from '../EditModals/EditPersonalData'
import { formatDrivingNumber, formatSnils, genderList, showExcept } from '../../../../helper'
import { AppConfig } from '../../../../AppConfig'
import { profileScreen } from '../../../../translates'
import { useMutateLog } from '../../../../api/react-query/log'
// import ErpButton from '../EditModals/ErpButton'
import CheckSberIcon from '../../../Common/CheckSberIcon'
import { formatForFrontVehicleNumber } from '../../../Common/FormItems/VehicleNumberFormItem'
import PartnerSmsBan from '../PartnerCommonDataItems/PartnerSmsBan'
import CheckTinkoffIcon from '../../../Common/CheckTinkoffIcon'

const { Text } = Typography
const userRoles = AppConfig.groups

function PartnerPersonalData({
  profile,
  loading,
  loadingPartnerData,
  onCommit,
  onCommitDocument,
  partnerId
  // partnerUuid,
  // statusCode
}) {
  const { personalData, notFormerCivilEmployee } = profile
  const {
    isSecurity,
    isAdmin,
    isCoordinators,
    isCoordinator,
    isPartnerSupport,
    isExternalCoordinator,
    user,
    portalButtonState
  } = useContext(ConfigContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [profileForEdit, setProfileForEdit] = useState({})

  const {
    mutate: mutateUserUpdatePersonalData,
    data: dataUserUpdatePersonalData,
    isError: isErrorUserUpdatePersonalData,
    isLoading: isLoadingUserUpdatePersonalData
  } = useMutateAdminUpdatePartnerPersonalData()

  useEffect(() => {
    if (isErrorUserUpdatePersonalData || dataUserUpdatePersonalData?.isSuccess === false) {
      message.error(dataUserUpdatePersonalData?.errorMessage || 'Ошибка обновления данных партнера')
    }
  }, [isErrorUserUpdatePersonalData, dataUserUpdatePersonalData])

  useEffect(() => {
    if (dataUserUpdatePersonalData?.isSuccess) {
      message.success('Данные партнера успешно обновлены')
      handleCloseEditModal()
    }
  }, [dataUserUpdatePersonalData])

  const { mutate: sendLog } = useMutateLog()

  const handleCommit = (d, o, c) => onCommit(d, o, c)
  const handleOpenEditModal = () => {
    if (user?.online && (isExternalCoordinator || isCoordinators || isCoordinator)) {
      portalButtonState?.stopStatusRequesting()
    }
    setIsModalOpen(true)
    sendLog({
      targetId: `partner/${partnerId}`,
      action: 'EditPersonalData'
    })
  }
  const handleCloseEditModal = () => {
    if (user?.online && (isExternalCoordinator || isCoordinators || isCoordinator)) {
      portalButtonState?.startStatusRequesting()
    }
    setIsModalOpen(false)
  }
  // const getLabel = (field, notBool) => {
  //   let label = notBool ? field : trueFalseList.find(item => item.value === field)?.label
  //   if (!label && ![partnerStatusCodes.new, partnerStatusCodes.draft].includes(statusCode)) {
  //     label = 'Не указано'
  //   }
  //   return label
  // }

  useEffect(() => {
    // Image accept rewrite profile for edit form. Isolate edit form state.
    if (isModalOpen) {
      setProfileForEdit(profile)
    } else {
      setProfileForEdit({})
    }
  }, [isModalOpen])

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row>
          <Col span={7}>
            <Text type="secondary">Фамилия</Text>
          </Col>
          <Col span={1}>
            <div className="wrapCheckIcons">
              <CheckSberIcon profileAndSberIdProperty="surname" profile={profile} />
              <CheckTinkoffIcon profileAndTinkoffIdProperty="surname" profile={profile} />
            </div>
          </Col>
          <Col span={16}>
            <Row justify="space-between">
              <Text>{personalData?.surname}</Text>
              {!isSecurity && personalData?.allowEdit && (
                <Button type="primary" size="small" onClick={handleOpenEditModal}>
                  Редактировать
                </Button>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Text type="secondary">Имя</Text>
          </Col>
          <Col span={1}>
            <div className="wrapCheckIcons">
              <CheckSberIcon profileAndSberIdProperty="firstName" profile={profile} />
              <CheckTinkoffIcon profileAndTinkoffIdProperty="firstName" profile={profile} />
            </div>
          </Col>
          <Col span={16}>
            <Text>{personalData?.firstName}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Text type="secondary">Отчество</Text>
          </Col>
          <Col span={1}>
            <div className="wrapCheckIcons">
              <CheckSberIcon profileAndSberIdProperty="middleName" profile={profile} />
              <CheckTinkoffIcon profileAndTinkoffIdProperty="middleName" profile={profile} />
            </div>
          </Col>
          <Col span={16}>
            <Text>{personalData?.middleName}</Text>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Text type="secondary">Пол</Text>
          </Col>
          <Col span={16}>
            <Text>
              {genderList.find(g => g.value === personalData?.gender)?.label ||
                personalData?.gender}
            </Text>
          </Col>
        </Row>

        {/* {showExcept(user.groupName, [userRoles.contentcoordinators, userRoles.researchercoordinators, userRoles.supervisors]) && ( */}
        {/*  <Row> */}
        {/*    <Col span={7}><Text type='secondary'>Наличие NFC</Text></Col> */}
        {/*    <Col span={1}> */}
        {/*      <div className='wrapCheckIcons'> */}
        {/*        <CheckSberIcon profileAndSberIdProperty='hasNfc' profile={profile} /> */}
        {/*        <CheckTinkoffIcon profileAndTinkoffIdProperty='hasNfc' profile={profile} /> */}
        {/*      </div> */}
        {/*    </Col> */}
        {/*    <Col span={16}><Text>{personalData?.hasNfc ? 'есть' : personalData?.hasNfc === null ? 'не задан' : 'нет'}</Text></Col> */}
        {/*  </Row> */}
        {/* )} */}

        {/* <Row>  */}
        {/*  <Col span={8}><Text type='secondary'>partner_uuid</Text></Col> */}
        {/*  <Col span={16}> */}
        {/*    <Row> */}
        {/*      <Col span={15}><Text>{partnerUuid}</Text></Col> */}
        {/*      {(profile.allowErpSend) && ( */}
        {/*        <Col span={9}><ErpButton partnerId={partnerId} /></Col> */}
        {/*      )} */}
        {/*    </Row> */}
        {/*  </Col> */}
        {/* </Row> */}

        {showExcept(user.groupName, [userRoles.supervisors]) && (
          <Row>
            <Col span={8}>
              <Text type="secondary">Телефон</Text>
            </Col>
            <Col span={6}>
              <Text>{personalData?.mobilePhone}</Text>
            </Col>
            {profile?.smsBanned ? (
              <Col span={10}>
                <PartnerSmsBan partnerId={partnerId} />
              </Col>
            ) : (
              (isCoordinators || isCoordinator || isAdmin) &&
              personalData?.previousPhone && (
                <Col span={10}>
                  <Text>Предыдущий номер: {personalData?.previousPhone}</Text>
                </Col>
              )
            )}
          </Row>
        )}

        {personalData?.emailRequired && showExcept(user.groupName, [userRoles.supervisors]) && (
          <Row>
            <Col span={7}>
              <Text type="secondary">Email</Text>
            </Col>
            <Col span={1}>
              <div className="wrapCheckIcons">
                <CheckSberIcon profileAndSberIdProperty="email" profile={profile} />
                <CheckTinkoffIcon profileAndTinkoffIdProperty="email" profile={profile} />
              </div>
            </Col>
            <Col span={16}>
              <Text>{personalData?.email}</Text>
            </Col>
          </Row>
        )}

        {showExcept(user.groupName, [userRoles.supervisors]) && (
          <Row>
            <Col span={8}>
              <Text type="secondary">Вид услуг</Text>
            </Col>
            <Col span={16}>
              <Text>{personalData?.role}</Text>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={8}>
            <Text type="secondary">Город оказания услуг</Text>
          </Col>
          <Col span={16}>
            <Text>{personalData?.city}</Text>
          </Col>
        </Row>

        {showExcept(user.groupName, [userRoles.supervisors]) &&
          personalData?.roleCode === AppConfig.partnerRoles.automotiveCourier && (
            <Row>
              <Col span={8}>
                <Text type="secondary">Госномер авто</Text>
              </Col>
              <Col span={16}>
                <Text>
                  {formatForFrontVehicleNumber(
                    personalData?.[profileScreen.input.vehicleNumber.name]
                  )}
                </Text>
              </Col>
            </Row>
          )}

        {showExcept(user.groupName, [userRoles.supervisors]) && (
          <Row>
            <Col span={7}>
              <Text type="secondary">Гражданство</Text>
            </Col>
            <Col span={1}>
              <div className="wrapCheckIcons">
                <CheckSberIcon profileAndSberIdProperty="citizenship" profile={profile} />
                <CheckTinkoffIcon profileAndTinkoffIdProperty="citizenship" profile={profile} />
              </div>
            </Col>
            <Col span={16}>
              <Text>{personalData?.citizenship}</Text>
            </Col>
          </Row>
        )}

        {showExcept(user.groupName, [userRoles.supervisors]) && (
          <Row>
            <Col span={7}>
              <Text type="secondary">Дата рождения</Text>
            </Col>
            <Col span={1}>
              <div className="wrapCheckIcons">
                <CheckSberIcon profileAndSberIdProperty="birthDate" profile={profile} />
                <CheckTinkoffIcon profileAndTinkoffIdProperty="birthDate" profile={profile} />
              </div>
            </Col>
            <Col span={16}>
              <Text>{personalData?.birthDate}</Text>
            </Col>
          </Row>
        )}

        {/* {showExcept(user.groupName, [userRoles.supervisors]) && ( */}
        {/*  <Row> */}
        {/*    <Col span={8}><Text type='secondary'>Дееспособность</Text></Col> */}
        {/*    <Col span={16}> */}
        {/*      <Text> */}
        {/*        {personalData?.isLegalAgeCapable ? 'Да' : personalData?.isLegalAgeCapable === false ? 'Нет' : 'Не указана'} */}
        {/*      </Text> */}
        {/*    </Col> */}
        {/*  </Row> */}
        {/* )} */}

        {showExcept(user.groupName, [userRoles.supervisors]) && (
          <Row>
            <Col span={7}>
              <Text type="secondary">Место рождения</Text>
            </Col>
            <Col span={1}>
              <div className="wrapCheckIcons">
                <CheckSberIcon profileAndSberIdProperty="placeOfBirth" profile={profile} />
                <CheckTinkoffIcon profileAndTinkoffIdProperty="placeOfBirth" profile={profile} />
              </div>
            </Col>
            <Col span={16}>
              <Text>{personalData?.placeOfBirth}</Text>
            </Col>
          </Row>
        )}

        {/* {showExcept(user.groupName, [userRoles.supervisors]) && ( */}
        {/*  <Row> */}
        {/*    <Col span={7}><Text type='secondary'>Пол</Text></Col> */}
        {/*    <Col span={1}> */}
        {/*      <div className='wrapCheckIcons'> */}
        {/*        <CheckSberIcon profileAndSberIdProperty='gender' profile={profile} /> */}
        {/*        <CheckTinkoffIcon profileAndTinkoffIdProperty='gender' profile={profile} /> */}
        {/*      </div> */}
        {/*    </Col> */}
        {/*    <Col span={16}><Text>{personalData?.gender}</Text></Col> */}
        {/*  </Row> */}
        {/* )} */}

        {showExcept(user.groupName, [userRoles.supervisors]) && (
          <Row>
            <Col span={7}>
              <Text type="secondary">Тип занятости</Text>
            </Col>
            <Col span={1}>
              <div className="wrapCheckIcons">
                <CheckSberIcon profileAndSberIdProperty="legalForm" profile={profile} />
                <CheckTinkoffIcon profileAndTinkoffIdProperty="legalForm" profile={profile} />
              </div>
            </Col>
            <Col span={16}>
              <Text>{personalData?.legalForm}</Text>
            </Col>
          </Row>
        )}

        {showExcept(user.groupName, [
          userRoles.supervisors,
          userRoles.coordinators,
          userRoles.partnersupport,
          userRoles.externalcoordinators,
          userRoles.coordinator
        ]) && (
          <Row>
            <Col span={8}>
              <Text type="secondary">Являлся госслужащим</Text>
            </Col>
            <Col span={16}>
              <Text>
                {notFormerCivilEmployee !== undefined
                  ? notFormerCivilEmployee
                    ? 'Не являлся'
                    : 'Являлся'
                  : 'Не указано'}
              </Text>
            </Col>
          </Row>
        )}

        {/* {showExcept(user.groupName, [userRoles.supervisors, userRoles.contentcoordinators, userRoles.researchercoordinators]) && ( */}
        {/*  <Row> */}
        {/*    <Col span={8}><Text type='secondary'>Торговая сеть</Text></Col> */}
        {/*    <Col span={16}><Text>{personalData?.storeChain}</Text></Col> */}
        {/*  </Row> */}
        {/* )} */}

        {/* {showExcept(user.groupName, [userRoles.supervisors, userRoles.contentcoordinators, userRoles.researchercoordinators]) && ( */}
        {/*  <Row> */}
        {/*    <Col span={8}><Text type='secondary'>Магазин</Text></Col> */}
        {/*    <Col span={16}><Text>{personalData?.store}</Text></Col> */}
        {/*  </Row> */}
        {/* )} */}

        {showExcept(user.groupName, [userRoles.supervisors]) && (
          <Row>
            <Col span={7}>
              <Text type="secondary">Серия и номер паспорта</Text>
            </Col>
            <Col span={1}>
              <div className="wrapCheckIcons">
                <CheckSberIcon
                  profileAndSberIdProperty="passport.series passport.number"
                  profile={profile}
                />
                <CheckTinkoffIcon
                  profileAndTinkoffIdProperty="passport.series passport.number"
                  profile={profile}
                />
              </div>
            </Col>
            <Col span={16}>
              <Text>
                {personalData?.passport?.series} {personalData?.passport?.number}
              </Text>
            </Col>
          </Row>
        )}

        {showExcept(user.groupName, [userRoles.supervisors]) && (
          <Row>
            <Col span={7}>
              <Text type="secondary">Кем и когда выдан</Text>
            </Col>
            <Col span={1}>
              <div className="wrapCheckIcons">
                <CheckSberIcon
                  profileAndSberIdProperty="passport.issuerName passport.issuerCode passport.issueDate"
                  profile={profile}
                />
                <CheckTinkoffIcon
                  profileAndTinkoffIdProperty="passport.issuerName passport.issuerCode passport.issueDate"
                  profile={profile}
                />
              </div>
            </Col>
            <Col span={16}>
              <Text>
                {[
                  personalData?.passport?.issuerName,
                  ...(personalData?.passport?.issuerCode
                    ? [`(${personalData?.passport?.issuerCode})`]
                    : []),
                  ...(personalData?.passport?.issueDate ? [personalData?.passport?.issueDate] : [])
                ]
                  .filter(Boolean)
                  .join(' ')}
              </Text>
            </Col>
          </Row>
        )}
        {/* { */}
        {/*  showExcept(user.groupName, [userRoles.supervisors]) && ( */}
        {/*    <Row> */}
        {/*      <Col span={8}><Text type='secondary'>Тип регистрации</Text></Col> */}
        {/*      <Col span={16}>{AppConfig.registrationTypeLabels[personalData?.registrationKindCode]}</Col> */}
        {/*    </Row> */}
        {/*  ) */}
        {/* } */}
        {showExcept(user.groupName, [userRoles.supervisors]) && (
          <Row>
            <Col span={7}>
              <Text type="secondary">Адрес регистрации</Text>
            </Col>
            <Col span={1}>
              <div className="wrapCheckIcons">
                <CheckSberIcon profileAndSberIdProperty="registrationAddress" profile={profile} />
                <CheckTinkoffIcon
                  profileAndTinkoffIdProperty="registrationAddress"
                  profile={profile}
                />
              </div>
            </Col>
            <Col span={16}>
              <Text>
                {[
                  personalData?.registrationAddress?.zipCode,
                  personalData?.registrationAddress?.country,
                  personalData?.registrationAddress?.region,
                  personalData?.registrationAddress?.district,
                  personalData?.registrationAddress?.city,
                  personalData?.registrationAddress?.settlement,
                  personalData?.registrationAddress?.street,
                  personalData?.registrationAddress?.house,
                  personalData?.registrationAddress?.building,
                  personalData?.registrationAddress?.apartment
                ]
                  .filter(Boolean)
                  .join(', ')}
              </Text>
            </Col>
          </Row>
        )}

        {/* {showExcept(user.groupName, [ */}
        {/*  userRoles.supervisors, */}
        {/*  userRoles.coordinators, */}
        {/*  userRoles.externalcoordinators, */}
        {/*  userRoles.contentcoordinators, */}
        {/*  userRoles.researchercoordinators, */}
        {/*  userRoles.partnersupport, */}
        {/*  userRoles.coordinator */}
        {/* ]) && ( */}
        {/*  <Row> */}
        {/*    <Col span={8}><Text type='secondary'>Размер одежды</Text></Col> */}
        {/*    <Col span={16}><Text>{getLabel(personalData?.clothingSize, true)}</Text></Col> */}
        {/*  </Row> */}
        {/* )} */}

        {/* {showExcept(user.groupName, [ */}
        {/*  userRoles.coordinators, userRoles.contentcoordinators, userRoles.researchercoordinators, */}
        {/*  userRoles.partnersupport, userRoles.externalcoordinators, userRoles.coordinator */}
        {/* ]) && ( */}
        {/*  <Row> */}
        {/*    <Col span={8}><Text type='secondary'>Медкнижка</Text></Col> */}
        {/*    <Col span={16}><Text>{getLabel(lmkDetails?.hasMedicalBook)}</Text></Col> */}
        {/*  </Row> */}
        {/* )} */}

        {/* {lmkDetails?.hasMedicalBook && showExcept(user.groupName, [ */}
        {/*  userRoles.coordinators, userRoles.contentcoordinators, userRoles.researchercoordinators, */}
        {/*  userRoles.partnersupport, userRoles.externalcoordinators, userRoles.coordinator */}
        {/* ]) && ( */}
        {/*  <> */}
        {/*    <Row> */}
        {/*      <Col span={8}><Text type='secondary'>{profileScreen.input.medicalBookNumber.label}</Text></Col> */}
        {/*      <Col span={16}><Text>{lmkDetails?.medicalBookNumber}</Text></Col> */}
        {/*    </Row> */}
        {/*    <Row> */}
        {/*      <Col span={8}><Text type='secondary'>{profileScreen.input.medicalexaminationDate.label}</Text></Col> */}
        {/*      <Col span={16}><Text>{lmkDetails?.examinationDate}</Text></Col> */}
        {/*    </Row> */}
        {/*    <Row> */}
        {/*      <Col span={8}><Text type='secondary'>Дата окончания медкомиссии</Text></Col> */}
        {/*      <Col span={16}><Text>{lmkDetails?.examinationExpireDate}</Text></Col> */}
        {/*    </Row> */}
        {/*    <Row> */}
        {/*      <Col span={8}><Text type='secondary'>{profileScreen.input.medicalattestationDate.label}</Text></Col> */}
        {/*      <Col span={16}><Text>{lmkDetails?.attestationDate}</Text></Col> */}
        {/*    </Row> */}
        {/*    <Row> */}
        {/*      <Col span={8}><Text type='secondary'>Дата окончания аттестации</Text></Col> */}
        {/*      <Col span={16}><Text>{lmkDetails?.attestationExpireDate}</Text></Col> */}
        {/*    </Row> */}
        {/*  </> */}
        {/* )} */}

        {/* {showExcept(user.groupName, [ */}
        {/*  userRoles.supervisors, */}
        {/*  userRoles.coordinators, */}
        {/*  userRoles.externalcoordinators, */}
        {/*  userRoles.contentcoordinators, */}
        {/*  userRoles.researchercoordinators, */}
        {/*  userRoles.partnersupport, */}
        {/*  userRoles.coordinator */}
        {/* ]) && ( */}
        {/*  <Row> */}
        {/*    <Col span={8}><Text type='secondary'>Вакцинация</Text></Col> */}
        {/*    <Col span={16}><Text>{getLabel(personalData?.vaccinated)}</Text></Col> */}
        {/*  </Row> */}
        {/* )} */}

        {showExcept(user.groupName, [userRoles.supervisors]) && (
          <Row>
            <Col span={8}>
              <Text type="secondary">СНИЛС</Text>
            </Col>
            <Col span={16}>
              <Text>{formatSnils(personalData?.snils)}</Text>
            </Col>
          </Row>
        )}

        {showExcept(user.groupName, [userRoles.supervisors]) && (
          <Row>
            <Col span={8}>
              <Text type="secondary">Серия и номер ВУ</Text>
            </Col>
            <Col span={16}>
              <Text>{formatDrivingNumber(personalData?.drivingLicence?.seriesNumber)}</Text>
            </Col>
          </Row>
        )}

        {showExcept(user.groupName, [userRoles.supervisors]) && (
          <Row>
            <Col span={8}>
              <Text type="secondary">Кем и когда выдано ВУ</Text>
            </Col>
            <Col span={16}>
              <Text>
                {[
                  personalData?.drivingLicence?.issuerName,
                  personalData?.drivingLicence?.issueDate
                ].join(' ')}
              </Text>
            </Col>
          </Row>
        )}
      </Space>
      {personalData?.allowReview && (
        <>
          <Divider />
          <Approval
            loading={loading}
            defaultComment={personalData?.statusReason}
            defaultOutcome={personalData?.status}
            onCommit={({ outcome, comment }) => handleCommit('personalData', outcome, comment)}
            targetType={AppConfig.reasonTargets.personalData}
            autocomplete={Boolean(
              isAdmin ||
                isCoordinators ||
                isCoordinator ||
                isPartnerSupport ||
                isExternalCoordinator
            )}
          />
        </>
      )}
      {isModalOpen && (
        <EditPersonalData
          onCancel={handleCloseEditModal}
          profile={profileForEdit}
          partnerId={partnerId}
          onChange={mutateUserUpdatePersonalData}
          visible={isModalOpen}
          loading={isLoadingUserUpdatePersonalData}
          loadingPartnerData={loadingPartnerData}
          loadingDocumentApproval={loading}
          onCommitDocument={onCommitDocument}
        />
      )}
    </>
  )
}

export default PartnerPersonalData
