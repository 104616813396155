import { Form, Input } from 'antd'
import React, { useMemo } from 'react'
import { profileScreen } from '../../../../translates'
import { validatePassportNumber } from '../../../../helper'
import SberIcon from '../../SberIcon'
import TinkoffIcon from '../../TinkoffIcon'

const ZipCodeFormItem = ({
  label = profileScreen.input.registrationAddressZipCode.label,
  rules = undefined,
  requiredRule = true,
  messageRule = profileScreen.input.registrationAddressZipCode.message,
  size = 'large',
  placeholder = profileScreen.input.registrationAddressZipCode.placeholder,
  classNameInput = '',
  showSberIcon = false,
  showTinkoffIcon = false
}) => {
  const labelParam = useMemo(() => {
    return showSberIcon || showTinkoffIcon ? (
      <>
        {label}
        {showSberIcon && <SberIcon style={{ marginLeft: '0.5rem' }} />}
        {showTinkoffIcon && <TinkoffIcon style={{ marginLeft: '0.5rem' }} />}
      </>
    ) : (
      label
    )
  }, [label, showSberIcon, showTinkoffIcon])

  return (
    <Form.Item
      name={[
        'personalData',
        'registrationAddress',
        profileScreen.input.registrationAddressZipCode.name
      ]}
      label={labelParam}
      rules={
        rules ?? [
          { required: requiredRule, message: messageRule, validator: validatePassportNumber }
        ]
      }
    >
      <Input type="number" size={size} placeholder={placeholder} className={classNameInput} />
    </Form.Item>
  )
}
export default ZipCodeFormItem
