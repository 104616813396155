export const actScreen = {
  title: 'Акт приема-передачи имущества',
  subTitle: 'Период оказания услуг',
  acceptTitle:
    'Нажимая на кнопку "Отправить", Вы подтверждаете, что ознакомлены, согласны с условиями акта и полностью принимаете их.',
  prefixFrom: 'С',
  prefixTo: 'по',
  prefixBy: 'от',
  button: {
    submit: 'Отправить'
  },
  errors: {
    acceptTitle: 'Подтвердите, что ознакомились с Условиями акта',
    emptyTitle: 'Акт не найден',
    act: 'Не удалось загрузить акт',
    actAccept: 'Не удалоось сохранить согласие'
  },
  success: 'Акт согласован'
}
