import React, { useEffect, useContext, useReducer, useMemo } from 'react'
import { Button, Input, message, Col, AutoComplete, Typography, Modal, Row } from 'antd'
import { AppConfig } from '../../../../AppConfig'
import { useMutateAdminUpdateShopperStatus } from '../../../../api/react-query/adminPartners'
import { ConfigContext } from '../../../../context/configContext'
import { useDeclineReasonsQuery } from '../../../../api/react-query/dict'
import { simpleReducer } from '../../../../helper'
import './ShopperStatus.css'

const { Text } = Typography

const initialState = {
  status: null,
  declineReasons: [],
  comment: null,
  errorComment: false,
  isShow: false
}

function ShopperStatusButton({ partnerId, profile, loading, setPartnerData, statusType }) {
  const { setConfig } = useContext(ConfigContext)
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    isLoading: isLoadingAdminUpdateShopperStatus,
    mutateAsync: mutateAdminUpdateShopperStatus
  } = useMutateAdminUpdateShopperStatus()

  useEffect(() => {
    setConfig({ loadingData: { isLoadingAdminUpdateShopperStatus } })
  }, [isLoadingAdminUpdateShopperStatus])

  const handleSubmitAccountStatus = async status => {
    setState({ errorComment: false })
    if (status) {
      try {
        const result = await mutateAdminUpdateShopperStatus({
          id: partnerId,
          status,
          data: { reason: state.comment || '' }
        })
        if (result?.isSuccess) {
          setPartnerData({ ...result?.partner })
        } else {
          message.error(result?.errorMessage || 'Ошибка доступа к данным')
          setState(initialState)
        }
      } catch (error) {
        console.error(error)
        setState(initialState)
      }
    }
  }

  const { data: dataDeclineReasons, isSuccess: isSuccessReasons } = useDeclineReasonsQuery(
    AppConfig.reasonTargets.verification
  )

  useEffect(() => {
    if (isSuccessReasons && dataDeclineReasons?.isSuccess) {
      setState({
        declineReasons: dataDeclineReasons.result.map(item => ({
          label: item.reason,
          value: item.reason
        }))
      })
    }
  }, [dataDeclineReasons, isSuccessReasons])

  const handleSave = () => handleSubmitAccountStatus(statusType)
  const handleCommentChange = e => setState({ comment: e.target.value })
  const handleAutoCommentChange = item => {
    setState({
      comment: state.comment ? `${state.comment} ${item}` : item
    })
  }
  const handleAutoCommentClear = () => setState({ comment: null })
  const okButtonProps = useMemo(
    () => ({ className: 'green', loading: isLoadingAdminUpdateShopperStatus }),
    [isLoadingAdminUpdateShopperStatus]
  )
  const cancelButtonProps = useMemo(
    () => ({ disabled: isLoadingAdminUpdateShopperStatus }),
    [isLoadingAdminUpdateShopperStatus]
  )

  if (!profile?.shopperAccount?.allowEdit) return null

  return (
    <>
      <Button
        onClick={() => setState({ isShow: true })}
        className={`mb-3 mr-3 status-${statusType}`}
        disabled={isLoadingAdminUpdateShopperStatus || loading}
      >
        {statusType === AppConfig.shopperStatuses.verified && 'Рекомендован СБ'}
        {statusType === AppConfig.shopperStatuses.notfound && 'Ошибка верификации'}
      </Button>

      <Modal
        width={700}
        visible={state.isShow}
        title={
          (statusType === AppConfig.shopperStatuses.verified && 'Рекомендован') ||
          (statusType === AppConfig.shopperStatuses.notfound && 'Ошибка верификации')
        }
        onCancel={() => setState(initialState)}
        okButtonProps={okButtonProps}
        cancelButtonProps={cancelButtonProps}
        onOk={handleSave}
      >
        <Row>
          <Col span={4}>Причина:</Col>
          <Col span={20}>
            <AutoComplete
              options={state.declineReasons}
              style={{ width: '100%' }}
              onSelect={handleAutoCommentChange}
              allowClear
              onClear={handleAutoCommentClear}
              defaultValue={state.comment}
              value={state.comment}
              status="error"
            >
              <Input
                style={{ width: '100%', ...(state.errorComment && { borderColor: '#ff4d4f' }) }}
                defaultValue={state.comment}
                value={state.comment}
                onChange={handleCommentChange}
                placeholder="Введите причину"
              />
            </AutoComplete>
          </Col>
        </Row>
        {state.errorComment && (
          <div style={{ width: '100%' }}>
            <Text type="danger">Введите причину</Text>
          </div>
        )}
      </Modal>
    </>
  )
}

export default ShopperStatusButton
