import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, message, Modal, Row, Spin, Table, Typography } from 'antd'
import Approval from '../../Approval/Approval'
import { errors, profileScreen } from '../../../../translates'
import {
  useMutateAdminAcceptFireBriefingForm,
  useMutateAdminDeclineFireBriefingForm,
  useQueryAdminFireBriefingForm
} from '../../../../api/react-query/adminPartners'
import { ACCEPT } from '../../../../Constants'
import {
  arraySortByArray,
  createApiDataFromForm,
  createFormDataFromApi,
  flattenObject,
  restoreFlattenObject,
  validateDateNotFuture
} from '../../../../helper'
import './EditModals.css'
import { AppConfig } from '../../../../AppConfig'
import CityChainStoreItem from './CityChainStoreItem'

const { Text } = Typography
const columns = [
  {
    title: 'Название поля',
    dataIndex: 'fieldName',
    width: 300
  },
  {
    title: 'Значение',
    dataIndex: 'newValue',
    editable: true
  }
]

function EditFireBriefingRequest({ profile, disabled, partnerId, viewOnly = false }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [profileData, setProfileData] = useState([])
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})
  const [fieldsHasErrors, setFieldsHasErrors] = useState([])
  const [closeModalAfterAccept, setCloseModalAfterAccept] = useState(false)

  const { data: fireBriefingFormData, isFetching: isFetchingFireBriefingFormData } =
    useQueryAdminFireBriefingForm(partnerId, {
      enabled: isModalVisible,
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0
    })

  const {
    mutate: acceptRequest,
    data: acceptRequestData,
    isLoading: acceptRequestLoading,
    isSuccess: acceptRequestSuccess,
    isError: acceptRequestError
  } = useMutateAdminAcceptFireBriefingForm()

  const {
    mutate: declineRequest,
    data: declineRequestData,
    isLoading: declineRequestLoading,
    isSuccess: declineRequestSuccess,
    isError: declineRequestError
  } = useMutateAdminDeclineFireBriefingForm()

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)

  const prepareAcceptRequestData = newValues => {
    const inputs = profileScreen.input
    newValues = restoreFlattenObject(newValues)
    const data = createApiDataFromForm({
      fireBriefingDetails: Object.fromEntries(
        Object.entries({
          ...newValues
        }).filter(([, value]) => value !== undefined)
      )
    })?.fireBriefingDetails
    return {
      data: { [inputs.briefingDate.name]: data[inputs.briefingDate.name] }
    }
  }

  const handleCommit = ({ outcome, comment: reason }) => {
    setBackendFieldsErrors({})
    if (outcome === ACCEPT) {
      const newValues = Object.fromEntries(profileData.map(item => [item.key, item.newValue]))
      setCloseModalAfterAccept(true)
      acceptRequest({
        id: partnerId,
        data: prepareAcceptRequestData({ ...newValues })
      })
    } else {
      declineRequest({ id: partnerId, reason })
    }
  }

  const flattenProfileData = useMemo(
    () =>
      flattenObject(
        createFormDataFromApi({
          fireBriefingDetails: profile?.fireBriefingDetails
        })?.fireBriefingDetails
      ),
    [profile]
  )

  useEffect(() => {
    const data = []
    const formData = { ...(fireBriefingFormData?.form || {}) }
    delete formData.status
    delete formData.statusCode
    delete formData.statusReason
    delete formData.storeId
    const flattenData = flattenObject(
      createFormDataFromApi({ fireBriefingDetails: formData })?.fireBriefingDetails
    )

    const keysList = [profileScreen.input.briefingDate.name]

    const keys = arraySortByArray(Object.keys(flattenData), keysList)
    keys
      ?.filter(key => keysList.includes(key))
      .map(key => {
        const fieldName = profileScreen.input[key.replace('.', '')]?.label
        data.push({
          key,
          required: true,
          fieldName,
          newValue: flattenData?.[key],
          isDifferentFromProfile: flattenProfileData?.[key] !== flattenData?.[key],
          specialValidation: [
            {
              message: errors.futureDate,
              validator: (rule, value) => validateDateNotFuture({ rule, date: value })
            }
          ]
        })
      })
    setProfileData(data)
  }, [fireBriefingFormData?.form, flattenProfileData, profile])

  useEffect(() => {
    if (acceptRequestError) {
      message.error('Не удалось принять запрос.')
    }
  }, [acceptRequestError])

  useEffect(() => {
    if (acceptRequestSuccess) {
      if (acceptRequestData?.errorMessage) {
        return message.error(acceptRequestData?.errorMessage || 'Не удалось принять запрос.')
      }
      if (closeModalAfterAccept) {
        handleCancel()
        setCloseModalAfterAccept(false)
      }
      message.success('Запрос на изменение данных принят.')
    }
  }, [acceptRequestData, acceptRequestSuccess])

  useEffect(() => {
    if (declineRequestError) {
      message.error('Не удалось отклонить запрос.')
    }
  }, [declineRequestError])

  useEffect(() => {
    if (declineRequestSuccess) {
      if (declineRequestData?.errorMessage) {
        return message.error(declineRequestData?.errorMessage || 'Не удалось отклонить запрос.')
      }
      handleCancel()
      message.success('Запрос на изменение данных отклонен.')
    }
  }, [declineRequestData, declineRequestSuccess])

  const tableProps = useMemo(
    () => ({
      backendFieldsErrors,
      setHasErrors: setFieldsHasErrors,
      columns,
      dataSource: profileData,
      setDataSource: setProfileData,
      pagination: false
    }),
    [backendFieldsErrors, profileData, viewOnly]
  )

  return (
    <div>
      <Button ghost type="primary" className="w-150" disabled={disabled} onClick={showModal}>
        {viewOnly ? 'Посмотреть' : 'Проверить'}
      </Button>
      <Modal
        width={1200}
        style={{ top: 20 }}
        title={
          <Row gutter={[12, 6]} align="middle">
            <Col className="mr-3">
              <b>Пожарный инструктаж</b>
            </Col>
          </Row>
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="assetsModalForm"
      >
        <Spin
          spinning={isFetchingFireBriefingFormData || acceptRequestLoading || declineRequestLoading}
        >
          {fireBriefingFormData?.form?.statusReason && (
            <Alert
              className="mb-3"
              message={<Text type="secondary">Причины отказа</Text>}
              description={fireBriefingFormData?.form.statusReason}
              type="error"
            />
          )}
          <Table {...tableProps} />

          <CityChainStoreItem
            data={{
              store: {
                city: profile?.personalData?.city,
                chain: profile?.personalData?.storeChain,
                name: profile?.personalData?.store
              }
            }}
            acceptButton={null}
          />
          <br />
          {viewOnly ? (
            <Button type="primary" onClick={handleCancel}>
              Закрыть
            </Button>
          ) : (
            <Approval
              onCommit={handleCommit}
              onCancel={handleCancel}
              multiline
              disabled={fieldsHasErrors?.length}
              autocomplete
              targetType={AppConfig.reasonTargets.fireBriefingForm}
            />
          )}
        </Spin>
      </Modal>
    </div>
  )
}

export default EditFireBriefingRequest
