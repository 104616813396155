import { Form, Input } from 'antd'
import React, { useMemo } from 'react'
import { profileScreen } from '../../../translates'
import { validateEmail } from '../../../helper'
import SberIcon from '../SberIcon'
import TinkoffIcon from '../TinkoffIcon'

const EmailFormItem = ({
  label = profileScreen.input.email.label,
  rules = undefined,
  requiredRule = true,
  messageRule = profileScreen.input.email.message,
  size = 'large',
  placeholder = profileScreen.input.email.placeholder,
  validator = validateEmail,
  showSberIcon = false,
  showTinkoffIcon = false
}) => {
  const labelParam = useMemo(() => {
    return showSberIcon || showTinkoffIcon ? (
      <>
        {label}
        {showSberIcon && <SberIcon style={{ marginLeft: '0.5rem' }} />}
        {showTinkoffIcon && <TinkoffIcon style={{ marginLeft: '0.5rem' }} />}
      </>
    ) : (
      label
    )
  }, [label, showSberIcon, showTinkoffIcon])

  return (
    <Form.Item
      name={['personalData', 'email']}
      label={labelParam}
      rules={
        rules ?? [
          { required: requiredRule, message: messageRule, ...(validator ? { validator } : {}) }
        ]
      }
    >
      <Input type="email" size={size} placeholder={placeholder} />
    </Form.Item>
  )
}
export default EmailFormItem
