import React, { useEffect, useReducer } from 'react'
import { Button, Form, Input, message, Modal, Spin, Typography } from 'antd'
import { simpleReducer, validatePhone } from '../../../helper'
import { authScreen } from '../../../translates'
import './ModalChangePhone.css'
import {
  useMutateFinishPhonePartner,
  useMutateStartPhonePartner
} from '../../../api/react-query/partners'
import { AppConfig } from '../../../AppConfig'

const { Title, Text } = Typography

const inputModes = {
  inputPhone: 'inputPhone',
  inputSms: 'inputSms'
}

const buttonLabels = {
  [inputModes.inputPhone]: 'Получить код',
  [inputModes.inputSms]: 'Подтвердить'
}

const initialState = {
  visible: false,
  inputMode: inputModes.inputPhone,
  phoneErrorCode: null
}

function ModalChangePhone({ onSuccess }) {
  const [state, setState] = useReducer(simpleReducer, initialState)
  const [form] = Form.useForm()

  const {
    mutate: startPhone,
    data: dataStartPhone,
    isSuccess: isSuccessStartPhone,
    isError: isErrorStartPhone,
    isLoading: isLoadingStartPhone
  } = useMutateStartPhonePartner()

  useEffect(() => {
    if (isSuccessStartPhone && dataStartPhone?.isSuccess) {
      message.success('Смс с кодом отправлено')
      setState({ inputMode: inputModes.inputSms })
    } else if (isErrorStartPhone || dataStartPhone?.errorMessage) {
      message.error(dataStartPhone?.errorMessage || 'Ошибка отправки данных для смс')
      if (dataStartPhone?.errorCode === AppConfig.phoneEditErrorCodes.PhoneDuplicate) {
        setState({ phoneErrorCode: 'Номер уже используется' })
        console.log('dataStartPhone', dataStartPhone)
      }
    }
  }, [isSuccessStartPhone, isErrorStartPhone, dataStartPhone])

  const {
    mutate: finishPhone,
    data: dataFinishPhone,
    isSuccess: isSuccessFinishPhone,
    isError: isErrorFinishPhone,
    isLoading: isLoadingFinishPhone
  } = useMutateFinishPhonePartner()

  useEffect(() => {
    if (isSuccessFinishPhone && dataFinishPhone?.isSuccess) {
      message.success('Номер изменен')
      setState({ visible: false })
      onSuccess?.(dataFinishPhone?.mobilePhone)
    } else if (isErrorFinishPhone || dataFinishPhone?.errorMessage) {
      message.error(dataFinishPhone?.errorMessage || 'Ошибка отправки кода из смс')
    }
  }, [isSuccessFinishPhone, isErrorFinishPhone, dataFinishPhone])

  const handleOpen = event => {
    event.preventDefault()
    setState({ visible: true })
  }

  const handleClose = () => {
    setState({ visible: false })
  }

  const handleChangeForm = () => {
    setState({ phoneErrorCode: null })
  }

  const handleSubmit = values => {
    if (state.inputMode === inputModes.inputPhone) {
      startPhone({
        mobilePhone: values?.mobilePhone?.trim()
      })
    }
    if (state.inputMode === inputModes.inputSms) {
      finishPhone({
        code: values?.code?.trim()
      })
    }
  }

  useEffect(() => {
    setState({
      ...initialState,
      visible: state.visible
    })
    form.resetFields()
  }, [state.visible])

  return (
    <div className="buttonChangePhone">
      <a href="" onClick={handleOpen}>
        Изменить номер
      </a>
      <Modal
        className="modalChangePhone"
        visible={state.visible}
        onCancel={handleClose}
        centered
        footer={null}
        closable
        destroyOnClose
        maskClosable={false}
      >
        <Title>Смена номера</Title>
        {state.inputMode === inputModes.inputPhone && (
          <div className="message">Укажите номер, с которого будете принимать заказы</div>
        )}

        {state.inputMode === inputModes.inputSms && <Text>Введите код из СМС</Text>}
        <Spin spinning={isLoadingStartPhone || isLoadingFinishPhone}>
          <Form form={form} onFinish={handleSubmit} onFieldsChange={handleChangeForm}>
            {state.inputMode === inputModes.inputPhone && (
              <Form.Item
                name="mobilePhone"
                rules={[
                  {
                    required: true,
                    message: authScreen.input.phoneNumber.message,
                    validator: validatePhone
                  }
                ]}
                help={state.phoneErrorCode}
                validateStatus={state.phoneErrorCode ? 'error' : null}
              >
                <Input
                  size="large"
                  type="tel"
                  placeholder={authScreen.input.phoneNumber.placeholder}
                />
              </Form.Item>
            )}
            {state.inputMode === inputModes.inputSms && (
              <Form.Item
                name="code"
                rules={[{ required: true, message: authScreen.input.smsCode.message }]}
              >
                <Input
                  className="noScrollNumber"
                  size="large"
                  type="number"
                  placeholder={authScreen.input.smsCode.placeholder}
                />
              </Form.Item>
            )}
            <Form.Item>
              <Button className="buttonSend" block size="large" type="primary" htmlType="submit">
                {buttonLabels[state.inputMode]}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
}

export default ModalChangePhone
