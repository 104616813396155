import React, { useCallback, useEffect, useReducer, useRef } from 'react'
import { Input, Row, Col, Typography, Drawer, Switch, Divider, message } from 'antd'

import { simpleReducer, sortByName } from '../../../helper'
import OrgTree from '../../../components/Admin/Tree/OrgTree'

import './StoreManagement.css'
import EditStoreManagement from './EditStoreManagement'
import { useOrgTreeQuery } from '../../../api/react-query/org'
import { twoMinutes } from '../../../Constants'
import ChainSelector from '../../../components/ChainSelector/ChainSelector'
import { useChains } from '../../../hooks'
const { Title, Text } = Typography
const inputGroupAndTitleHeight = 210

export default function StoreManagement() {
  const initState = {
    chains: [],
    orgTree: [],
    filteredCity: '',
    filteredChain: '',
    filteredStore: '',
    isModalOpen: false,
    isStoreOpen: false,
    isStoreClose: false,
    modalData: {
      title: '',
      newEntity: '',
      editEntity: '',
      city: '',
      chain: '',
      store: ''
    }
  }
  const [state, setState] = useReducer(simpleReducer, initState)

  const containerRef = useRef(null)

  const {
    data: treeData,
    isError: isErrorTree,
    isSuccess: isSuccessTree
  } = useOrgTreeQuery(
    {
      withClosed: !state.isStoreOpen,
      withEmptyCity: !state.isStoreOpen && !state.isStoreClose,
      withOpened: !state.isStoreClose
    },
    {
      staleTime: twoMinutes
    }
  )
  useEffect(() => {
    if (isErrorTree) {
      message.error('Ошибка доступа к данным')
    }
  }, [isErrorTree])
  useEffect(() => {
    if (isSuccessTree) {
      let store
      if (state.modalData?.editEntity === 'store') {
        store = treeData?.cities
          ?.find(city => city.id === state.modalData?.city?.id)
          ?.chains?.find(chain => chain.id === state.modalData?.chain?.id)
          ?.stores?.find(store => store.id === state.modalData?.store?.id)
      }
      setState({
        orgTree: sortByName(treeData?.cities),
        modalData: {
          ...state.modalData,
          ...(store && { store })
        }
      })
    }
  }, [isSuccessTree, treeData])

  const { data: chains } = useChains()
  useEffect(() => {
    if (chains) {
      const data = chains?.map(e => e.name)
      setState({ fullChains: chains, chains: data })
    }
  }, [chains])

  const onSelect = (selectedKeys, info) => {
    const data = info?.node?.key?.split(',') || []

    let title = 'Редактировать '
    let newEntity = ''
    let editEntity = ''
    let city = null
    let chain = null
    let store = null

    if (info?.node?.key?.includes('new')) {
      title = 'Создать '
    }
    switch (true) {
      case data.length === 1:
        title += 'город'
        if (info?.node?.key.includes('new')) {
          newEntity = 'city'
        } else {
          city = state.orgTree.find(city => city.id === data[0])
          editEntity = 'city'
        }
        break
      case data.length === 2:
        title += 'сеть'
        city = state.orgTree.find(city => city.id === data[0])
        if (info?.node?.key.includes('new')) {
          newEntity = 'chain'
        } else {
          chain = city.chains.find(chain => chain.id === data[1])
          editEntity = 'chain'
        }
        break
      case data.length === 3:
        title += 'магазин'
        city = state.orgTree.find(city => city.id === data[0])
        chain = city.chains.find(chain => chain.id === data[1])
        if (info?.node?.key.includes('new')) {
          newEntity = 'store'
        } else {
          store = chain.stores.find(store => store.id === data[2])
          editEntity = 'store'
        }
        break
    }
    const modalData = {
      title,
      newEntity,
      editEntity,
      city,
      chain,
      store,
      chains: state.fullChains || [],
      cities: state.orgTree
    }

    setState({
      modalData,
      isModalOpen: true
    })
  }

  const handleChangeCity = ({ target }) => setState({ filteredCity: target.value })
  const handleSwitchOpen = value => setState({ isStoreOpen: value, isStoreClose: false })
  const handleSwitchClose = value => setState({ isStoreOpen: false, isStoreClose: value })
  const handleOnSelect = useCallback(value => setState({ filteredChain: value }), [])
  const handleOnClear = useCallback(() => setState({ filteredChain: '' }), [])

  return (
    <div ref={containerRef} className="tree-container">
      <Title level={3} className="title-management">
        Оргструктура
      </Title>
      <Input.Group size="large">
        <Row gutter={8}>
          <Col span={5}>
            <Input placeholder="Введите город" onChange={handleChangeCity} allowClear />
          </Col>
          <Col span={5}>
            <ChainSelector
              onSelect={handleOnSelect}
              onClear={handleOnClear}
              chains={state.chains}
            />
          </Col>
          {/* <Col span={5}> */}
          {/*  <Input placeholder='Введите магазин' /> */}
          {/* </Col> */}
        </Row>
        <Row gutter={8} className="mt-3">
          <Col span={24}>
            <Switch className="mr-3" onChange={handleSwitchOpen} checked={state.isStoreOpen} />
            <Text>показать только открытые магазины</Text>
          </Col>
          <Col span={24} className="mt-3">
            <Switch className="mr-3" onChange={handleSwitchClose} checked={state.isStoreClose} />
            <Text>показать только закрытые магазины</Text>
          </Col>
        </Row>
      </Input.Group>
      <Divider />
      <OrgTree
        withEditing
        onSelect={onSelect}
        isStoreClose={state.isStoreClose}
        isStoreOpen={state.isStoreOpen}
        withEmptyCity={!state.isStoreOpen && !state.isStoreClose}
        filteredCity={state.filteredCity}
        filteredChain={state.filteredChain}
        height={containerRef?.current?.offsetHeight - inputGroupAndTitleHeight || 100}
        selectable
      />
      {state.isModalOpen && (
        <Drawer
          width={800}
          placement="right"
          closable={false}
          onClose={() => {
            setState({ isModalOpen: false })
          }}
          visible={state.isModalOpen}
        >
          <EditStoreManagement
            {...state.modalData}
            onClose={() => setState({ isModalOpen: false })}
          />
        </Drawer>
      )}
    </div>
  )
}
