import React from 'react'
import { Button, Modal, Typography } from 'antd'
import { nbsp } from '../../../Constants'
import './ModalUploadPhoto.css'
import { UploadOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

function ModalUploadPhoto({
  isVisible = false,
  onCancel = () => {},
  onOk = () => {},
  isHideOk = false
}) {
  return (
    <Modal
      centered
      visible={isVisible}
      okText="Сохранить"
      cancelText="Отменить"
      onCancel={onCancel}
      destroyOnClose
      footer={null}
      closable={false}
      maskClosable={false}
    >
      <div className="modal-upload-photo">
        <img src="/img/photo-doc/passport-extra-reg.png" style={{ width: '100%' }} />
        <Title level={4}>Убедитесь, что загрузили все страницы</Title>
        <Text>
          Если разворот оканчивается штампом «зарегистрирован»{nbsp}— нужно загрузить следующий
          разворот, даже если он пустой
        </Text>
        {!isHideOk && (
          <Button onClick={onOk} block size="large" icon={<UploadOutlined />}>
            Загрузить еще
          </Button>
        )}
        <Button onClick={onCancel} ghost block size="large">
          Назад
        </Button>
      </div>
    </Modal>
  )
}

export default ModalUploadPhoto
