import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Document, Page, pdfjs } from 'react-pdf'
import { makeFileUrl } from '../../../../helper'
import { Spin } from 'antd'
import ScrollContainer from 'react-indiana-drag-scroll'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function PdfViewer({ fileId, zoom }) {
  const [file, setFile] = useState(null)
  const [currentPage] = useState(1)

  useEffect(() => {
    if (fileId && !file) {
      axios
        .get(makeFileUrl(fileId), {
          responseType: 'blob'
        })
        .then(response => {
          response.arrayBuffer().then(data => setFile({ data }))
        })
    }
  }, [fileId])

  return (
    <Document file={file} onLoadError={e => console.error(e)} loading={<Spin spinning />}>
      <ScrollContainer className="scroll-container" hideScrollbars={false}>
        <Page
          scale={zoom}
          renderTextLayer={false}
          loading={<Spin spinning />}
          pageNumber={currentPage}
        />
      </ScrollContainer>
    </Document>
  )
}

export default PdfViewer
