import React from 'react'
import { Button, Input, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

const handleSearch = (selectedKeys, confirm) => {
  confirm()
}

const handleReset = clearFilters => {
  clearFilters()
}

let searchInput

function getColumnSearchProps(dataIndex, placeholder = '', allowedPattern = null) {
  const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          searchInput = node
        }}
        placeholder={'Поиск ' + placeholder}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        onKeyPress={e => {
          if (allowedPattern && !allowedPattern.test(e.key)) {
            e.preventDefault()
          }
        }}
        style={{
          width: 188,
          marginBottom: 8,
          display: 'block'
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
          disabled={!selectedKeys?.[0]?.length || selectedKeys?.[0]?.length < 2}
        >
          Фильтр
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Очистить
        </Button>
      </Space>
    </div>
  )
  const filterIcon = filtered => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  )
  const onFilterDropdownVisibleChange = visible => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100)
    }
  }

  return {
    filterDropdown,
    filterIcon,
    onFilterDropdownVisibleChange
  }
}
export default getColumnSearchProps
