import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import { message, Space, Row, Col, Typography, Button, Modal } from 'antd'
import moment from 'moment-timezone'

import { useMutateUserBlock, useMutateUserUnblock } from '../../../api/react-query/users'
// import { ALL } from '../../../Constants'
// import { AppConfig } from '../../../AppConfig'
// import CityTree from '../../../components/Admin/Tree/CityTree'

const { Title, Text } = Typography
// const inputGroupAndTitleHeight = 320

function UserProfile({ groups, user, onEdit }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    isError: isErrorBlock,
    isSuccess: isSuccessBlock,
    isLoading: isLoadingBlock,
    mutate: mutateBlock
  } = useMutateUserBlock()

  const {
    isError: isErrorUnblock,
    isSuccess: isSuccessUnblock,
    isLoading: isLoadingUnblock,
    mutate: mutateUnblock
  } = useMutateUserUnblock()

  useEffect(() => {
    if (isSuccessBlock) {
      message.success('Учетная запись деактивирована.')
    } else if (isErrorBlock) {
      message.error('Ошибка деактивации учетной записи.')
    }
    handleCloseEditModal()
  }, [isErrorBlock, isSuccessBlock])

  useEffect(() => {
    if (isSuccessUnblock) {
      message.success('Учетная запись активирована.')
    } else if (isErrorUnblock) {
      message.error('Ошибка активации учетной записи.')
    }
    handleCloseEditModal()
  }, [isErrorUnblock, isSuccessUnblock])

  const dateToString = d => (d ? moment(d).format('DD MMMM yyyy') : '')

  const handleOpenEditModal = () => setIsModalOpen(true)
  const handleCloseEditModal = () => setIsModalOpen(false)
  const handleDeactivate = () => mutateBlock(user.id)
  const handleActivate = () => mutateUnblock(user.id)

  // const subscriptionsText = useMemo(() => {
  //   if (!subscriptions?.length) {
  //     return 'Нет'
  //   }
  //   if (subscriptions?.find(item => item.topicId === ALL)) {
  //     return 'Все'
  //   }
  //   return subscriptions?.map(item => item.topicName)?.join(', ')
  // }, [subscriptions])

  if (!user?.id) return null
  return (
    <>
      <Row justify="space-between">
        <Col>
          <Title level={2} type="success">
            Профиль пользователя
          </Title>
        </Col>
        <Col>
          <Space className="mt-2">
            <Button onClick={onEdit} type="primary">
              Редактировать
            </Button>
            {user?.blocked ? (
              <Button onClick={handleOpenEditModal} type="success">
                Активировать
              </Button>
            ) : (
              <Button onClick={handleOpenEditModal} type="primary" danger>
                Деактивировать
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row>
          <Col span={6}>
            <Text type="secondary">ФИО:</Text>
          </Col>
          <Col span={6}>
            <Text>{user?.name}</Text>
          </Col>
          <Col span={6}>
            <Text type="secondary">Последнее изменение:</Text>
          </Col>
          <Col span={6}>
            <Text>{dateToString(user?.lastModifiedAt)}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Text type="secondary">Дата регистрации:</Text>
          </Col>
          <Col span={6}>
            <Text>{dateToString(user?.registrationDate)}</Text>
          </Col>
          <Col span={6}>
            <Text type="secondary">Пользователь:</Text>
          </Col>
          <Col span={6}>
            <Text>{user?.lastModifiedBy}</Text>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col span={6}>
            <Text type="secondary">Электронная почта:</Text>
          </Col>
          <Col span={6}>
            <Text>{user?.email}</Text>
          </Col>
          {/* <Col span={6}><Text type='secondary'>UUID:</Text></Col> */}
          {/* <Col span={6}><Text ellipsis>{user?.uuid}</Text></Col> */}
        </Row>
        <Row>
          <Col span={6}>
            <Text type="secondary">Телефон:</Text>
          </Col>
          <Col>
            <Text>{user?.mobilePhone}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Text type="secondary">Группа пользователей:</Text>
          </Col>
          <Col span={6}>
            <Text>{groups.find(e => e.id === user?.groupName)?.name}</Text>
          </Col>
        </Row>
        {/* <Row> */}
        {/*  <Col span={6}> */}
        {/*    <Text type='secondary'>Подписки:</Text> */}
        {/*  </Col> */}
        {/*  <Col span={12}> */}
        {/*    <Text ellipsis={{ tooltip: subscriptionsText }}>{subscriptionsText}</Text> */}
        {/*  </Col> */}
        {/* </Row> */}

        {/* <Title level={4} className='mt-3'>Область видимости пользователя</Title> */}
      </Space>
      {/* <CityTree */}
      {/*  height={(containerHeight - inputGroupAndTitleHeight) || 300} */}
      {/*  externalData={!AppConfig.disabledVisibilityScopeGroups.includes(user?.groupName) && user?.visibilityScope} */}
      {/* /> */}
      <Modal
        visible={isModalOpen}
        title={user?.blocked ? 'Активировать учетную запись' : 'Деактивировать учетную запись'}
        onCancel={handleCloseEditModal}
        okText="Ок"
        cancelText="Отменить"
        okButtonProps={{ className: 'green', loading: isLoadingBlock || isLoadingUnblock }}
        onOk={user?.blocked ? handleActivate : handleDeactivate}
      >
        <Text>
          Учетная запись <b>{user?.email}</b> будет{' '}
          {user?.blocked ? 'активирована' : 'деактивирована'}.
        </Text>
      </Modal>
    </>
  )
}

export default withRouter(observer(UserProfile))
