import React from 'react'
import { Form, Select } from 'antd'
import { profileScreen } from '../../../translates'
import { genderList } from '../../../helper'

const { Option } = Select

function GenderSelectFormItem({ onSelect, required = true }) {
  return (
    <Form.Item
      name={['personalData', profileScreen.input.gender.name]}
      label={profileScreen.input.gender.label}
      rules={[{ required, message: profileScreen.input.gender.message }]}
    >
      <Select size="large" placeholder={profileScreen.input.gender.placeholder} onSelect={onSelect}>
        {genderList.map(c => (
          <Option key={c.label} value={c.value}>
            {c.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default GenderSelectFormItem
