import { api, queryClient } from './api'
import { useQuery, useMutation } from 'react-query'

export const ACTS_KEY = 'acts'
export const TAGS_KEY = 'tags'
export const TEMPLATES_KEY = 'templates'

const fetchActCreate = data => {
  return api.put({ path: `${ACTS_KEY}/${TEMPLATES_KEY}`, data })
}
const fetchActTemplateTags = () => {
  return api.get({ path: `${ACTS_KEY}/${TEMPLATES_KEY}/${TAGS_KEY}` })
}
const fetchActTemplatesList = ({ queryKey }) => {
  const [, /* key */ pagination] = queryKey
  return api.post({ path: `${ACTS_KEY}/${TEMPLATES_KEY}/list`, data: pagination })
}
const fetchActByType = ({ queryKey }) => {
  const [, /* key */ type] = queryKey
  return api.get({ path: `${ACTS_KEY}/${TEMPLATES_KEY}/active?actType=` + type })
}
const fetchActById = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: `${ACTS_KEY}/${TEMPLATES_KEY}?id=` + id })
}

export function useGetActTemplatesListQuery(data, options) {
  return useQuery([ACTS_KEY, data], fetchActTemplatesList, options)
}
export function useActTemplateTagsQuery(options) {
  return useQuery([TAGS_KEY], fetchActTemplateTags, options)
}
export function useActByTypeQuery(type, options) {
  return useQuery([ACTS_KEY, type], fetchActByType, options)
}
export function useActByIdQuery(id, options) {
  return useQuery([ACTS_KEY, id], fetchActById, options)
}
export function useMutateActCreate() {
  return useMutation(fetchActCreate, {
    onSuccess: () => {
      queryClient.refetchQueries(ACTS_KEY, { active: true })
    }
  })
}
