import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Modal,
  Spin,
  message,
  List,
  Input,
  AutoComplete,
  Row,
  Typography,
  Col,
  Select,
  Form
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'

import { useActByTypeQuery } from '../../../../api/react-query/acts'
import {
  useMutateAdminPartnerAct,
  useMutateAdminPartnerActPreview
} from '../../../../api/react-query/adminPartners'
import { AppConfig } from '../../../../AppConfig'
import { ruCurrency } from '../../../../helper'
import { CreateActWithAssetsPreview } from './'
import { useUserSbisDepartments } from '../../../../api/react-query/users'
import AssetInventoryFlag from './AssetInventoryFlag'

const { Option } = Select
const { Title, Text } = Typography
const { confirm } = Modal
const { TextArea } = Input

function CreateActWithAssetsModal({
  partner,
  actType,
  confirmTitle = 'Создать',
  listTitle = 'Список имущества:',
  title,
  content = 'Акт',
  loading = false,
  visible = false,
  onClose = () => {},
  list
}) {
  const [lines, setLines] = useState([])
  const [act, setAct] = useState({})

  const [value, setValue] = useState('')
  const [preview, setPreview] = useState('')
  const [selectedId, setSelectedId] = useState('')
  const [options, setOptions] = useState([])
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null)
  const [selectedDepartmentStatus, setSelectedDepartmentStatus] = useState('error')
  const [departmentRequestError, setDepartmentRequestError] = useState(null)

  const handleSearchAsset = searchText => {
    setOptions(
      !searchText
        ? list.map(a => ({ value: a.name, id: a.id }))
        : list
            .filter(o => o.name?.toLowerCase()?.includes(searchText.toLowerCase()))
            .map(a => ({ value: a.name, id: a.id }))
    )
  }
  const handleChangeAsset = value => {
    if (!value) {
      setSelectedId('')
    }
    setValue(value)
  }
  const handleSelectAsset = (value, item) => setSelectedId(item.id)
  const handleAddToList = () => {
    setSelectedId('')
    setValue('')
    setLines([...lines, { ...list.find(a => a.id === selectedId), quantity: 1 }])
  }
  const handleRemoveItem = item => setLines([...lines.filter(line => line.id !== item.id)])
  const handleChangeQuantity = (event, item) => {
    setLines([
      ...lines.map(el => {
        if (el.id === item.id) {
          el.quantity = Math.max(event?.target?.value, 1)
        }
        return el
      })
    ])
  }
  const handleChangeSerialNumber = (event, item) => {
    setLines([
      ...lines.map(el => {
        if (el.id === item.id) {
          el.assetSerial = event?.target?.value
        }
        return el
      })
    ])
  }
  const handleChangeAssetFlags = (inventory, item) => {
    setLines([
      ...lines.map(el => {
        if (el.id === item.id) {
          el.inventoryKind = inventory
        }
        return el
      })
    ])
  }
  const handleChangeReason = (event, item) => {
    setLines([
      ...lines.map(el => {
        if (el.id === item.id) {
          el.reason = event?.target?.value
        }
        return el
      })
    ])
  }
  useEffect(() => {
    if (!visible) {
      setLines([])
      setSelectedId('')
      setValue('')
      setSelectedDepartmentId(null)
    }
  }, [visible])

  const isTransfer = useMemo(() => actType === AppConfig.assetActTypes.transfer, [actType])
  const isReturn = useMemo(() => actType === AppConfig.assetActTypes.return, [actType])

  const {
    data: dataPartnerAct,
    mutate: mutatePartnerAct,
    isError: isErrorPartnerAct,
    isLoading: isLoadingPartnerAct,
    isSuccess: isSuccessPartnerAct
  } = useMutateAdminPartnerAct()

  useEffect(() => {
    if (isErrorPartnerAct || dataPartnerAct?.errorMessage) {
      message.error(dataPartnerAct?.errorMessage || 'Ошибка создания акта.')
    } else if (isSuccessPartnerAct) {
      message.success('Акт успешно создан.')
      onClose()
    }
  }, [isErrorPartnerAct, isSuccessPartnerAct, dataPartnerAct])

  const {
    data: dataPartnerActPreview,
    mutate: mutatePartnerActPreview,
    isError: isErrorPartnerActPreview,
    isLoading: isLoadingPartnerActPreview,
    isSuccess: isSuccessPartnerActPreview
  } = useMutateAdminPartnerActPreview()

  useEffect(() => {
    if (isErrorPartnerActPreview || dataPartnerActPreview?.errorMessage) {
      message.error(dataPartnerActPreview?.errorMessage || 'Ошибка создания акта.')
    } else if (isSuccessPartnerActPreview) {
      setPreview(dataPartnerActPreview.text)
    }
  }, [isErrorPartnerActPreview, isSuccessPartnerActPreview, dataPartnerActPreview])

  const {
    data: dataSbisDepartments,
    isLoading: isLoadingSbisDepartments,
    isError: isErrorSbisDepartmnets,
    isSuccess: isSuccessSbisDepartments
  } = useUserSbisDepartments(actType, {
    enabled: Boolean(visible && (isReturn || isTransfer)),
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: false
  })

  useEffect(() => {
    if ((isReturn || isTransfer) && isSuccessSbisDepartments && dataSbisDepartments?.isSuccess) {
      if (dataSbisDepartments?.items?.length === 1) {
        setSelectedDepartmentId(dataSbisDepartments?.items[0].id)
        setSelectedDepartmentStatus(null)
      }
      setDepartmentRequestError(!dataSbisDepartments?.items?.length)
    } else if (
      (isReturn || isTransfer) &&
      (isErrorSbisDepartmnets || dataSbisDepartments?.errorMessage)
    ) {
      message.error(dataSbisDepartments?.errorMessage || 'Ошибка получения точек партнера')
      setDepartmentRequestError(true)
      setSelectedDepartmentStatus(null)
    }
  }, [
    dataSbisDepartments,
    isErrorSbisDepartmnets,
    isSuccessSbisDepartments,
    isReturn,
    isTransfer,
    visible
  ])

  const handleCreatePreview = () => {
    if ((isReturn || isTransfer) && dataSbisDepartments?.items?.length && !selectedDepartmentId) {
      setSelectedDepartmentStatus('error')
      return
    }
    const data = {
      actType,
      lines: lines.map(line => ({
        assetId: line.id,
        reason: line.reason,
        quantity: Number(line.quantity),
        assetSerial: line.assetSerial
      })),
      templateId: act.id
    }
    mutatePartnerActPreview({ data, id: partner.id })
  }

  const handleRemovePreview = () => setPreview('')

  const handleSavePreview = () => {
    const data = {
      actType,
      lines: lines.map(line => ({
        assetId: line.id,
        reason: line.reason,
        quantity: Number(line.quantity),
        assetSerial: line.assetSerial,
        ...((isReturn || isTransfer) && { inventoryKind: line.inventoryKind })
      })),
      templateId: act.id,
      ...((isReturn || isTransfer) && { departmentId: selectedDepartmentId })
    }
    confirm({
      title: confirmTitle,
      content: content,
      okText: 'Да',
      cancelText: 'Нет',
      onOk: () => {
        mutatePartnerAct({ data, id: partner.id })
        setPreview('')
      },
      onCancel: () => {
        setPreview('')
      }
    })
  }

  useEffect(() => {
    if (list) {
      setOptions(list?.map(a => ({ value: a.name, id: a.id })))
    }
  }, [list])

  const {
    data: actByTypeData,
    isError: isErrorActByType,
    isLoading: isLoadingActByType,
    isSuccess: isSuccessActByType
  } = useActByTypeQuery(actType, { enabled: !!visible })

  useEffect(() => {
    if (isSuccessActByType) {
      setAct(actByTypeData?.template)
    } else if (isErrorActByType) {
      console.error(actByTypeData)
      message.error('Ошибка получения акта')
    }
  }, [isErrorActByType, isSuccessActByType, actByTypeData])

  const handleChangeDepartment = value => {
    setSelectedDepartmentStatus(value || departmentRequestError ? null : 'error')
    setSelectedDepartmentId(value)
  }

  return (
    <>
      <Modal
        width={1000}
        title={title}
        visible={visible}
        onOk={handleCreatePreview}
        okButtonProps={{ disabled: !lines?.length }}
        onCancel={onClose}
        confirmLoading={isLoadingPartnerActPreview || isLoadingPartnerAct}
        className="create-act-with-assets-modal"
        destroyOnClose
      >
        <Spin spinning={loading || isLoadingActByType || isLoadingSbisDepartments}>
          <Row gutter={[16, 8]}>
            <Col span={13}>
              <Text className="title">{content}</Text>
              <Text className="sub-title">
                Редакция акта от {moment(act?.createdAt).format(AppConfig.formats.shortDate)}
              </Text>
              <Row gutter={[16, 8]}>
                <Col>
                  <AutoComplete
                    options={options.filter(o => !lines.some(line => line.id === o.id))}
                    value={value}
                    style={{ width: 300 }}
                    allowClear
                    onSearch={handleSearchAsset}
                    placeholder="Выберите имущество"
                    className="item"
                    onSelect={handleSelectAsset}
                    onChange={handleChangeAsset}
                  />
                </Col>
                <Col>
                  <Button type="primary" onClick={handleAddToList} disabled={!selectedId}>
                    Добавить в список
                  </Button>
                </Col>
              </Row>
            </Col>
            {(isReturn || isTransfer) && (
              <Col span={11}>
                <Form layout="vertical">
                  <Form.Item
                    label="Выберите магазин"
                    validateStatus={selectedDepartmentStatus}
                    help={
                      selectedDepartmentStatus &&
                      'Выберите магазин, чтобы документ был проведен в СБИС.'
                    }
                  >
                    <Select
                      placeholder="Выберите магазин"
                      allowClear
                      defaultValue={selectedDepartmentId}
                      onChange={handleChangeDepartment}
                      value={selectedDepartmentId}
                    >
                      {dataSbisDepartments?.items?.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {departmentRequestError && (
                    <Text type="danger">
                      Документ не будет проведен в СБИС. За вами нет закрепленного магазина.
                    </Text>
                  )}
                </Form>
              </Col>
            )}
          </Row>
          <Title className="mt-3" level={5}>
            {listTitle}
          </Title>
          {lines.length > 0 ? (
            <>
              <List
                bordered
                className="list"
                itemLayout="horizontal"
                dataSource={lines}
                header={
                  <Row>
                    <Col span={isTransfer ? 9 : 5}>Название</Col>
                    <Col span={4}>Серийный номер</Col>
                    {!isTransfer && <Col span={5}>Комментарий</Col>}
                    <Col span={3}>Цена</Col>
                    <Col span={isTransfer ? 4 : 3}>Количество</Col>
                    <Col span={3}>Стоимость</Col>
                    <Col span={1} />
                  </Row>
                }
                renderItem={item => {
                  const listItem = list.find(a => a.id === item.id)
                  return (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <>
                            {item.title || item.name}
                            {(isReturn || isTransfer) && (
                              <AssetInventoryFlag
                                item={
                                  item?.assetInventory
                                    ? item
                                    : { ...item, assetInventory: item?.inventory }
                                }
                                onSuccess={handleChangeAssetFlags}
                                isReturnActType={isReturn}
                              />
                            )}
                          </>
                        }
                      />
                      <Col span={4}>
                        {(listItem?.hasSerial || listItem?.assetSerial) && (
                          <Input
                            disabled={listItem?.assetSerial?.length > 0 && !isTransfer}
                            size="small"
                            value={item.assetSerial}
                            onChange={e => handleChangeSerialNumber(e, item)}
                          />
                        )}
                      </Col>
                      {!isTransfer && (
                        <Col span={5}>
                          <TextArea
                            value={item.reason}
                            rows={1}
                            onChange={e => handleChangeReason(e, item)}
                          />
                        </Col>
                      )}

                      <Col span={3}>
                        {item.price?.toFixed(2)}
                        <small>
                          <Text>{ruCurrency}</Text>
                        </small>
                      </Col>
                      <Col span={isTransfer ? 4 : 3}>
                        <Input
                          disabled={listItem?.hasSerial || listItem?.assetSerial}
                          min={1}
                          max={item.existQuantity}
                          type="number"
                          size="small"
                          value={item.quantity}
                          onChange={e => handleChangeQuantity(e, item)}
                        />
                      </Col>
                      <Col span={3}>
                        {(item.price * item.quantity)?.toFixed(2)}
                        <small>
                          <Text type="secondary">{ruCurrency}</Text>
                        </small>
                      </Col>
                      <Col span={1}>
                        <a key="list-remove" onClick={() => handleRemoveItem(item)}>
                          <DeleteOutlined />
                        </a>
                      </Col>
                    </List.Item>
                  )
                }}
              />
              <Row className="mt-3 mr-3" justify="end">
                <Col>
                  Итого:{' '}
                  <b>
                    {lines.reduce((prev, cur) => prev + cur.price * cur.quantity, 0)?.toFixed(2)}
                  </b>
                  <small>
                    <Text type="secondary">{ruCurrency}</Text>
                  </small>
                </Col>
              </Row>
            </>
          ) : (
            <Text>Ничего не выбрано</Text>
          )}
        </Spin>
      </Modal>
      {preview && (
        <CreateActWithAssetsPreview
          preview={preview}
          onClose={handleRemovePreview}
          onSave={handleSavePreview}
          title={content}
        />
      )}
    </>
  )
}

export default CreateActWithAssetsModal
