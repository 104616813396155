import { Form, Select } from 'antd'
import React from 'react'
import { profileScreen } from '../../../translates'
import { useChains } from '../../../hooks'

const { Option } = Select

const StoreChainFormItem = ({
  city = '',
  label = profileScreen.input.storeChain.label,
  rules = undefined,
  requiredRule = true,
  messageRule = profileScreen.input.storeChain.message,
  size = 'large',
  disabled = false,
  placeholder = profileScreen.input.storeChain.placeholder,
  onChange = () => {},
  withOnDemandStores = false,
  hidden = false,
  disabledQuery = false
}) => {
  const { data: chains, isLoading } = useChains(
    {
      city,
      ...(withOnDemandStores && { withOnDemandStores })
    },
    {
      enabled: !!city && !disabledQuery
    }
  )
  return (
    <Form.Item
      name={['personalData', 'storeChain']}
      label={label}
      rules={rules ?? [{ required: requiredRule, message: messageRule }]}
      hidden={hidden}
    >
      <Select
        size={size}
        disabled={disabled}
        showSearch
        placeholder={placeholder}
        onChange={onChange}
        loading={isLoading}
      >
        {!isLoading &&
          chains.map(c => (
            <Option key={c.id} value={c.name}>
              {c.name}
            </Option>
          ))}
      </Select>
    </Form.Item>
  )
}
export default StoreChainFormItem
