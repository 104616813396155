import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import {
  message,
  Table,
  Spin,
  Button,
  Space,
  ConfigProvider,
  Typography,
  Row,
  Col,
  Tag
} from 'antd'
import ruRu from 'antd/es/locale/ru_RU'
import moment from 'moment-timezone'

import { simpleReducer } from '../../../helper'
import { breadcrumbSeparator } from '../../../Constants'
import NewAsset from './NewAsset'
import { useGetAssetsListQuery } from '../../../api/react-query/assets'
import { AppConfig } from '../../../AppConfig'
import getColumnSearchProps from '../../../components/SearchFilter/SearchFilter'
import AssetActions from './AssetActions'
import { useAssetStatusesQuery } from '../../../api/react-query/dict'

const { Text } = Typography
const initState = {
  assetsList: {
    result: [],
    pageSize: 20,
    pageNumber: 1,
    resultsTotal: 0,
    isSuccess: true,
    errorMessage: '',
    ...JSON.parse(localStorage.getItem('assetsRequest') || '{}')
  },
  groups: [],
  assetStatuses: [],
  request: {},
  activeCollapse: []
}

function Assets() {
  const [state, setState] = useReducer(simpleReducer, initState)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const handleOpenNewModal = () => setIsModalOpen(true)
  const handleCloseNewModal = () => setIsModalOpen(false)

  const {
    data: assetsData,
    isError: isErrorAssets,
    isSuccess: isSuccessAssets
  } = useAssetStatusesQuery({})

  useEffect(() => {
    if (isSuccessAssets) {
      setState({
        assetStatuses: assetsData?.result
      })
    } else if (isErrorAssets) {
      console.error(assetsData)
      message.error('Ошибка получения списка типов имущества')
    }
  }, [assetsData, isErrorAssets, isSuccessAssets])

  const { data, isError, isSuccess, isLoading } = useGetAssetsListQuery({
    // pagination
    pageSize: state.assetsList.pageSize,
    pageNumber: state.assetsList.pageNumber,
    // sorting
    sortBy: state.assetsList.orderBy,
    sortDir: state.assetsList.orderDir,
    // filtering
    status: state.assetsList.filter?.status,
    name: state.assetsList.filter?.name
  })

  useEffect(() => {
    if (isSuccess) {
      setState({
        assetsList: {
          ...JSON.parse(localStorage.getItem('assetsRequest') || '{}'),
          ...data
        }
      })
    } else if (isError) {
      console.error(data)
      message.error('Ошибка получения списка имущества')
    }
  }, [isSuccess, isError, data])

  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      ...getColumnSearchProps('name', 'по наименованию'),
      sorter: true,
      sortOrder: state.assetsList?.orderBy === 'name' ? state.assetsList?.orderDir + 'end' : false,
      filteredValue: state.assetsList?.filter?.name ? [state.assetsList?.filter?.name] : null
    },
    {
      title: 'Флаги',
      dataIndex: 'flags',
      render: (value, record) =>
        [
          ...(record.isSuit
            ? [
                <Tag key="suit" color="green">
                  Форма
                </Tag>
              ]
            : []),
          ...(record.hasSerial
            ? [
                <Tag key="hasSerial" color="blue">
                  С номером
                </Tag>
              ]
            : [])
        ].map(tag => tag)
    },
    {
      title: AppConfig.inventoryTypeLabels.new,
      dataIndex: ['inventory', AppConfig.inventoryTypes.new],
      width: 60
    },
    {
      title: AppConfig.inventoryTypeLabels.used,
      dataIndex: ['inventory', AppConfig.inventoryTypes.used],
      render: (value, record) =>
        record?.inventory?.usedName ? (
          <>
            {record?.inventory?.usedName}
            <br />
            {value}
          </>
        ) : (
          value
        )
    },
    {
      title: AppConfig.inventoryTypeLabels.disposal,
      dataIndex: ['inventory', AppConfig.inventoryTypes.disposal],
      render: (value, record) =>
        record?.inventory?.disposalName ? (
          <>
            {record?.inventory?.disposalName}
            <br />
            {value}
          </>
        ) : (
          value
        )
    },
    {
      title: 'Стоимость',
      dataIndex: 'price',
      width: 70,
      sorter: true,
      sortOrder: state.assetsList?.orderBy === 'price' ? state.assetsList?.orderDir + 'end' : false,
      filteredValue: state.assetsList?.filter?.price ? [state.assetsList?.filter?.price] : null
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      // width: 50
      render: key => {
        const status = state.assetStatuses.find(status => status.id === key)
        return <Text type={AppConfig.assetStatusColors[status?.id]}>{status?.name}</Text>
      },
      filters: state.assetStatuses.map(status => ({ text: status.name, value: status.id })),
      filteredValue: state.assetsList?.filter?.status || null
    },
    {
      title: 'Дата создания',
      // width: 100,
      dataIndex: 'createdAt',
      render: date => (date ? moment(date).format(AppConfig.formats.shortDate) : ''),
      sorter: true,
      sortOrder:
        state.assetsList?.orderBy === 'createdAt' ? state.assetsList?.orderDir + 'end' : false
    }
  ]

  const pagination = useMemo(
    () => ({
      current: state.assetsList.pageNumber,
      total: state.assetsList.resultsTotal,
      pageSize: state.assetsList.pageSize
    }),
    [state.assetsList.pageNumber, state.assetsList.resultsTotal, state.assetsList.pageSize]
  )

  const handleTableChange = useCallback((pagination, filters, sorter) => {
    const filterArrType = ['status']
    let orderDir
    if (sorter?.order) {
      orderDir = sorter.order === 'ascend' ? 'asc' : 'desc'
    }
    const assetsList = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      filter: Object.entries(filters || {}).reduce(
        (r, c) => ({
          ...r,
          [c[0]]: c[1] ? (filterArrType.includes(c[0]) ? c[1] : c[1][0]) : undefined
        }),
        {}
      ),
      orderBy: sorter.field,
      orderDir
    }
    setState({
      assetsList
    })
    localStorage.setItem('assetsRequest', JSON.stringify(assetsList))
  }, [])

  const rowSelectionOnChange = useCallback(
    selectedRowKeys => setSelectedRowKeys(selectedRowKeys),
    []
  )

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedRowKeys,
      onChange: rowSelectionOnChange
    }),
    [selectedRowKeys, rowSelectionOnChange]
  )

  return (
    <div className="Assets">
      <Spin spinning={isLoading} size="large">
        <Space style={{ marginBottom: 16 }}>
          <Button onClick={handleOpenNewModal}>Добавить имущество</Button>
        </Space>
        <Row className="mb-3 mt-3" justify="space-between" align="middle">
          <Col className="mt-3">
            <Text className="font-size-20">Все имущество</Text>
            {selectedRowKeys?.length > 0 && (
              <>
                {breadcrumbSeparator}
                <Text className="font-size-20">Выбрано ({selectedRowKeys.length})</Text>
                <AssetActions assets={selectedRowKeys} />
              </>
            )}
          </Col>
        </Row>
        <ConfigProvider locale={ruRu}>
          <Table
            columns={columns}
            loading={isLoading}
            pagination={pagination}
            rowKey="id"
            dataSource={state.assetsList?.result}
            onChange={handleTableChange}
            rowSelection={rowSelection}
          />
        </ConfigProvider>
      </Spin>

      {isModalOpen && <NewAsset onClose={handleCloseNewModal} visible={isModalOpen} />}
    </div>
  )
}

export default Assets
