import React, { useEffect, useState } from 'react'
import { Typography, Input, Button, Form, message, Modal, AutoComplete } from 'antd'
import {
  useMutateUserOpenStore,
  useMutateUserCloseStore,
  useMutateCreateOrUpdateOrgElement
} from '../../../api/react-query/org'

const { Title, Text } = Typography
const { confirm } = Modal

export default function EditStoreManagement({
  title,
  newEntity,
  editEntity,
  city,
  chain,
  store,
  onClose,
  chains,
  cities
}) {
  const [chainOptions, setChainOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    isError: isErrorOpenStore,
    isSuccess: isSuccessOpenStore,
    isLoading: isLoadingOpenStore,
    mutate: mutateOpenStore
  } = useMutateUserOpenStore()

  const {
    isError: isErrorCloseStore,
    isSuccess: isSuccessCloseStore,
    isLoading: isLoadingCloseStore,
    mutate: mutateCloseStore
  } = useMutateUserCloseStore()

  useEffect(() => {
    if (isSuccessOpenStore) {
      message.success('Магазин открыт.')
    } else if (isErrorOpenStore) {
      message.error('Ошибка открытия магазина.')
    }
    handleCloseEditModal()
  }, [isErrorOpenStore, isSuccessOpenStore])

  useEffect(() => {
    if (isSuccessCloseStore) {
      message.success('Магазин закрыт.')
    } else if (isErrorCloseStore) {
      message.error('Ошибка закрытия магазина.')
    }
    handleCloseEditModal()
  }, [isErrorCloseStore, isSuccessCloseStore])

  const {
    isLoading: isLoadingCreateOrUpdateOrgElement,
    mutateAsync: mutateCreateOrUpdateOrgElement
  } = useMutateCreateOrUpdateOrgElement()

  const onSearch = searchText => {
    setChainOptions(
      !searchText
        ? []
        : chains
            .filter(chain => chain.name?.toLowerCase()?.includes(searchText.toLowerCase()))
            ?.map(e => ({ value: e.name }))
    )
  }

  const onCitySearch = searchText => {
    setCityOptions(
      !searchText
        ? []
        : cities
            .filter(city => city.name?.toLowerCase()?.includes(searchText.toLowerCase()))
            ?.map(e => ({ value: e.name }))
    )
  }

  const setNewChainModal = async ({ chain, store }) => {
    confirm({
      title: 'Добавить новую сеть',
      content: (
        <div>
          Вы собираетесь создать новую сеть <b>{chain?.body?.name}</b> с магазином{' '}
          <b>{store?.body?.name}</b>
        </div>
      ),
      onOk: async () => {
        if (store.body.chainId) {
          return createRecord(store)
        }
        const newChain = await createRecord(chain)
        store.body.chainId = newChain?.chain?.id
        createRecord(store)
      }
    })
  }

  const handleFinish = values => {
    const data = {}
    const existChain = chains.find(chain => chain.name === values.chain)
    const successCreateText = ' успешно добавлен'
    const successEditText = ' успешно изменен'
    switch (true) {
      // CITY
      case newEntity === 'city':
        data.name = 'city'
        data.messageText = 'Город' + successCreateText
        data.body = { name: values.city }
        data.method = 'put'
        break
      case editEntity === 'city':
        data.name = 'city'
        data.messageText = 'Город' + successEditText
        data.body = { id: city.id, name: values.city }
        data.method = 'patch'
        break
      // CHAIN
      case newEntity === 'chain':
        data.chain = {
          name: 'chain',
          messageText: 'Сеть' + successCreateText + 'а',
          body: { name: values.chain },
          method: 'put',
          isCloseModal: false
        }
        data.store = {
          name: 'store',
          messageText: 'Магазин' + successCreateText,
          body: { name: values.store, cityId: city.id, chainId: existChain?.id },
          method: 'put'
        }
        return setNewChainModal(data)
      case editEntity === 'chain':
        data.name = 'chain'
        data.messageText = 'Сеть' + successEditText + 'а'
        data.body = { id: chain.id, name: values.chain }
        data.method = 'patch'
        break
      // STORE
      case newEntity === 'store':
        data.name = 'store'
        data.messageText = 'Магазин' + successCreateText
        data.body = { name: values.store, cityId: city.id, chainId: chain.id }
        data.method = 'put'
        break
      case editEntity === 'store':
        data.name = 'store'
        data.messageText = 'Магазин' + successEditText
        data.body = { id: store.id, name: values.store }
        data.method = 'patch'
        break
      default:
        return
    }
    createRecord(data)
  }

  const createRecord = async ({ name, body, messageText, method, isCloseModal = true }) => {
    try {
      const result = await mutateCreateOrUpdateOrgElement({ name, body, method })
      if (result?.isSuccess) {
        message.success(messageText)
        // или закрываем модалку или возвращаем значение промиса чтобы работать с ним дальше
        if (isCloseModal) {
          onClose()
        } else {
          return result
        }
      } else {
        message.error(result?.errorMessage)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleOpenEditModal = () => setIsModalOpen(true)
  const handleCloseEditModal = () => setIsModalOpen(false)
  const handleCloseStore = () => mutateCloseStore(store.id)
  const handleOpenStore = () => mutateOpenStore(store.id)

  return (
    <Form
      onFinish={handleFinish}
      initialValues={{
        city: city?.name,
        chain: chain?.name,
        store: store?.name
      }}
    >
      <Title type="success">{title}</Title>
      {city && newEntity !== 'city' && editEntity !== 'city' && (
        <div className="item">
          Город: <b>{city.name}</b>
        </div>
      )}
      {chain && newEntity !== 'chain' && editEntity !== 'chain' && (
        <div className="item">
          Сеть: <b>{chain.name}</b>
        </div>
      )}

      {(newEntity === 'city' || editEntity === 'city') && (
        <Form.Item
          label="Город"
          name="city"
          rules={[{ required: true, message: 'Пожалуйста, введите название города' }]}
        >
          <AutoComplete
            options={cityOptions}
            onSearch={onCitySearch}
            placeholder="Введите название города"
            className="item"
          />
        </Form.Item>
      )}

      {(newEntity === 'chain' || editEntity === 'chain') && (
        <Form.Item
          label="Сеть"
          name="chain"
          rules={[{ required: true, message: 'Пожалуйста, введите название сети' }]}
          className={newEntity === 'chain' && 'without-margin'}
        >
          <AutoComplete
            options={chainOptions}
            onSearch={onSearch}
            placeholder="Введите название сети"
            className="item"
          />
        </Form.Item>
      )}

      {(newEntity === 'store' || editEntity === 'store' || newEntity === 'chain') && (
        <Form.Item
          label="Магазин"
          name="store"
          rules={[{ required: true, message: 'Пожалуйста, введите название магазина' }]}
        >
          <Input className="item" placeholder="Введите название магазина" />
        </Form.Item>
      )}
      {editEntity === 'store' && (
        <div className="item">
          Быстрая доставка: <b>{store.onDemand ? 'ДА' : 'НЕТ'}</b>
        </div>
      )}
      <Button type="primary" htmlType="submit" loading={isLoadingCreateOrUpdateOrgElement}>
        {newEntity ? 'Создать' : 'Редактировать'}
      </Button>
      {editEntity === 'store' && (
        <>
          <Button
            onClick={handleOpenEditModal}
            type="primary"
            className={`mt-3 ml-3 ${store?.isClosed && 'ant-btn-success'}`}
            danger={!store?.isClosed}
          >
            {store?.isClosed ? 'Открыть магазин' : 'Закрыть магазин'}
          </Button>
          <Modal
            visible={isModalOpen}
            title={store?.isClosed ? 'Открыть магазин' : 'Закрыть магазин'}
            onCancel={handleCloseEditModal}
            okText="Ок"
            cancelText="Отменить"
            okButtonProps={{
              className: 'green',
              loading: isLoadingOpenStore || isLoadingCloseStore
            }}
            onOk={store?.isClosed ? handleOpenStore : handleCloseStore}
          >
            <Text>
              Магазин <b>{store?.name}</b> будет {store?.isClosed ? 'открыт' : 'закрыт'}.
            </Text>
          </Modal>
        </>
      )}
    </Form>
  )
}
