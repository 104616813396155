// import { shopperLink } from '../env.json'
import envJson from '../env.json'
import React from 'react'

export const successScreen = {
  prefixTitle: 'Благодарим за сотрудничество!',
  title:
    'Ваша заявка отправлена на проверку. По результатам проверки на ваш номер телефона придёт уведомление.',
  payoutSuspendedTitle:
    'Ваша учетная запись заблокирована. Пожалуйста, обратитесь к вашему супервайзеру.',
  bl: 'Ваша учетная запись заблокирована. Пожалуйста, обратитесь к вашему супервайзеру.',
  correction: 'Ваша заявка отправлена на проверку.',
  correction1: 'Ваша заявка отправлена на проверку. Установите на свой телефон приложение',
  correction2:
    'и зарегистрируйтесь по указанному вами в анкете номеру телефона. Ожидайте окончания проверки документов.',
  loginNotFound: 'При верификации учетной записи возникла ошибка.',
  loginNotFound1:
    'Ваша учетная запись в приложении Шоппер не найдена. Установите на свой телефон приложение',
  loginNotFound2:
    'зарегистрируйтесь по указанному вами в анкете номеру телефона, и нажмите кнопку ниже.',
  loginIssued:
    'Ваша учетная запись в приложении "Шоппер" верифицирована. Приступайте к оказанию услуг',
  loginIssued1: 'Вы можете приступать к оказанию услуг! Установите приложение ',
  loginIssued2: 'и запланируйте первую смену',
  verified: 'Проверка завершена. Ожидайте верификации аккаунта.',
  verified1: 'Установите на свой телефон приложение',
  verified2: 'и зарегистрируйтесь по указанному вами в анкете номеру телефона.',
  shopperLink: (
    <a href={envJson.shopperLink} title={envJson.shopperLink} data-renderer-mark="true">
      `&quot;Шоппер`&quot;
    </a>
  ),
  button: {
    register: 'Я зарегистрировался',
    myContract: 'Мой договор'
  },
  error: {
    message: 'Что-то пошло не так.'
  }
}
