import React, { useEffect, useMemo, useReducer, useRef, useContext } from 'react'

import './PhotoViewer.css'
import { Button, Carousel } from 'antd'
import { UNKNOWN_DOC } from '../../../../Constants'
import { profileScreen } from '../../../../translates'
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MinusOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { makeFileUrl, simpleReducer, dragElement } from '../../../../helper'
import { AppConfig } from '../../../../AppConfig'
import PdfViewer from './PdfViewer'
import Approval from '../../Approval/Approval'
import { ConfigContext } from '../../../../context/configContext'

const iconStyle = { fontSize: '40px' }
const buttonStyle = { height: '64px' }
const idImgDoc = 'idImgDoc'

const initialState = {
  scale: 1
}

function PhotoViewer({
  componentName = null,
  documents,
  profile,
  onCommitDocument = () => {},
  loading = false,
  disableApproval = false
}) {
  const [state, setState] = useReducer(simpleReducer, initialState)
  const slider = useRef(null)

  const { isAdmin, isCoordinators, isCoordinator, isPartnerSupport, isExternalCoordinator } =
    useContext(ConfigContext)

  const componentPrefix = useMemo(() => componentName ?? '', [componentName])

  const handleClickMinus = () => {
    setState({ scale: state.scale - 1 > 0 ? state.scale - 1 : state.scale })
  }
  const handleClickPlus = () => {
    setState({ scale: state.scale + 1 })
  }

  const handleClickPrev = () => {
    setState(initialState)
    slider?.current?.prev()
  }
  const handleClickNext = () => {
    setState(initialState)
    slider?.current?.next()
  }

  useEffect(() => {
    documents
      .filter(d => profile?.[d]?.fileId)
      .forEach(d => {
        const list = document.getElementsByClassName(`${componentPrefix}${idImgDoc}${d}`)
        Array.prototype.forEach.call(list, imageDoc => {
          if (imageDoc) {
            dragElement(imageDoc)
          }
        })
      })
  }, [])

  const handleCommit = (d, o, c) => onCommitDocument(d, o, c, () => slider?.current?.next())

  return (
    <div className="photo-viewer">
      <Carousel ref={slider} dots={null}>
        {documents
          .filter(d => profile?.[d]?.fileId)
          .map(d => (
            <div key={d}>
              <h2>{profileScreen.input[d]?.label ?? UNKNOWN_DOC}</h2>
              <div className="toolbar">
                <Button icon={<MinusOutlined />} className="mr-3 ml-3" onClick={handleClickMinus} />
                <Button icon={<PlusOutlined />} onClick={handleClickPlus} />
              </div>
              <div className="image-wrap">
                {profile?.[d]?.fileType === AppConfig.uploadTypes.pdf ? (
                  <PdfViewer fileId={profile?.[d]?.fileId} zoom={state.scale} />
                ) : (
                  <img
                    className={`${componentPrefix}${idImgDoc}${d}`}
                    src={makeFileUrl(profile?.[d]?.fileId)}
                    style={{ transform: `scale(${state.scale})` }}
                  />
                )}
              </div>
              <div>
                <Button style={buttonStyle} onClick={handleClickPrev} type="link">
                  <ArrowLeftOutlined style={iconStyle} />
                </Button>
                <Button style={buttonStyle} onClick={handleClickNext} type="link">
                  <ArrowRightOutlined style={iconStyle} />
                </Button>
              </div>
              {profile?.[d]?.allowReview && !disableApproval && (
                <div style={{ paddingRight: '0.5rem' }}>
                  <Approval
                    defaultOutcome={profile?.[d]?.status}
                    defaultComment={profile?.[d]?.statusReason}
                    loading={loading}
                    onCommit={({ outcome, comment }) => handleCommit(d, outcome, comment)}
                    targetType={d}
                    autocomplete={Boolean(
                      isAdmin ||
                        isCoordinators ||
                        isCoordinator ||
                        isPartnerSupport ||
                        isExternalCoordinator
                    )}
                  />
                </div>
              )}
            </div>
          ))}
      </Carousel>
    </div>
  )
}

export default PhotoViewer
