import { api } from './api'
import { useMutation } from 'react-query'

const LOG_KEY = 'log'

const fetchLog = data => {
  return api.post({ path: `${LOG_KEY}`, data })
}

export function useMutateLog() {
  return useMutation(fetchLog)
}
