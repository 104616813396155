import { Form, message, Select } from 'antd'
import React, { useEffect, useState, useMemo } from 'react'

import { AppConfig } from '../../../AppConfig'
import { useRefCitizenshipQuery } from '../../../api/react-query/dict'
import { twoMinutes } from '../../../Constants'
import SberIcon from '../SberIcon'
import TinkoffIcon from '../TinkoffIcon'

const { Option } = Select

const CitizenshipFormItem = ({
  label = 'Гражданство',
  rules = undefined,
  requiredRule = true,
  messageRule = 'Пожалуйста, выберите гражданство',
  size = 'large',
  placeholder = 'Россия',
  disabled = false,
  showSberIcon = false,
  showTinkoffIcon = false
}) => {
  const [citizenships, setCitizenships] = useState(
    Object.values(AppConfig.countries).filter(country => country === AppConfig.countries.Russia)
  )
  const {
    data: citizenshipsData,
    isError: isErrorCitizenships,
    isSuccess: isSuccessCitizenships
  } = useRefCitizenshipQuery({
    staleTime: twoMinutes
  })
  useEffect(() => {
    if (isSuccessCitizenships) {
      setCitizenships(
        [...(citizenshipsData?.result || [])].filter(
          country => country === AppConfig.countries.Russia
        )
      )
    }
  }, [isSuccessCitizenships, citizenshipsData])

  useEffect(() => {
    if (isErrorCitizenships) {
      message.error('Ошибка доступа к данным')
    }
  }, [isErrorCitizenships])

  const labelParam = useMemo(() => {
    return showSberIcon || showTinkoffIcon ? (
      <>
        {label}
        {showSberIcon && <SberIcon style={{ marginLeft: '0.5rem' }} />}
        {showTinkoffIcon && <TinkoffIcon style={{ marginLeft: '0.5rem' }} />}
      </>
    ) : (
      label
    )
  }, [label, showSberIcon, showTinkoffIcon])

  return (
    <Form.Item
      name={['personalData', 'citizenship']}
      label={labelParam}
      rules={rules ?? [{ required: requiredRule, message: messageRule }]}
    >
      <Select size={size} placeholder={placeholder} disabled={disabled}>
        {citizenships.map(c => (
          <Option key={c} value={c}>
            {c}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}
export default CitizenshipFormItem
