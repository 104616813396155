import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Avatar, Button, Space, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import env from '../../../env.json'
import { ADMIN_AUTH_PAGE } from '../../../routes'
import { ConfigContext } from '../../../context/configContext'
import { queryClient } from '../../../api/react-query/api'
import { googleLogout } from '@react-oauth/google'

const { Text } = Typography

const google = 'google'
export const googleTokenName = 'googleToken'
const adfs = 'adal'
const adfsKey = 'adal.access.token.key'

function AdminLogin() {
  const [name, setName] = useState('')
  const [avatar, setAvatar] = useState('')
  const [authType, setAuthType] = useState('')

  const history = useHistory()
  const onInit = googleData => {
    if (googleData) {
      setAuthType(google)
      setName(googleData.name)
      setAvatar(googleData.picture)
    } else if (localStorage.getItem(adfsKey)) {
      setAuthType(adfs)
      setName('Sbermarket User')
      setAvatar('')
    } else {
      onError('Пользователь не авторизован')
    }
  }
  const onError = err => {
    console.log('error', err)
    history.push(ADMIN_AUTH_PAGE)
  }
  useEffect(() => {
    if (env.envName === 'dev') {
      const getGoogleUser = async () => {
        const userInfo = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${localStorage.getItem(googleTokenName)}` }
        })
        const response = await userInfo.json()
        onInit(response)
      }

      if (localStorage.getItem(googleTokenName)) {
        getGoogleUser()
      }
    } else {
      onInit()
    }
  }, [])

  const onLogout = cb => {
    console.log('User signed out.')
    queryClient.clear()
    cb?.()
  }

  const handleSignOut = cb => {
    if (authType === google) {
      googleLogout()
      onLogout(cb)
    } else {
      for (const key in localStorage) {
        if (key.includes(adfs)) {
          localStorage.removeItem(key)
        }
      }
      onLogout(cb)
    }
  }

  return (
    <Space>
      <Avatar size={40} src={avatar} icon={avatar ? null : <UserOutlined />} />
      <ConfigContext.Consumer>
        {({ user }) => <Text>{user?.name || name}</Text>}
      </ConfigContext.Consumer>
      <Button
        onClick={() =>
          handleSignOut(() => {
            fetch(`${env.apiEndpoint}admin/auth/logout`, { method: 'POST' }).finally(() =>
              history.push(ADMIN_AUTH_PAGE)
            )
          })
        }
      >
        Выйти
      </Button>
    </Space>
  )
}

export default AdminLogin
