import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Form, message, Modal, Row, Select, Spin } from 'antd'
import {
  usePartnerCardReissueClaimsQuery,
  usePartnerCardReissueReasonsQuery
} from '../../../../api/react-query/dict'
import { useMutateReissueCardAdmin } from '../../../../api/react-query/adminPartners'
import { useMutateReissueCard } from '../../../../api/react-query/partners'

const layout = {
  wrapperCol: {
    xs: { span: 14 }
  },
  labelCol: {
    xs: { span: 10 }
  }
}
const ReissueCardRequest = ({ forPartner = false, partnerId, onSuccess }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)
  const [form] = Form.useForm()

  const reasonsGetter = useMemo(
    () => (forPartner ? usePartnerCardReissueClaimsQuery : usePartnerCardReissueReasonsQuery),
    [forPartner]
  )
  const reissueMethod = useMemo(
    () => (forPartner ? useMutateReissueCard : useMutateReissueCardAdmin),
    [forPartner]
  )
  const { data: reissueReasons, isLoading: isLoadingReissueReasons } = reasonsGetter()

  const {
    mutate: reissueCard,
    data: reissueCardData,
    isLoading: isLoadingReissueCard,
    isError
  } = reissueMethod()

  useEffect(() => {
    if (isError || reissueCardData?.errorMessage) {
      message.error(reissueCardData?.errorMessage || 'Не удалось отправить запрос на перевыпуск')
    } else if (reissueCardData?.isSuccess) {
      message.success('Запрос на перевыпуск принят')
      forPartner && onSuccess?.()
      handleCancel()
    }
  }, [reissueCardData, isError])

  return (
    <div>
      <Button ghost type="primary" size={forPartner ? 'large' : 'middle'} onClick={showModal}>
        Перевыпустить карту
      </Button>
      <Modal
        width={700}
        title={
          <Row gutter={[12, 6]} align="middle">
            <Col className="mr-3">
              <b>Перевыпуск банковской карты</b>
            </Col>
          </Row>
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="assetsModalForm"
      >
        <Spin spinning={isLoadingReissueCard}>
          <Form
            {...layout}
            name="reissueCard"
            form={form}
            onFinish={data => reissueCard({ id: partnerId, data })}
          >
            <Form.Item
              label="Укажите причину перевыпуска"
              name="reason"
              rules={[{ required: true, message: 'Укажите, пожалуйста, причину перевыпуска' }]}
            >
              <Select loading={isLoadingReissueReasons} size={forPartner ? 'large' : 'middle'}>
                {reissueReasons?.result?.map(reason => (
                  <Select.Option key={reason.id} value={reason.id}>
                    {reason.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Row justify="end">
              <Col>
                <Row justify="end">
                  <Button
                    htmlType="submit"
                    type="primary"
                    className={`mr-3 ${forPartner ? 'ant-btn-success' : ''}`}
                    size={forPartner ? 'large' : 'middle'}
                  >
                    Перевыпустить
                  </Button>
                </Row>
              </Col>
              <Col>
                <Row justify="end">
                  <Button onClick={handleCancel} size={forPartner ? 'large' : 'middle'}>
                    Отмена
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
}

export default ReissueCardRequest
