import React, { useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { message, Spin } from 'antd'
import { useMutateFinishIODCPartner } from '../../../api/react-query/partners'
import { simpleReducer } from '../../../helper'
import { PROFILE_MAIN_PAGE } from '../../../routes'
import ModalAuthUuid from '../ModalAuthUuid/ModalAuthUuid'
import { apiErrorCodes } from '../../../AppConfig'

const initalState = {
  loading: true,
  isModalAuthUuid: false
}

function PartnersOIDC() {
  const history = useHistory()
  const location = useLocation()
  const [state, setState] = useReducer(simpleReducer, initalState)

  const {
    data: dataFinish,
    mutate: finishIODC,
    isLoading: isLoadingFinish,
    isError: isErrorFinish,
    isSuccess: isSuccessFinish
  } = useMutateFinishIODCPartner()

  useEffect(() => {
    if (isSuccessFinish && dataFinish?.isSuccess) {
      goToProfile()
    } else if (
      !dataFinish?.isSuccess &&
      dataFinish?.errorCode === apiErrorCodes.partnerUuidNotFound
    ) {
      setState({ isModalAuthUuid: true })
    } else if (isErrorFinish || dataFinish?.errorMessage) {
      message.error(dataFinish?.errorMessage || 'Ошибка запроса профиля SberId')
      goToAuth()
    }
  }, [dataFinish, isSuccessFinish, isErrorFinish])

  const goToAuth = () => setTimeout(() => history.replace('/'), 1000)
  const goToProfile = () => history.replace(PROFILE_MAIN_PAGE)
  const onOkModal = () => {
    setState({ isModalAuthUuid: false })
    goToAuth()
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const stateCode = params.get('state')
    const authCode = params.get('code')
    if (stateCode && authCode) {
      setState({ oidcSent: true })
      finishIODC({
        state: stateCode,
        authCode
      })
    } else {
      message.error('Ошибка параметров SberId')
    }
  }, [location.search])

  return (
    <div style={{ marginTop: '5rem' }}>
      <Spin spinning={state.loading || isLoadingFinish} style={{ marginRight: '1rem' }} />
      Обработка данных
      <ModalAuthUuid isModalOpen={state.isModalAuthUuid} onOk={onOkModal} />
    </div>
  )
}

export default PartnersOIDC
