import React, { useContext, useEffect, useReducer, useRef } from 'react'
import { ConfigContext } from '../../../context/configContext'
import { Button, Modal, Spin, Space, Form, Input, message } from 'antd'
import { simpleReducer, validatePhone } from '../../../helper'
import { profileScreen } from '../../../translates'
import { useMutateUnbanPhone } from '../../../api/react-query/sa'

const initialState = {
  isModalOpen: false
}

function UnbanByPhone() {
  const { isAdmin, isCoordinators, isCoordinator, isDivisionalInstructor } =
    useContext(ConfigContext)
  const [form] = Form.useForm()
  const [state, setState] = useReducer(simpleReducer, initialState)
  const inputRef = useRef()

  const {
    mutate: unbanPhone,
    data: dataUnban,
    isSuccess: isSuccessUnban,
    isError: isErrorUnban,
    isLoading: isLoadingUnban
  } = useMutateUnbanPhone()

  useEffect(() => {
    if (isSuccessUnban && dataUnban?.isSuccess) {
      message.success('Телефон разблокирован')
      handleClose()
    } else if (isErrorUnban || dataUnban?.errorMessage) {
      message.error(dataUnban?.errorMessage || 'Ошибка отправки данных')
    }
  }, [isSuccessUnban, isErrorUnban, dataUnban])

  const handleClick = () => {
    setState({ isModalOpen: true })
  }

  const handleClose = () => {
    setState({ isModalOpen: false })
  }

  const handleSave = () => {
    form
      .validateFields()
      .then(fields => {
        unbanPhone({ phone: fields.phone })
      })
      .catch(() => {
        console.log('fail')
      })
  }

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue({ phone: '+7' })
    setTimeout(() => inputRef.current?.focus(), 300)
  }, [state.isModalOpen])

  if (!isAdmin && !isCoordinators && !isCoordinator && !isDivisionalInstructor) {
    return null
  }

  return (
    <Space style={{ marginBottom: isAdmin ? 16 : 0 }}>
      <Button loading={isLoadingUnban} onClick={handleClick}>
        Разблокировать отправку смс
      </Button>
      {state.isModalOpen && (
        <Modal
          visible={state.isModalOpen}
          onCancel={handleClose}
          onOk={handleSave}
          okText="Разблокировать"
          title="Разблокировать отправку смс"
          destroyOnClose
        >
          <Spin spinning={isLoadingUnban}>
            <Form form={form}>
              <Form.Item
                label="Номер телефона"
                name={['phone']}
                rules={[
                  { required: true, message: profileScreen.input.mobilePhone.message },
                  { validator: validatePhone, message: profileScreen.input.mobilePhone.message }
                ]}
              >
                <Input placeholder={profileScreen.input.mobilePhone.placeholder} ref={inputRef} />
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      )}
    </Space>
  )
}

export default UnbanByPhone
