import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, Divider, message, Modal, Row, Spin, Table, Typography } from 'antd'
import Approval from '../../Approval/Approval'
import PartnerDocuments from '../Sections/PartnerDocuments'
import EditableTable from '../../../Common/EditableTable/EditableTable'
import { errors, profileScreen } from '../../../../translates'
import {
  useMutateAdminAcceptEmploymentTypeForm,
  useMutateAdminDeclineEmploymentTypeForm,
  useQueryAdminEmploymentTypeForm
} from '../../../../api/react-query/adminPartners'
import { ACCEPT, ACCEPTED, NONE } from '../../../../Constants'
import {
  arraySortByArray,
  createApiDataFromForm,
  createFormDataFromApi,
  flattenObject,
  restoreFlattenObject,
  validateBankAccountIndividual,
  validateBankAccountSelfEmployed
} from '../../../../helper'
import './EditModals.css'
import { AppConfig } from '../../../../AppConfig'
import { ConfigContext } from '../../../../context/configContext'

const { Text } = Typography
const columns = [
  {
    title: 'Название поля',
    dataIndex: 'fieldName',
    width: 300
  },
  {
    title: 'Значение',
    dataIndex: 'newValue',
    editable: true
  }
]
const documents = [profileScreen.input.bankDetailsPhoto.name, profileScreen.input.egripPhoto.name]

function EditEmploymentTypeRequest({ profile, setPartner, disabled, partnerId, viewOnly = false }) {
  const { user, portalButtonState, isCoordinators, isCoordinator, isExternalCoordinator } =
    useContext(ConfigContext)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [profileData, setProfileData] = useState([])
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})
  const [fieldsHasErrors, setFieldsHasErrors] = useState([])
  const [closeModalAfterAccept, setCloseModalAfterAccept] = useState(false)

  const { data: employmentTypeFormData, isFetching: isFetchingEmploymentTypeData } =
    useQueryAdminEmploymentTypeForm(partnerId, {
      enabled: isModalVisible,
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0
    })

  const {
    mutate: acceptRequest,
    data: acceptRequestData,
    isLoading: acceptRequestLoading,
    isSuccess: acceptRequestSuccess,
    isError: acceptRequestError
  } = useMutateAdminAcceptEmploymentTypeForm()

  const {
    mutate: declineRequest,
    data: declineRequestData,
    isLoading: declineRequestLoading,
    isSuccess: declineRequestSuccess,
    isError: declineRequestError
  } = useMutateAdminDeclineEmploymentTypeForm()

  const showModal = () => {
    if (user?.online && (isCoordinators || isCoordinator || isExternalCoordinator)) {
      portalButtonState?.stopStatusRequesting()
    }
    setIsModalVisible(true)
  }
  const handleCancel = useCallback(() => {
    if (user?.online && (isCoordinators || isCoordinator || isExternalCoordinator)) {
      portalButtonState?.startStatusRequesting()
    }
    setIsModalVisible(false)
  }, [user?.online, isCoordinators, isCoordinator, isExternalCoordinator, portalButtonState])

  const prepareAcceptRequestData = newValues => {
    const inputs = profileScreen.input
    newValues = restoreFlattenObject(newValues)
    const data = createApiDataFromForm({
      bankDetails: Object.fromEntries(
        Object.entries({
          ...newValues
        }).filter(([, value]) => value !== undefined)
      )
    })
    return {
      data: {
        bankDetails: {
          [inputs.bankAccount.name]: data?.bankDetails[inputs.bankAccount.name],
          [inputs.bankBik.name]: data?.bankDetails[inputs.bankBik.name],
          [inputs.bankName.name]: data?.bankDetails[inputs.bankName.name]
        },
        [inputs.email.name]: newValues[inputs.email.name],
        [inputs.legalForm.name]: newValues[inputs.legalForm.name],
        [inputs.bankDetailsPhoto.name]: newValues[inputs.bankDetailsPhoto.name],
        [inputs.egripPhoto.name]: newValues[inputs.egripPhoto.name]
      }
    }
  }

  const handleCommit = useCallback(
    ({ outcome, comment: reason }) => {
      setBackendFieldsErrors({})
      if (outcome === ACCEPT) {
        const newValues = Object.fromEntries(profileData.map(item => [item.key, item.newValue]))
        setCloseModalAfterAccept(true)
        acceptRequest({
          id: partnerId,
          data: prepareAcceptRequestData({
            ...newValues,
            [profileScreen.input.legalForm.name]:
              employmentTypeFormData?.form?.[profileScreen.input.legalForm.name],
            [profileScreen.input.bankDetailsPhoto.name]:
              employmentTypeFormData?.form?.[profileScreen.input.bankDetailsPhoto.name]?.fileId,
            ...(employmentTypeFormData?.form?.[profileScreen.input.egripPhoto.name]?.fileId
              ? {
                  [profileScreen.input.egripPhoto.name]:
                    employmentTypeFormData?.form?.[profileScreen.input.egripPhoto.name]?.fileId
                }
              : {})
          })
        })
      } else {
        declineRequest({ id: partnerId, reason })
      }
    },
    [acceptRequest, declineRequest, employmentTypeFormData?.form, partnerId, profileData]
  )

  const flattenProfileData = useMemo(
    () => flattenObject(createFormDataFromApi({ bankDetails: profile?.bankDetails })?.bankDetails),
    [profile]
  )

  useEffect(() => {
    const data = []
    const formData = { ...(employmentTypeFormData?.form || {}) }
    delete formData.bankDetailsPhoto
    delete formData.egripPhoto
    delete formData.legalForm
    delete formData.status
    delete formData.statusCode
    delete formData.statusReason
    const flattenData = flattenObject(createFormDataFromApi({ bankDetails: formData })?.bankDetails)

    const employmentTypeKeysList = [
      profileScreen.input.bankAccount.name,
      profileScreen.input.bankBik.name,
      profileScreen.input.bankName.name,
      profileScreen.input.email.name
    ]

    const requiredList = [
      profileScreen.input.bankAccount.name,
      profileScreen.input.bankBik.name,
      profileScreen.input.bankName.name,
      profileScreen.input.email.name
    ]

    const keys = arraySortByArray(Object.keys(flattenData), employmentTypeKeysList)
    keys
      ?.filter(key => employmentTypeKeysList.includes(key.replace('bankDetails.', '')))
      .map(key => {
        const shortKey = key.replace('bankDetails.', '')
        const fieldName = profileScreen.input[shortKey]?.label
        data.push({
          key: shortKey,
          required: requiredList.includes(key),
          fieldName,
          newValue: flattenData?.[key],
          isDifferentFromProfile: flattenProfileData?.[key] !== flattenData?.[key],
          specialValidation: [
            ...(shortKey === profileScreen.input.bankAccount.name &&
            employmentTypeFormData?.form?.legalForm === AppConfig.legalFormCodes.individual
              ? [
                  {
                    message: errors.bankAccount,
                    validator: validateBankAccountIndividual
                  }
                ]
              : []),
            ...(shortKey === profileScreen.input.bankAccount.name &&
            employmentTypeFormData?.form?.legalForm === AppConfig.legalFormCodes.self
              ? [
                  {
                    message: errors.bankAccountSelf,
                    validator: validateBankAccountSelfEmployed
                  }
                ]
              : [])
          ]
        })
      })
    setProfileData(data)
  }, [employmentTypeFormData?.form, flattenProfileData, profile])

  useEffect(() => {
    if (acceptRequestError) {
      message.error('Не удалось принять запрос.')
    }
  }, [acceptRequestError])

  useEffect(() => {
    if (acceptRequestSuccess) {
      if (acceptRequestData?.errorMessage) {
        return message.error(acceptRequestData?.errorMessage || 'Не удалось принять запрос.')
      }
      if (closeModalAfterAccept) {
        handleCancel()
        setCloseModalAfterAccept(false)
      }
      message.success('Запрос на изменение данных принят.')
    }
  }, [acceptRequestData, acceptRequestSuccess, handleCancel, closeModalAfterAccept])

  useEffect(() => {
    if (declineRequestError) {
      message.error('Не удалось отклонить запрос.')
    }
  }, [declineRequestError])

  useEffect(() => {
    if (declineRequestSuccess) {
      if (declineRequestData?.errorMessage) {
        return message.error(declineRequestData?.errorMessage || 'Не удалось отклонить запрос.')
      }
      handleCancel()
      message.success('Запрос на изменение данных отклонен.')
    }
  }, [declineRequestData, declineRequestSuccess, handleCancel])

  const tableProps = useMemo(
    () => ({
      backendFieldsErrors,
      setHasErrors: setFieldsHasErrors,
      columns,
      dataSource: profileData,
      setDataSource: setProfileData,
      pagination: false
    }),
    [backendFieldsErrors, profileData]
  )

  return (
    <div>
      <Button ghost type="primary" className="w-150" disabled={disabled} onClick={showModal}>
        {viewOnly ? 'Посмотреть' : 'Проверить'}
      </Button>
      <Modal
        width={1200}
        style={{ top: 20 }}
        title={
          <Row gutter={[12, 6]} align="middle">
            <Col className="mr-3">
              <b>
                Данные о смене формы занятости с {profile?.personalData?.legalForm} на{' '}
                {AppConfig.legalFormLabels[employmentTypeFormData?.form?.legalForm]}
              </b>
            </Col>
          </Row>
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="assetsModalForm"
      >
        <Row className="mb-3">
          <Text underline>
            ФИО: <b>{profile?.personalData?.name}</b>
          </Text>
        </Row>
        <Row className="mb-3">
          <Text underline>
            ИНН: <b>{profile?.personalData?.inn}</b>
          </Text>
        </Row>
        <Spin
          spinning={isFetchingEmploymentTypeData || acceptRequestLoading || declineRequestLoading}
        >
          {employmentTypeFormData?.form?.statusReason && (
            <Alert
              className="mb-3"
              message={<Text type="secondary">Причины отказа</Text>}
              description={employmentTypeFormData?.form.statusReason}
              type="error"
            />
          )}
          {viewOnly ? <Table {...tableProps} /> : <EditableTable {...tableProps} />}
          <Divider style={{ border: 0 }} />
          <PartnerDocuments
            partnerId={partnerId}
            documents={documents}
            setPartner={setPartner}
            profile={employmentTypeFormData?.form}
            getDocumentStatus={d =>
              profile?.[d]?.fileId === employmentTypeFormData?.form?.[d]?.fileId ? ACCEPTED : NONE
            }
            getDocumentButton={() => null}
            correctForm={AppConfig.updateDocFormNames.employmentTypeForm}
          />
          <Divider />
          {viewOnly ? (
            <Button type="primary" onClick={handleCancel}>
              Закрыть
            </Button>
          ) : (
            <Approval
              onCommit={handleCommit}
              onCancel={handleCancel}
              multiline
              disabled={fieldsHasErrors?.length}
              autocomplete
              targetType={AppConfig.reasonTargets.employmentTypeForm}
            />
          )}
        </Spin>
      </Modal>
    </div>
  )
}

export default EditEmploymentTypeRequest
