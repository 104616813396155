import { Button, Typography, Modal, message, Row, Col, Input } from 'antd'
import React, { useEffect, useMemo, useReducer } from 'react'

import { useMutateAdminUnBanPartner } from '../../../../api/react-query/adminPartners'
import { useParams } from 'react-router-dom'
import { simpleReducer } from '../../../../helper'

const { Text } = Typography
const initialState = {
  isShow: false,
  reason: null
}
const UnBan = ({ allowUnban = false }) => {
  const { id } = useParams()
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    mutate: mutateUnBanPartner,
    data: dataUnBanPartner,
    isError: isErrorUnBanPartner,
    isLoading: isLoadingUnBanPartner,
    isSuccess: isSuccessUnBanPartner
  } = useMutateAdminUnBanPartner()

  useEffect(() => {
    if (isErrorUnBanPartner) {
      message.error(dataUnBanPartner?.errorMessage || 'Ошибка разбана Партнера.')
    }
  }, [dataUnBanPartner, isErrorUnBanPartner])
  useEffect(() => {
    if (isSuccessUnBanPartner) {
      if (dataUnBanPartner?.errorMessage) {
        return message.error(dataUnBanPartner?.errorMessage)
      }
      setState({ isShow: false })
      message.success('Партнер разбанен.')
    }
  }, [dataUnBanPartner, isSuccessUnBanPartner])

  const handleOnSave = () => mutateUnBanPartner({ id, reason: state.reason })
  const okButtonProps = useMemo(
    () => ({ className: 'green', loading: isLoadingUnBanPartner }),
    [isLoadingUnBanPartner]
  )
  const cancelButtonProps = useMemo(
    () => ({ disabled: isLoadingUnBanPartner }),
    [isLoadingUnBanPartner]
  )
  if (!allowUnban) return null

  return (
    <>
      <Button
        onClick={() => setState({ isShow: true })}
        disabled={isLoadingUnBanPartner}
        className="mb-3 ml-3"
        type="success"
      >
        Разбанить
      </Button>
      <Modal
        width={700}
        visible={state.isShow}
        title="Разбанить Партнера"
        onCancel={() => setState(initialState)}
        okButtonProps={okButtonProps}
        cancelButtonProps={cancelButtonProps}
        onOk={handleOnSave}
      >
        <Text>
          Партнер будет разбанен и получит статус свободного партнера. <br />
          Партнер получит СМС и сможет в своей анкете внести изменения и отправить ее на проверку.
        </Text>
        <Row style={{ marginTop: '1rem' }}>
          <Col span={4}>Причина:</Col>
          <Col span={20}>
            <Input
              value={state.reason}
              onChange={e => {
                setState({ reason: e.target.value })
              }}
              placeholder="Введите причину"
            />
          </Col>
        </Row>
      </Modal>
    </>
  )
}
export default UnBan
