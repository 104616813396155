import React, { useMemo, useState } from 'react'
import { Divider } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import './InnPhotoWithDescription.css'
import Documents from './Documents'
import { errors, profileScreen } from '../../../translates'
import { InnFormItem } from '../../Common'
import { validateFullInn, validateInn } from '../../../helper'
import {
  partnerStatusCodes,
  innDetailsStatusCodes,
  individual,
  AppConfig
} from '../../../AppConfig'

function InnPhotoWithDescription({ profile, form }) {
  const [isShowDescription, setIsShowDescription] = useState(false)
  const isShowInnInput = useMemo(() => {
    const inputForStatuses = [
      partnerStatusCodes.new,
      partnerStatusCodes.draft,
      partnerStatusCodes.free
    ].includes(profile?.statusCode)
    const inputForCorrection =
      profile?.statusCode === partnerStatusCodes.correction &&
      (profile?.personalData?.legalForm === individual ||
        [AppConfig.partnerRoles.szContent, AppConfig.partnerRoles.researcher].includes(
          profile?.personalData?.roleCode
        ))
    return inputForStatuses || inputForCorrection
  }, [profile])

  const isShowInnPhoto = useMemo(() => {
    return (
      profile?.innPhoto?.visible || profile?.innDetails?.status === innDetailsStatusCodes.declined
    )
  }, [profile])

  const handleClickQuestion = () => {
    setIsShowDescription(!isShowDescription)
  }

  return (
    <>
      <Divider orientation="left">ИНН</Divider>
      <div className="innPhotoDescription">
        {isShowInnInput ? (
          <InnFormItem
            label={profileScreen.input.inn.label}
            placeholder={profileScreen.input.inn.placeholder}
            rules={[
              {
                required: profile?.personalData?.required,
                message: profileScreen.input.inn.message,
                validator: validateInn
              },
              {
                message: errors.innCheckSum,
                validator: (rule, value) => validateFullInn({ rule, inn: value })
              }
            ]}
            classNameInput="noScrollNumber"
          />
        ) : (
          <p>Ваш ИНН {profile?.innDetails?.inn}</p>
        )}
        {isShowDescription && (
          <>
            <p>Загрузите фото ИНН, перейдите в Мой налог</p>
            <p>
              Выберите пункт «Прочее» <ArrowRightOutlined /> «Профиль» <ArrowRightOutlined />{' '}
              и&nbsp;сделайте снимок экрана.
            </p>
            <p>Экран может отличаться, но на нём должны быть видны ФИО и номер</p>
            <img src="/img/inn-photo-doc.png" />
          </>
        )}
      </div>
      {isShowInnPhoto && (
        <Documents
          title={null}
          hidePhotoDoc
          documents={[profileScreen.input.innPhoto.name]}
          required={{ [profileScreen.input.innPhoto.name]: profile?.innPhoto?.required }}
          form={form}
          onInnIconClick={handleClickQuestion}
        />
      )}
    </>
  )
}

export default InnPhotoWithDescription
