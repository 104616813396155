import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, Divider, message, Modal, Row, Spin, Table, Typography } from 'antd'
import Approval from '../../Approval/Approval'
import PartnerDocuments from '../Sections/PartnerDocuments'
import EditableTable from '../../../Common/EditableTable/EditableTable'
import { errors, profileScreen } from '../../../../translates'
import {
  useMutateAdminAcceptRequisitesForm,
  useMutateAdminDeclineRequisitesForm,
  useQueryAdminRequisitesForm
} from '../../../../api/react-query/adminPartners'
import { ACCEPT, ACCEPTED, NONE } from '../../../../Constants'
import {
  arraySortByArray,
  createApiDataFromForm,
  createFormDataFromApi,
  flattenObject,
  patterns,
  restoreFlattenObject,
  validateBankAccountIndividual,
  validateBankAccountSelfEmployed,
  validateNameRus
} from '../../../../helper'
import './EditModals.css'
import { AppConfig, individual, selfEmployed } from '../../../../AppConfig'
import { ConfigContext } from '../../../../context/configContext'

const { Text } = Typography
const columns = [
  {
    title: 'Название поля',
    dataIndex: 'fieldName',
    width: 300
  },
  {
    title: 'Значение',
    dataIndex: 'newValue',
    editable: true
  }
]
const documents = [profileScreen.input.requisitesBankPhoto.name]

function EditRequisitesRequest({ profile, setPartner, disabled, partnerId, viewOnly = false }) {
  const { user, portalButtonState, isCoordinators, isCoordinator, isExternalCoordinator } =
    useContext(ConfigContext)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [profileData, setProfileData] = useState([])
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})
  const [fieldsHasErrors, setFieldsHasErrors] = useState([])
  const [closeModalAfterAccept, setCloseModalAfterAccept] = useState(false)

  const { data: requisitesFormData, isFetching: isLoadingRequisitesFormData } =
    useQueryAdminRequisitesForm(partnerId, {
      enabled: isModalVisible,
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0
    })

  const {
    mutate: acceptRequest,
    data: acceptRequestData,
    isLoading: acceptRequestLoading,
    isSuccess: acceptRequestSuccess,
    isError: acceptRequestError
  } = useMutateAdminAcceptRequisitesForm()

  const {
    mutate: declineRequest,
    data: declineRequestData,
    isLoading: declineRequestLoading,
    isSuccess: declineRequestSuccess,
    isError: declineRequestError
  } = useMutateAdminDeclineRequisitesForm()

  const showModal = () => {
    if (user?.online && (isCoordinators || isCoordinator || isExternalCoordinator)) {
      portalButtonState?.stopStatusRequesting()
    }
    setIsModalVisible(true)
  }
  const handleCancel = () => {
    if (user?.online && (isCoordinators || isCoordinator || isExternalCoordinator)) {
      portalButtonState?.startStatusRequesting()
    }
    setIsModalVisible(false)
  }

  const prepareAcceptRequestData = newValues => {
    const inputs = profileScreen.input
    newValues = restoreFlattenObject(newValues)
    const data = createApiDataFromForm({
      requisitesDetails: Object.fromEntries(
        Object.entries({
          ...newValues
        }).filter(([, value]) => value !== undefined)
      )
    })?.requisitesDetails
    return {
      [inputs.requisitesbankAccount.name]: data[inputs.requisitesbankAccount.name],
      [inputs.requisitesbankBik.name]: data[inputs.requisitesbankBik.name],
      [inputs.requisitesbankName.name]: data[inputs.requisitesbankName.name],
      [inputs.requisiteslastName.name]: data[inputs.requisiteslastName.name]?.trim(),
      [inputs.requisitesfirstName.name]: data[inputs.requisitesfirstName.name]?.trim(),
      [inputs.requisitespatronymic.name]: data[inputs.requisitespatronymic.name]?.trim(),
      [inputs.requisitesBankPhoto.name]: data[inputs.requisitesBankPhoto.name]
    }
  }

  const handleCommit = ({ outcome, comment, additionalComment }) => {
    setBackendFieldsErrors({})
    if (outcome === ACCEPT) {
      const newValues = Object.fromEntries(profileData.map(item => [item.key, item.newValue]))
      setCloseModalAfterAccept(true)
      acceptRequest({
        id: partnerId,
        data: prepareAcceptRequestData({
          ...newValues,
          bankRequisitesPhoto: requisitesFormData?.form?.bankRequisitesPhoto?.fileId
        })
      })
    } else {
      declineRequest({ id: partnerId, reason: comment, description: additionalComment })
    }
  }
  const flattenProfileData = useMemo(
    () =>
      flattenObject(
        createFormDataFromApi({ requisitesDetails: profile?.requisitesDetails })?.requisitesDetails
      ),
    [profile]
  )
  useEffect(() => {
    const data = []
    const formData = { ...(requisitesFormData?.form || {}) }
    delete formData[profileScreen.input.requisitesBankPhoto.name]
    delete formData.status
    delete formData.statusCode
    delete formData.statusReason
    const flattenData = flattenObject(
      createFormDataFromApi({ requisitesDetails: formData })?.requisitesDetails
    )

    const requisitesKeysList = [
      profileScreen.input.requisiteslastName.name,
      profileScreen.input.requisitesfirstName.name,
      profileScreen.input.requisitespatronymic.name,
      profileScreen.input.requisitesbankAccount.name,
      profileScreen.input.requisitesbankBik.name
    ]

    const requiredList = [
      profileScreen.input.requisiteslastName.name,
      profileScreen.input.requisitesfirstName.name,
      // profileScreen.input.requisitespatronymic.name,
      profileScreen.input.requisitesbankAccount.name,
      profileScreen.input.requisitesbankBik.name
    ]

    const keys = arraySortByArray(Object.keys(flattenData), requisitesKeysList)
    keys
      ?.filter(key => requisitesKeysList.includes(key))
      .map(key => {
        const fieldName = profileScreen.input[`requisites${key}`]?.label
        data.push({
          key,
          required: requiredList.includes(key),
          fieldName,
          newValue: flattenData?.[key],
          isDifferentFromProfile: flattenProfileData?.[key] !== flattenData?.[key],
          initEditable: isForceValidation(key, formData),
          specialValidation: [
            ...([
              profileScreen.input.requisiteslastName.name,
              profileScreen.input.requisitesfirstName.name,
              profileScreen.input.requisitespatronymic.name
            ].includes(key)
              ? [
                  {
                    message: errors.nameRus,
                    validator: validateNameRus
                  }
                ]
              : []),
            ...(key === profileScreen.input.bankAccount.name &&
            profile?.personalData?.legalForm === individual
              ? [
                  {
                    message: errors.bankAccount,
                    validator: validateBankAccountIndividual
                  }
                ]
              : []),
            ...(key === profileScreen.input.bankAccount.name &&
            profile?.personalData?.legalForm === selfEmployed
              ? [
                  {
                    message: errors.bankAccountSelf,
                    validator: validateBankAccountSelfEmployed
                  }
                ]
              : [])
          ]
        })
      })
    setProfileData(data)
  }, [requisitesFormData?.form, flattenProfileData, profile])

  const isForceValidation = (key, formData) => {
    const list = [
      profileScreen.input.requisiteslastName.name,
      profileScreen.input.requisitesfirstName.name,
      profileScreen.input.requisitespatronymic.name
    ]
    if (!list.includes(key)) {
      return false
    }

    const val = formData?.[key]
    return val && !patterns.nameRus.test(val)
  }

  useEffect(() => {
    if (acceptRequestError) {
      message.error('Не удалось принять запрос.')
    }
  }, [acceptRequestError])

  useEffect(() => {
    if (acceptRequestSuccess) {
      if (acceptRequestData?.errorMessage) {
        return message.error(acceptRequestData?.errorMessage || 'Не удалось принять запрос.')
      }
      if (closeModalAfterAccept) {
        handleCancel()
        setCloseModalAfterAccept(false)
      }
      message.success('Запрос на изменение данных принят.')
    }
  }, [acceptRequestData, acceptRequestSuccess])

  useEffect(() => {
    if (declineRequestError) {
      message.error('Не удалось отклонить запрос.')
    }
  }, [declineRequestError])

  useEffect(() => {
    if (declineRequestSuccess) {
      if (declineRequestData?.errorMessage) {
        return message.error(declineRequestData?.errorMessage || 'Не удалось отклонить запрос.')
      }
      handleCancel()
      message.success('Запрос на изменение данных отклонен.')
    }
  }, [declineRequestData, declineRequestSuccess])

  const tableProps = useMemo(
    () => ({
      backendFieldsErrors,
      setHasErrors: setFieldsHasErrors,
      columns,
      dataSource: profileData,
      setDataSource: setProfileData,
      pagination: false
    }),
    [backendFieldsErrors, profileData, viewOnly]
  )

  return (
    <div>
      <Button ghost type="primary" className="w-150" disabled={disabled} onClick={showModal}>
        {viewOnly ? 'Посмотреть' : 'Проверить'}
      </Button>
      <Modal
        width={1200}
        style={{ top: 20 }}
        title={
          <Row gutter={[12, 6]} align="middle">
            <Col className="mr-3">
              <b>Данные о банковских реквизитах</b>
            </Col>
          </Row>
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="assetsModalForm"
      >
        <Row className="mb-3">
          <Text underline>
            Полное ФИО: <b>{requisitesFormData?.form?.[profileScreen.input.requisitesname.name]}</b>
          </Text>
        </Row>
        <Spin
          spinning={isLoadingRequisitesFormData || acceptRequestLoading || declineRequestLoading}
        >
          {requisitesFormData?.form?.statusReason && (
            <Alert
              className="mb-3"
              message={<Text type="secondary">Причины отказа</Text>}
              description={
                <>
                  {requisitesFormData?.form.statusReason}
                  {requisitesFormData?.form?.statusDescription && (
                    <div style={{ marginTop: '1rem' }}>
                      <div className="ant-alert-message">
                        <Text type="secondary">Уточнение причины</Text>
                      </div>
                      {requisitesFormData?.form?.statusDescription}
                    </div>
                  )}
                </>
              }
              type="error"
            />
          )}
          {viewOnly ? <Table {...tableProps} /> : <EditableTable {...tableProps} />}
          <Divider style={{ border: 0 }} />
          <PartnerDocuments
            partnerId={partnerId}
            documents={documents}
            setPartner={setPartner}
            profile={requisitesFormData?.form}
            getDocumentStatus={d =>
              profile?.[d]?.fileId === requisitesFormData?.form?.[d]?.fileId ? ACCEPTED : NONE
            }
            getDocumentButton={() => null}
            correctForm={AppConfig.updateDocFormNames.bankRequisitesForm}
          />
          <Divider />
          {viewOnly ? (
            <Button type="primary" onClick={handleCancel}>
              Закрыть
            </Button>
          ) : (
            <Approval
              onCommit={handleCommit}
              onCancel={handleCancel}
              multiline
              disabled={fieldsHasErrors?.length}
              targetType={AppConfig.reasonTargets.requisitesDetails}
              isReasonWithAdditional
            />
          )}
        </Spin>
      </Modal>
    </div>
  )
}

export default EditRequisitesRequest
