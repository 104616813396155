import React, { useContext, useEffect, useReducer } from 'react'
import { Button, Col, Divider, message, Row, Space, Typography } from 'antd'

import Approval from '../../Approval/Approval'
import { useMutateAdminUpdatePartnerInnDetails } from '../../../../api/react-query/adminPartners'
import { ConfigContext } from '../../../../context/configContext'
import DocumentPreview from './DocumentPreview'
import { profileScreen } from '../../../../translates'
import { apiErrorCodes, AppConfig, individual } from '../../../../AppConfig'
import EditInnDetailsData from '../EditModals/EditInnDetailsData'
import CheckSberIcon from '../../../Common/CheckSberIcon'
import CheckTinkoffIcon from '../../../Common/CheckTinkoffIcon'
import { simpleReducer } from '../../../../helper'

const { Text } = Typography
const initialState = {
  isModalOpen: false,
  backendFieldsErrors: {}
}

function PartnerInn({ profile, loading, onCommit, partnerId }) {
  const { isAdmin, isCoordinators, isCoordinator, isExternalCoordinator, portalButtonState, user } =
    useContext(ConfigContext)
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    mutate: mutateUserUpdateInnDetails,
    data: dataUserUpdateInnDetails,
    isError: isErrorUserUpdateInnDetails,
    isLoading: isLoadingUserUpdateInnDetails,
    isSuccess: isSuccessUserUpdateInnDetails
  } = useMutateAdminUpdatePartnerInnDetails()

  useEffect(() => {
    if (isErrorUserUpdateInnDetails || dataUserUpdateInnDetails?.isSuccess === false) {
      if (dataUserUpdateInnDetails?.errorCode === apiErrorCodes.innDuplicate) {
        setState({
          backendFieldsErrors: { inn: [dataUserUpdateInnDetails?.errorMessage] }
        })
      }
      message.error(
        dataUserUpdateInnDetails?.errorMessage || 'Ошибка обновления данных ИНН партнера'
      )
    }
  }, [isErrorUserUpdateInnDetails, dataUserUpdateInnDetails])
  useEffect(() => {
    if (isSuccessUserUpdateInnDetails && dataUserUpdateInnDetails.isSuccess) {
      message.success('Данные ИНН партнера успешно обновлены')
      if (user?.online && (isExternalCoordinator || isCoordinators || isCoordinator)) {
        portalButtonState?.startStatusRequesting()
      }
      setState({ isModalOpen: false })
    }
  }, [isSuccessUserUpdateInnDetails])

  const handleOpenEditModal = () => {
    if (user?.online && (isExternalCoordinator || isCoordinators || isCoordinator)) {
      portalButtonState?.stopStatusRequesting()
    }
    setState({ isModalOpen: true })
  }
  const handleCloseEditModal = () => {
    if (user?.online && (isExternalCoordinator || isCoordinators || isCoordinator)) {
      portalButtonState?.startStatusRequesting()
    }
    setState(initialState)
  }

  const handleCommit = (d, o, c) => onCommit(d, o, c)

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row>
          <Col span={8}>
            <Text type="secondary">ФИО в ФНС</Text>
          </Col>
          <Col span={16}>
            <Row justify="space-between">
              <Text>
                {profile?.personalData?.legalForm === individual
                  ? 'Не применимо'
                  : profile?.innDetails?.fio}
              </Text>
              {profile?.innDetails?.allowEdit && (
                <Button type="primary" size="small" onClick={handleOpenEditModal}>
                  Редактировать
                </Button>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Text type="secondary">ИНН</Text>
          </Col>
          <Col span={1}>
            <div className="wrapCheckIcons">
              <CheckSberIcon profileAndSberIdProperty="inn" profile={profile} />
              <CheckTinkoffIcon profileAndTinkoffIdProperty="inn" profile={profile} />
            </div>
          </Col>
          <Col span={16}>
            <Text>{profile?.innDetails?.inn}</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <DocumentPreview
              document={profile?.innPhoto}
              text="ИНН"
              partnerId={partnerId}
              documentType={profileScreen.input.innPhoto.name}
            />
          </Col>
        </Row>
      </Space>
      {profile?.innDetails?.allowReview && (
        <>
          <Divider />
          <Approval
            loading={loading}
            defaultOutcome={profile?.innDetails?.status}
            defaultComment={profile?.innDetails?.statusReason}
            onCommit={({ outcome, comment }) => handleCommit('innDetails', outcome, comment)}
            targetType={AppConfig.reasonTargets.innDetails}
            autocomplete={Boolean(
              isAdmin || isCoordinators || isCoordinator || isExternalCoordinator
            )}
          />
        </>
      )}
      {state.isModalOpen && (
        <EditInnDetailsData
          profile={profile}
          onCancel={handleCloseEditModal}
          innDetails={profile?.innDetails}
          partnerId={partnerId}
          onChange={mutateUserUpdateInnDetails}
          visible={state.isModalOpen}
          loading={isLoadingUserUpdateInnDetails}
          backendFieldsErrors={state.backendFieldsErrors}
        />
      )}
    </>
  )
}

export default PartnerInn
