import React from 'react'
import { Row, Col } from 'antd'

function CityChainStoreItem({ data, acceptButton }) {
  return (
    <Row className="city-chain-store">
      <Col>
        {data?.store?.city} {data?.store?.сhain} {data?.store?.name}
      </Col>
      <Col className="accept-btn">{acceptButton}</Col>
    </Row>
  )
}

export default CityChainStoreItem
