import { Divider, PageHeader } from 'antd'
import React from 'react'

import { BankAccountFormItem, BankBikFormItem, BankNameFormItem } from '../../Common'
import {
  validateBankAccount,
  validateBankAccountIndividual,
  validateBankAccountSelfEmployed,
  validateBankBik,
  validateFullBankAccount,
  validateTitle
} from '../../../helper'
import { useStores } from '../../../Store/Store'
import { AppConfig, individual, selfEmployed } from '../../../AppConfig'
import { errors, profileScreen } from '../../../translates'
import { Link } from 'react-router-dom'
import { PROFILE_MAIN_PAGE } from '../../../routes'

const BankDetails = ({ isVisible = false, form, isEditInfoScreen = false }) => {
  const {
    partnerProfile: {
      visible,
      required,
      profile
      // form: partnerProfileForm
    } = {}
  } = useStores()

  const bankAccountChange = (
    <>
      Для перехода на другую форму занятости заполните{' '}
      <Link to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.editEmploymentType}`}>
        анкету смены форма занятости
      </Link>
    </>
  )

  const handleChangeBik = () => {
    if (form?.getFieldValue(['bankDetails', profileScreen.input.bankAccount.name])) {
      form.validateFields([['bankDetails', profileScreen.input.bankAccount.name]])
    }
  }

  const legalForm =
    form?.getFieldValue(['personalData', profileScreen.input.legalForm.name]) ||
    profile?.personalData?.legalForm

  if (!visible.bankDetails && !isVisible) return null
  return (
    <>
      <PageHeader title={profileScreen.title2} />

      <BankAccountFormItem
        label={profileScreen.input.bankAccount.label}
        placeholder={profileScreen.input.bankAccount.placeholder}
        rules={[
          {
            required: required?.bankDetails,
            message: profileScreen.input.bankAccount.message,
            validator: validateBankAccount
          },
          ...(legalForm === individual
            ? [
                {
                  message: (
                    <>
                      {errors.bankAccount}
                      {isEditInfoScreen ? (
                        <>
                          .<br /> {bankAccountChange}
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ),
                  validator: validateBankAccountIndividual
                }
              ]
            : []),
          ...(legalForm === selfEmployed
            ? [
                {
                  message: (
                    <>
                      {errors.bankAccountSelf}
                      {isEditInfoScreen ? (
                        <>
                          .<br />
                          {bankAccountChange}
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ),
                  validator: validateBankAccountSelfEmployed
                }
              ]
            : []),
          {
            message: errors.bankBikAccount,
            validator: (rule, value) =>
              validateFullBankAccount({
                rule,
                account: value,
                bik: form?.getFieldValue(['bankDetails', profileScreen.input.bankBik.name])
              })
          }
        ]}
        classNameInput="noScrollNumber"
      />

      <BankBikFormItem
        label={profileScreen.input.bankBik.label}
        placeholder={profileScreen.input.bankBik.placeholder}
        rules={[
          {
            required: required?.bankDetails,
            message: profileScreen.input.bankBik.message,
            validator: validateBankBik
          }
        ]}
        classNameInput="noScrollNumber"
        onChange={handleChangeBik}
      />

      <BankNameFormItem
        label={profileScreen.input.bankName.label}
        placeholder={profileScreen.input.bankName.placeholder}
        rules={[
          {
            required: required?.bankDetails,
            message: profileScreen.input.bankName.message,
            validator: validateTitle
          }
        ]}
      />
      <Divider />
    </>
  )
}
export default BankDetails
