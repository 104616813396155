import React from 'react'
import {
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  StopTwoTone,
  CloseCircleTwoTone
} from '@ant-design/icons'
import { ACCEPTED, DECLINED, DELETED } from '../../../../Constants'

function DocumentStatus({ status }) {
  if (status === ACCEPTED) return <CheckCircleTwoTone twoToneColor="#52c41a" />
  if (status === DECLINED) return <StopTwoTone twoToneColor="#eb2f96" />
  if (status === DELETED) return <CloseCircleTwoTone twoToneColor="#eb2f96" />
  return <ClockCircleTwoTone />
}

export default DocumentStatus
