import React, { useState, useEffect, useMemo } from 'react'
import { withRouter, useParams, useHistory, generatePath } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  message,
  Typography,
  Form,
  Input,
  Select,
  Button,
  // Tree,
  Row,
  Col,
  Space
} from 'antd'

import { sortByName, isProd } from '../../../helper'
// import CityTree from '../../../components/Admin/Tree/CityTree'
import { useMutateUserCreate, useMutateUserUpdate } from '../../../api/react-query/users'
import { useAllOrgCitiesQuery } from '../../../api/react-query/org'
import { ALL, NEW, twoMinutes } from '../../../Constants'
import { AppConfig } from '../../../AppConfig'
import { NameFormItem } from '../../../components/Common'
// import { useMutateUserSubscriptions } from '../../../api/react-query/sa'
import { ADMIN_USER_PAGE } from '../../../routes'

const { Option } = Select
const { Title } = Typography

// const inputGroupAndTitleHeight = 290
// const subscriptionsTreeHeight = 200

function isDisabledVisibilityScope(value) {
  return AppConfig.disabledVisibilityScopeGroups.includes(value)
}

function UserEdit({
  user,
  // subscriptions,
  // availableSubscriptions,
  groups,
  onClose
  // containerHeight
}) {
  const [cityTree, setCityTree] = useState([])
  const [checkedVisibilityKeys, setCheckedVisibilityKeys] = useState([])
  // const [checkedSubscriptions, setCheckedSubscriptions] = useState(subscriptions.map(item => item.topicId))
  const [userGroupName, setUserGroupName] = useState(user?.groupName)
  const { id } = useParams()
  const history = useHistory()
  const isNewUser = useMemo(() => id === NEW, [id])

  useEffect(() => {
    if (cityTree.length) {
      let data
      /**
       * Для определенных ролей всегда выбраны все города (и нет возможности редактировать)
       * Если роль другая и у юзера есть сохраненная область видимости, предвыбираем её
       * В ином случае - снимаем все галки
       */
      if (isDisabledVisibilityScope(userGroupName)) {
        data = cityTree.map(city => city.id)
      } else if (user?.visibilityScope?.cities) {
        data = user.visibilityScope.cities.map(city => city.id)
      } else {
        data = []
      }
      setCheckedVisibilityKeys(data)
    }
  }, [userGroupName, user, cityTree])

  const {
    data: treeData,
    isError: isErrorTree,
    isSuccess: isSuccessTree
  } = useAllOrgCitiesQuery({
    staleTime: twoMinutes
  })
  useEffect(() => {
    if (isErrorTree) {
      message.error('Ошибка доступа к данным')
    }
  }, [isErrorTree])

  useEffect(() => {
    if (isSuccessTree) {
      setCityTree(sortByName(treeData?.result))
    }
  }, [isSuccessTree, treeData])

  const {
    data: createData,
    mutate: mutateUserCreate,
    isError: isErrorUserCreate,
    isLoading: isLoadingUserCreate
  } = useMutateUserCreate()
  const {
    data: updateData,
    mutate: mutateUserUpdate,
    isError: isErrorUserUpdate,
    isLoading: isLoadingUserUpdate
  } = useMutateUserUpdate()

  // const {
  //   data: userSubscriptionsData,
  //   mutate: mutateUserSubscriptions,
  //   isError: isErrorUserSubscriptions
  // } = useMutateUserSubscriptions()

  useEffect(() => {
    if (isErrorUserCreate || createData?.errorMessage) {
      message.error(createData?.errorMessage || 'Ошибка добавления пользователя')
    }
  }, [createData, isErrorUserCreate])

  useEffect(() => {
    if (isErrorUserUpdate || updateData?.errorMessage) {
      message.error(updateData?.errorMessage || 'Ошибка обновления пользователя')
    }
  }, [isErrorUserUpdate, updateData])

  // useEffect(() => {
  //   if (isErrorUserSubscriptions || userSubscriptionsData?.errorMessage) {
  //     message.error(userSubscriptionsData?.errorMessage || 'Ошибка обновления подписок пользователя')
  //   }
  // }, [isErrorUserSubscriptions, userSubscriptionsData])

  useEffect(() => {
    if (createData?.isSuccess || updateData?.isSuccess) {
      message.success(`Пользователь успешно ${isNewUser ? 'добавлен' : 'обновлен'}`)
      const id = createData?.userId || user?.id
      if (id) {
        // updateSubscriptions(id)
        if (isNewUser) {
          history.push(generatePath(ADMIN_USER_PAGE, { id }))
        }
      }
      onClose()
    }
  }, [createData, updateData, isNewUser, user?.id])

  // const updateSubscriptions = (id) => {
  //   const topicIds = checkedSubscriptions.includes(ALL) ? [ALL] : checkedSubscriptions
  //   mutateUserSubscriptions({ id, topicIds })
  // }
  const onFinish = async values => {
    const body = {
      name: values.name,
      email: values.email,
      id: !isNewUser ? user?.id : undefined,
      uuid: values.uuid,
      mobilePhone: values.mobilePhone,
      groupId: values.groupName,
      visibilityScope: {
        cities: checkedVisibilityKeys.filter(id => id !== ALL).map(id => ({ id }))
      }
    }
    isNewUser ? await mutateUserCreate(body) : await mutateUserUpdate(body)
  }

  // const subscriptionsData = useMemo(() => [
  //   {
  //     title: 'Все',
  //     key: ALL,
  //     children: availableSubscriptions.map(item => ({
  //       title: item.name,
  //       key: item.id
  //     }))
  //   }
  // ], [availableSubscriptions])

  return (
    <Form
      layout="vertical"
      name="editUser"
      initialValues={{ ...user }}
      onFinish={onFinish}
      style={{ paddingBottom: 50 }}
    >
      <Row justify="space-between">
        <Col>
          <Title level={2} type="success">
            {user?.id ? 'Редактирование' : 'Добавление'} пользователя
          </Title>
        </Col>
        <Col>
          <Space className="mt-2">
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoadingUserCreate || isLoadingUserUpdate}
            >
              Сохранить
            </Button>
            {!isNewUser && (
              <Button
                danger
                onClick={onClose}
                disabled={isLoadingUserCreate || isLoadingUserUpdate}
              >
                Отмена
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={15}>
          <NameFormItem
            label="ФИО"
            placeholder="ФИО"
            messageRule="Пожалуйста, введите ФИО"
            size="middle"
            disabled={isProd() && !isNewUser}
          />
        </Col>
        <Col span={9}>
          <Form.Item
            name="email"
            label="Электронная почта"
            rules={[{ required: true, message: 'Пожалуйста, укажите электронную почту' }]}
          >
            <Input placeholder="email@email.ru" type="email" disabled={!isNewUser} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={15}>
          <Form.Item
            name="groupName"
            label="Группа пользователей"
            rules={[{ required: true, message: 'Пожалуйста, выберите группу' }]}
          >
            <Select
              placeholder="Выберите группу"
              onSelect={setUserGroupName}
              disabled={isProd() && !isNewUser}
            >
              {groups.map(c => (
                <Option key={c.id} value={c.id}>
                  {c?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name="mobilePhone" label="Телефон">
            <Input placeholder="+79001234567" type="phone" />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={15}> */}
      {/*  <Col span={15}> */}
      {/*    <Form.Item name='uuid' label='UUID'> */}
      {/*      <Input disabled={isProd() && !isNewUser} /> */}
      {/*    </Form.Item> */}
      {/*  </Col> */}
      {/* </Row> */}

      {/* <Form.Item */}
      {/*  name='subscriptions' */}
      {/*  label='Подписки' */}
      {/* > */}
      {/*  <Tree */}
      {/*    checkable */}
      {/*    selectable={false} */}
      {/*    treeData={subscriptionsData} */}
      {/*    defaultExpandedKeys={[ALL]} */}
      {/*    height={subscriptionsTreeHeight} */}
      {/*    checkedKeys={checkedSubscriptions} */}
      {/*    onCheck={setCheckedSubscriptions} */}
      {/*    className='user-subscribes-tree' */}
      {/*  /> */}
      {/* </Form.Item> */}
      {/* <Title level={5} className='mt-3'>Область видимости пользователя</Title> */}
      {/* <CityTree */}
      {/*  disabled={isDisabledVisibilityScope(userGroupName) || (isProd() && !isNewUser)} */}
      {/*  checkable */}
      {/*  height={(containerHeight - inputGroupAndTitleHeight - subscriptionsTreeHeight) || 300} */}
      {/*  onCheck={setCheckedVisibilityKeys} */}
      {/*  checkedKeys={checkedVisibilityKeys} */}
      {/* /> */}
    </Form>
  )
}

export default withRouter(observer(UserEdit))
