import axios from 'axios'
import { QueryClient } from 'react-query/core'
import * as Sentry from '@sentry/react'
import { v4 as uuidv4 } from 'uuid'

import env from '../../env'
import { ADMIN_AUTH_PAGE } from '../../routes'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1
    }
  }
})

axios.interceptors.response.use(
  response => {
    if (response?.data?.isSuccess) return response.data

    const { data, headers, method, url } = response?.config || {}
    Sentry.captureException(new Error('Response is not success' + response?.config?.url), {
      contexts: {
        request: { data, headers, method, url }
      }
    })
    return response?.data
  },
  error => {
    console.error('API error:', error)
    const request = error.config
    if (error.response && error.response.status === 401 && !request._retry) {
      request._retry = true
      window.location.href = window.location.href?.includes('admin') ? ADMIN_AUTH_PAGE : '/'
    }
    if (error.response && error.response.status !== 401) {
      const { data, headers, method, url } = request
      Sentry.captureException(new Error(error.message + ' ' + request.url), {
        contexts: {
          request: { data, headers, method, url }
        }
      })
    }
    return Promise.reject(error)
  }
)

export const getHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Request-ID': uuidv4()
})

export const getLogHeaders = value => ({
  'X-Frontend-Location': value
})

const get = ({ path, data, params, headers }) =>
  makeRequest({ method: 'GET', path, data, params, headers })
const post = ({ path, data, headers }) => makeRequest({ method: 'POST', path, data, headers })
const put = ({ path, data, headers }) => makeRequest({ method: 'PUT', path, data, headers })
const patch = ({ path, data, headers }) => makeRequest({ method: 'PATCH', path, data, headers })
const del = ({ path, data, headers }) => makeRequest({ method: 'DELETE', path, data, headers })

const makeRequest = ({ method, path, data, params, headers = {} }) =>
  axios.request({
    url: env.apiEndpoint + path,
    method,
    data,
    params,
    headers: {
      ...getHeaders(),
      ...headers
    }
  })

const downloadFile = ({ path, data, method, filename, onSuccess, onError }) => {
  return fetch(`${env.apiEndpoint}${path}`, {
    headers: getHeaders(),
    ...(method && { method }),
    ...(data && { body: JSON.stringify(data) })
  })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      onSuccess()
    })
    .catch(e => {
      onError()
      console.error(`API ${path}`, e)
    })
}

export const api = {
  get,
  post,
  put,
  patch,
  del,
  downloadFile
}
