import { Form, Input } from 'antd'
import React from 'react'
import { adminValidationErrors } from '../../../translates/errors'
import { profileScreen } from '../../../translates'

const BankNameFormItem = ({
  label = 'Название банка',
  rules = undefined,
  requiredRule = true,
  messageRule = adminValidationErrors.bankName,
  size = 'large',
  placeholder = 'Сбербанк',
  validator,
  disabled = false
}) => {
  return (
    <Form.Item
      name={['bankDetails', profileScreen.input.bankName.name]}
      label={label}
      rules={
        rules ?? [
          { required: requiredRule, message: messageRule, ...(validator ? { validator } : {}) }
        ]
      }
    >
      <Input size={size} placeholder={placeholder} disabled={disabled} />
    </Form.Item>
  )
}
export default BankNameFormItem
