import { Form, Input } from 'antd'
import React, { useMemo } from 'react'
import { profileScreen } from '../../../../translates'
import { validatePassportSeries } from '../../../../helper'
import SberIcon from '../../SberIcon'

const PassportSeriesFormItem = ({
  label = profileScreen.input.passportSeries.label,
  rules = undefined,
  requiredRule = true,
  messageRule = profileScreen.input.passportSeries.message,
  size = 'large',
  placeholder = profileScreen.input.passportSeries.placeholder,
  classNameInput = '',
  showCheckIcon = false
}) => {
  const labelParam = useMemo(() => {
    return showCheckIcon ? (
      <>
        {label}
        <SberIcon style={{ marginLeft: '0.5rem' }} />
      </>
    ) : (
      label
    )
  }, [label, showCheckIcon])

  return (
    <Form.Item
      name={['personalData', 'passport', profileScreen.input.passportSeries.name]}
      label={labelParam}
      rules={
        rules ?? [
          { required: requiredRule, message: messageRule, validator: validatePassportSeries }
        ]
      }
    >
      <Input type="number" size={size} placeholder={placeholder} className={classNameInput} />
    </Form.Item>
  )
}
export default PassportSeriesFormItem
