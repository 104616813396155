import React, { useReducer } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import * as path from './routes'

import { GoogleOAuthProvider } from '@react-oauth/google'
import env from './env'
import { simpleReducer } from './helper'
import { ConfigContext, initConfig } from './context/configContext'
import AuthScreen from './Screens/Partner/AuthScreen/AuthScreen'
import 'antd/dist/antd.css'
import '@yaireo/tagify/dist/tagify.css'
import './App.css'
import { ConfigProvider } from 'antd'
import ruRu from 'antd/es/locale/ru_RU'
import './plugins/input-scroll-disable'
import useCoordinatorState from './hooks/useCoordinatorState'

const auth = {
  hasRole: role => role === 'Admin' || role === 'PartnerProfile'
}

const App = () => {
  const portalButtonState = useCoordinatorState()
  const [config, setConfig] = useReducer(simpleReducer, initConfig)
  return (
    <div className="App">
      <ConfigProvider locale={ruRu}>
        <ConfigContext.Provider value={{ ...config, setConfig, portalButtonState }}>
          <GoogleOAuthProvider clientId={env.googleClientID}>
            <Switch>
              <Route path="/" exact render={() => <AuthScreen auth={auth} />} />
              {Object.keys(path.routes).map(key =>
                path.routes[key].map(item => (
                  <PrivateRoute key={item.path} auth={auth} authRole={key} {...item} />
                ))
              )}
              <Redirect from="*" to="/" />
            </Switch>
          </GoogleOAuthProvider>
        </ConfigContext.Provider>
      </ConfigProvider>
    </div>
  )
}

export default App

export const PrivateRoute = ({ component, auth, authRole, city, ...rest }) => {
  const renderFn = Component => props =>
    auth.hasRole(authRole) || rest.publicRoute ? (
      <Component auth={auth} {...props} city={city} />
    ) : (
      <Redirect to="/" />
    )

  return <Route {...rest} render={renderFn(component)} />
}
