import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Layout, Button, Form, PageHeader, Checkbox, Spin, message } from 'antd'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import { useStores } from '../../../Store/Store'
import { PERSONAL_DATA_POLICY_PAGE, ELECTRONIC_SIGNATURE_POLICY_PAGE } from '../../../routes'
import './PolicyScreenStyle.css'
import {
  useGetPersonalDataPolicyQuery,
  useGetElectronicSignaturePolicyQuery,
  useMutatePrivacyPolicyAccept,
  useMutateElectronicSignaturePolicyAccept
} from '../../../api/react-query/partners'
import { policyScreen } from '../../../translates'
import { formLayout } from '../../../Constants'
import { AppConfig } from '../../../AppConfig'

const { Content } = Layout

const PolicyScreen = () => {
  const store = useStores()
  const [personalDataPolicy, setPersonalDataPolicy] = useState({})
  const [electronicSignaturePolicy, setElectronicSignaturePolicy] = useState({})

  const roleCode = useMemo(() => {
    return store?.partnerProfile?.profile?.personalData?.roleCode
  }, [store?.partnerProfile?.profile])

  const {
    data: dataPersonalDataPolicy,
    isError: isErrorPersonalDataPolicy,
    isSuccess: isSuccessPersonalDataPolicy,
    isLoading: isLoadingPersonalDataPolicy
  } = useGetPersonalDataPolicyQuery()

  useEffect(() => {
    if (!dataPersonalDataPolicy?.isSuccess) return
    if (isSuccessPersonalDataPolicy) {
      setPersonalDataPolicy(dataPersonalDataPolicy.agreement)
    }
  }, [dataPersonalDataPolicy, isSuccessPersonalDataPolicy])

  const {
    data: dataElectronicSignaturePolicy,
    isError: isErrorElectronicSignaturePolicy,
    isSuccess: isSuccessElectronicSignaturePolicy,
    isLoading: isLoadingElectronicSignaturePolicy
  } = useGetElectronicSignaturePolicyQuery()

  useEffect(() => {
    if (!dataElectronicSignaturePolicy?.isSuccess) return
    if (isSuccessElectronicSignaturePolicy) {
      setElectronicSignaturePolicy(dataElectronicSignaturePolicy.agreement)
    }
  }, [dataElectronicSignaturePolicy, isSuccessElectronicSignaturePolicy])

  const {
    // error: errorPrivacyPolicyAccept,
    data: dataPrivacyPolicyAccept,
    mutateAsync: mutatePrivacyPolicyAccept,
    isError: isErrorPrivacyPolicyAccept,
    isLoading: isLoadingPrivacyPolicyAccept,
    isSuccess: isSuccessPrivacyPolicyAccept
  } = useMutatePrivacyPolicyAccept()

  useEffect(() => {
    if (isSuccessPrivacyPolicyAccept) {
      store?.partnerProfile?.setPartnerProfile({
        profile: dataPrivacyPolicyAccept?.profile,
        ...(store?.partnerProfile?.id ? { id: store?.partnerProfile?.id } : {})
      })
      message.success(policyScreen.personalDataPolicy.success)
    }
  }, [isSuccessPrivacyPolicyAccept, dataPrivacyPolicyAccept])

  const {
    // error: errorElectronicSignaturePolicyAccept,
    data: dataElectronicSignaturePolicyAccept,
    mutateAsync: mutateElectronicSignaturePolicyyAccept,
    isError: isErrorElectronicSignaturePolicyAccept,
    isLoading: isLoadingElectronicSignaturePolicyAccept,
    isSuccess: isSuccessElectronicSignaturePolicyAccept
  } = useMutateElectronicSignaturePolicyAccept()

  useEffect(() => {
    if (isSuccessElectronicSignaturePolicyAccept) {
      message.success(policyScreen.electronicSignaturePolicy.success)
      store?.partnerProfile?.setPartnerProfile({
        profile: dataElectronicSignaturePolicyAccept?.profile,
        ...(store?.partnerProfile?.id ? { id: store?.partnerProfile?.id } : {})
      })
    }
  }, [isSuccessElectronicSignaturePolicyAccept, dataElectronicSignaturePolicyAccept])

  /**
   * Errors from api requests
   */
  useEffect(() => {
    if (isErrorElectronicSignaturePolicy || isErrorPersonalDataPolicy) {
      message.error(policyScreen.errors.agreement)
    }
    if (isErrorPrivacyPolicyAccept) {
      message.error(policyScreen.errors.agreementAccept)
    }
    if (isErrorElectronicSignaturePolicyAccept) {
      message.error(policyScreen.errors.agreementAccept)
    }
  }, [
    isErrorPrivacyPolicyAccept,
    isErrorPersonalDataPolicy,
    isErrorElectronicSignaturePolicyAccept,
    isErrorElectronicSignaturePolicy
  ])

  const onFinish = async values => {
    if (
      (store?.partnerProfile?.profile?.personalDataPolicy?.isValid ||
        values.acceptPersonalDataPolicy) &&
      (store?.partnerProfile?.profile?.electronicSignaturePolicy?.isValid ||
        values.acceptElectronicSignaturePolicy)
    ) {
      try {
        await mutatePrivacyPolicyAccept({
          agreementId: personalDataPolicy.id
        })
        await mutateElectronicSignaturePolicyyAccept({
          agreementId: electronicSignaturePolicy.id
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const myRef = useRef(null)
  useEffect(() => {
    if (myRef.current) {
      document.body.scrollIntoView({ behavior: 'smooth' })
    }
  }, [myRef])
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const [form] = Form.useForm()
  const [requiredMark, setRequiredMarkType] = useState('required')

  const onRequiredTypeChange = ({ requiredMark }) => {
    setRequiredMarkType(requiredMark)
  }

  const docParams = useMemo(() => {
    return [AppConfig.partnerRoles.szContent, AppConfig.partnerRoles.researcher].includes(roleCode)
      ? `?role=${roleCode}`
      : ''
  }, [roleCode])

  return (
    <div ref={myRef}>
      <Spin
        spinning={isLoadingElectronicSignaturePolicy || isLoadingPersonalDataPolicy}
        size="large"
      >
        <Content className="PolicyScreen">
          <Form
            {...formLayout}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            layout="vertical"
            initialValues={{ requiredMark, remember: true }}
            onValuesChange={onRequiredTypeChange}
            requiredMark={requiredMark}
          >
            <PageHeader title={policyScreen.title} />

            {!store?.partnerProfile?.profile?.electronicSignaturePolicy?.isValid && (
              <Form.Item
                name="acceptElectronicSignaturePolicy"
                valuePropName="checked"
                className="align-start"
                rules={[
                  {
                    required: true,
                    transform: value => value || undefined,
                    type: 'boolean',
                    message: policyScreen.agreementText
                  }
                ]}
              >
                <Checkbox>
                  <Link to={ELECTRONIC_SIGNATURE_POLICY_PAGE}>
                    {policyScreen.electronicSignaturePolicy.placeholder}
                  </Link>
                </Checkbox>
              </Form.Item>
            )}

            {!store?.partnerProfile?.profile?.personalDataPolicy?.isValid && (
              <Form.Item
                name="acceptPersonalDataPolicy"
                valuePropName="checked"
                className="align-start"
                rules={[
                  {
                    required: true,
                    transform: value => value || undefined,
                    type: 'boolean',
                    message: policyScreen.agreementText
                  }
                ]}
              >
                <Checkbox>
                  <Link to={`${PERSONAL_DATA_POLICY_PAGE}${docParams}`}>
                    {policyScreen.personalDataPolicy.placeholder}
                  </Link>
                </Checkbox>
              </Form.Item>
            )}

            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="continue-button"
                loading={isLoadingPrivacyPolicyAccept || isLoadingElectronicSignaturePolicyAccept}
              >
                {policyScreen.button.submit}
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Spin>
    </div>
  )
}
export default observer(PolicyScreen)
