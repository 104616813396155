import { errors } from '../translates'
import { usePartnerRolesQuery } from '../api/react-query/dict'
import useCommonDict from './useCommonDict'

export default function usePartnerRoles() {
  return useCommonDict({
    errorMessage: errors.roles,
    queryMethod: usePartnerRolesQuery
  })
}
