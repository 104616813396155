import { useState, useEffect, useReducer } from 'react'
import env from '../env'
import { dataFetchReducer } from './dataFetchReducer'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'

export const useDataApi = ({ initialUrl, initialParams, initialData }) => {
  const [dataFetch, setDataFetch] = useState({ url: initialUrl, params: initialParams })
  const history = useHistory()

  const setFetch = (newUrl, params) => {
    const newData = {
      url: newUrl || dataFetch.url,
      params: params || dataFetch.params
    }
    setDataFetch(newData)
  }
  const [state = {}, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' })

      try {
        const result = await fetch(env.apiEndpoint + dataFetch.url, dataFetch.params || {})
        if (result.status === 401) {
          if (window.location.pathname !== '/') {
            message.error('Пользователь не авторизован')
            history.replace('/')
          }
          return
        }
        const data = await result.json()

        if (!didCancel) {
          if (!data?.isSuccess) {
            message.error(data?.errorMessage)
            console.error(data?.errorMessage)
            dispatch({ type: 'FETCH_FAILURE', payload: data })
          } else {
            dispatch({ type: 'FETCH_SUCCESS', payload: data })
          }
        }
      } catch (error) {
        if (!didCancel) {
          console.error(error)
          message.error(error?.message || 'Сервер не отвечает')
          dispatch({ type: 'FETCH_FAILURE' })
        }
      }
    }

    dataFetch.url && fetchData()

    return () => {
      didCancel = true
    }
  }, [dataFetch])

  return [state, setFetch]
}
