import { useMemo, useEffect, useCallback } from 'react'
import {
  useGetAssemblyDeliveryPolicy,
  useGetCyberSecurityPolicyQuery,
  useGetMedicalAcceptOffer,
  useGetOffer,
  useMutateAcceptOffer,
  useMutateAssemblyDeliveryPolicyAccept,
  useMutateCyberSecurityPolicyAccept,
  useMutateMedicalOfferAccept
} from '../api/react-query/partners'
import { digestMessage, getMedicalOfferParams, renderMedicalOfferTemplate } from '../helper'
import { AppConfig } from '../AppConfig'
import { message } from 'antd'
import { offerScreen, errors } from '../translates'
import { partnerRoles4MultiOffer } from '../Constants'
import { useStores } from '../Store/Store'

function useRequiredOffers({ role, onSuccess }) {
  const { partnerProfile } = useStores()

  const onlyRoleOffer = useMemo(() => {
    return !partnerRoles4MultiOffer.includes(role)
  }, [role])

  const { data: dataRoleOffer, isError: isErrorRoleOffer } = useGetOffer(role, {
    enabled: !!role
  })

  const { data: dataCyberSecurityPolicy, isError: isErrorCyberSecurityPolicy } =
    useGetCyberSecurityPolicyQuery({
      enabled: !onlyRoleOffer
    })

  const { data: dataAssemblyDeliveryPolicy, isError: isErrorAssemblyDeliveryPolicy } =
    useGetAssemblyDeliveryPolicy({
      enabled: !onlyRoleOffer
    })

  const { data: dataMedicalOffer, isError: isErrorMedicalOffer } = useGetMedicalAcceptOffer({
    enabled: !onlyRoleOffer
  })

  useEffect(() => {
    if (
      isErrorRoleOffer ||
      isErrorCyberSecurityPolicy ||
      isErrorAssemblyDeliveryPolicy ||
      isErrorMedicalOffer
    ) {
      message.error(offerScreen.errors.offer)
    }
  }, [
    isErrorRoleOffer,
    isErrorCyberSecurityPolicy,
    isErrorAssemblyDeliveryPolicy,
    isErrorMedicalOffer
  ])

  const {
    mutate: acceptRoleOffer,
    data: dataAcceptRoleOffer,
    isLoading: isLoadingAcceptRoleOffer,
    isError: isErrorAcceptRoleOffer
  } = useMutateAcceptOffer()

  const {
    mutate: acceptCyberSecurityPolicy,
    data: dataAcceptCyberSecurityPolicy,
    isLoading: isLoadingAcceptCyberSecurityPolicy,
    isError: isErrorAcceptCyberSecurityPolicy
  } = useMutateCyberSecurityPolicyAccept()

  const {
    mutate: acceptAssemblyDeliveryPolicy,
    data: dataAcceptAssemblyDeliveryPolicy,
    isLoading: isLoadingAcceptAssemblyDeliveryPolicy,
    isError: isErrorAcceptAssemblyDeliveryPolicy
  } = useMutateAssemblyDeliveryPolicyAccept()

  const {
    mutate: acceptMedicalOffer,
    data: dataAcceptMedicalOffer,
    isLoading: isLoadingAcceptMedicalOffer,
    isError: isErrorAcceptMedicalOffer
  } = useMutateMedicalOfferAccept()

  const roleOfferData = useMemo(() => dataRoleOffer || {}, [dataRoleOffer])

  const acceptRoleOfferAsync = async function (offerData) {
    const hash = await digestMessage(
      offerData.offer?.subject + offerData.offer?.body + offerData.offer?.agreementTerm
    )
    acceptRoleOffer({
      offerId: offerData.offer?.id,
      offerHash: hash,
      validityFrom: offerData.validityFrom,
      validityTo: offerData.validityTo
    })
  }

  const acceptMedicalOfferAsync = useCallback(
    async function (offerData, profile) {
      const content = renderMedicalOfferTemplate(offerData?.template?.content, profile)
      const hash = await digestMessage(content)
      acceptMedicalOffer({
        templateId: offerData?.template?.id,
        signHash: hash,
        signData: getMedicalOfferParams(profile)
      })
    },
    [acceptMedicalOffer]
  )

  const acceptListOffers = () => {
    acceptRoleOfferAsync(roleOfferData)
  }

  const acceptOnlyMedicalOffer = profile => {
    acceptMedicalOfferAsync(dataMedicalOffer, profile)
  }

  useEffect(() => {
    if (dataAcceptRoleOffer?.isSuccess) {
      partnerProfile?.setPartnerProfile(dataAcceptRoleOffer?.partner)
      if (onlyRoleOffer) {
        onSuccess(AppConfig.partnerSteps.stepSuccess)
      } else {
        acceptCyberSecurityPolicy({ agreementId: dataCyberSecurityPolicy?.agreement?.id })
      }
    } else if (isErrorAcceptRoleOffer || dataAcceptRoleOffer?.errorMessage) {
      onSuccess(AppConfig.partnerSteps.stepOffer)
      message.error(dataAcceptRoleOffer?.errorMessage || 'Ошибка подтверждения оферты')
    }
  }, [
    isErrorAcceptRoleOffer,
    dataAcceptRoleOffer,
    dataCyberSecurityPolicy,
    partnerProfile,
    acceptCyberSecurityPolicy,
    onSuccess,
    onlyRoleOffer
  ])

  useEffect(() => {
    if (dataAcceptCyberSecurityPolicy?.isSuccess) {
      partnerProfile?.setPartnerProfile({ profile: dataAcceptCyberSecurityPolicy?.profile })
      acceptAssemblyDeliveryPolicy({ agreementId: dataAssemblyDeliveryPolicy?.agreement?.id })
    } else if (isErrorAcceptCyberSecurityPolicy || dataAcceptCyberSecurityPolicy?.errorMessage) {
      onSuccess(AppConfig.partnerSteps.stepOffer)
      message.error(dataAcceptCyberSecurityPolicy?.errorMessage || 'Ошибка подтверждения оферты')
    }
  }, [
    isErrorAcceptCyberSecurityPolicy,
    dataAcceptCyberSecurityPolicy,
    dataAssemblyDeliveryPolicy,
    onSuccess,
    partnerProfile,
    acceptAssemblyDeliveryPolicy
  ])

  useEffect(() => {
    if (dataAcceptAssemblyDeliveryPolicy?.isSuccess) {
      partnerProfile?.setPartnerProfile({ profile: dataAcceptAssemblyDeliveryPolicy?.profile })
      if (partnerProfile?.profile?.lmkDetails?.hasMedicalBook) {
        acceptMedicalOfferAsync(dataMedicalOffer, dataAcceptAssemblyDeliveryPolicy?.profile)
      } else {
        onSuccess(AppConfig.partnerSteps.stepSuccess)
      }
    } else if (
      isErrorAcceptAssemblyDeliveryPolicy ||
      dataAcceptAssemblyDeliveryPolicy?.errorMessage
    ) {
      onSuccess(AppConfig.partnerSteps.stepOffer)
      message.error(dataAcceptAssemblyDeliveryPolicy?.errorMessage || 'Ошибка подтверждения оферты')
    }
  }, [
    isErrorAcceptAssemblyDeliveryPolicy,
    dataAcceptAssemblyDeliveryPolicy,
    dataMedicalOffer,
    partnerProfile,
    acceptMedicalOfferAsync,
    onSuccess
  ])

  useEffect(() => {
    if (dataAcceptMedicalOffer?.isSuccess) {
      partnerProfile?.setPartnerProfile({ profile: dataAcceptMedicalOffer?.profile })
      onSuccess(AppConfig.partnerSteps.stepSuccess)
    } else if (isErrorAcceptMedicalOffer || dataAcceptMedicalOffer?.errorMessage) {
      onSuccess(AppConfig.partnerSteps.stepOffer)
      message.error(dataAcceptMedicalOffer?.errorMessage || errors.acceptLmkAgreement)
    }
  }, [isErrorAcceptMedicalOffer, dataAcceptMedicalOffer, onSuccess, partnerProfile])

  const isLoadingAccept = useMemo(() => {
    return (
      isLoadingAcceptRoleOffer ||
      isLoadingAcceptCyberSecurityPolicy ||
      isLoadingAcceptAssemblyDeliveryPolicy ||
      isLoadingAcceptMedicalOffer
    )
  }, [
    isLoadingAcceptRoleOffer,
    isLoadingAcceptCyberSecurityPolicy,
    isLoadingAcceptAssemblyDeliveryPolicy,
    isLoadingAcceptMedicalOffer
  ])

  return {
    acceptListOffers,
    acceptOnlyMedicalOffer,
    isLoadingAccept
  }
}

export default useRequiredOffers
