import React, { useEffect, useMemo } from 'react'
import ViewPdf from '../ViewPdf/ViewPdf'
import { AppConfig } from '../../../../AppConfig'

import ViewImage from '../ViewImage/ViewImage'
import {
  useMutateAdminUpdatePartnerDocs,
  useMutateAdminUpdatePartnerFormDocs
} from '../../../../api/react-query/adminPartners'
import { message } from 'antd'

function DocumentPreview({ document, title, partnerId, documentType, correctForm }) {
  const { isLoading: isLoadingAdminUpdatePartnerDocs, mutateAsync: mutateAdminUpdatePartnerDocs } =
    useMutateAdminUpdatePartnerDocs()

  const {
    isLoading: isLoadingAdminUpdatePartnerFormDocs,
    mutateAsync: mutateAdminUpdatePartnerFormDocs,
    data: dataAdminUpdatePartnerFormDocs
  } = useMutateAdminUpdatePartnerFormDocs()

  useEffect(() => {
    if (
      !dataAdminUpdatePartnerFormDocs?.isSuccess &&
      dataAdminUpdatePartnerFormDocs?.errorMessage
    ) {
      message.error({
        content: dataAdminUpdatePartnerFormDocs?.errorMessage,
        style: { marginTop: 100 }
      })
    }
  }, [dataAdminUpdatePartnerFormDocs])

  const updateDocMethod = useMemo(() => {
    return correctForm ? mutateAdminUpdatePartnerFormDocs : mutateAdminUpdatePartnerDocs
  }, [correctForm])

  const handleSave = async ({ angle, flip }) => {
    return await updateDocMethod({
      id: partnerId,
      ...(correctForm ? { formName: correctForm } : { outcome: 'edit' }),
      body: {
        documentType,
        angle,
        ...(flip && { flip })
      }
    })
  }

  return (
    <div className="preview">
      {document?.fileId ? (
        document?.fileType === AppConfig.uploadTypes.pdf ? (
          <ViewPdf
            fileId={document?.fileId}
            title={title}
            isLoading={isLoadingAdminUpdatePartnerDocs || isLoadingAdminUpdatePartnerFormDocs}
            onSave={handleSave}
          />
        ) : (
          <ViewImage
            image={document}
            title={title}
            isLoading={isLoadingAdminUpdatePartnerDocs || isLoadingAdminUpdatePartnerFormDocs}
            onSave={handleSave}
            hideSave={correctForm === AppConfig.updateDocFormNames.bankRequisitesForm}
          />
        )
      ) : (
        <p>Нет фото</p>
      )}
    </div>
  )
}

export default DocumentPreview
