import React, { useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { message, Spin } from 'antd'
import { useMutateFinishTinkoffPartner } from '../../../api/react-query/partners'
import { simpleReducer } from '../../../helper'
import { PROFILE_MAIN_PAGE } from '../../../routes'
import ModalAuthUuid from '../ModalAuthUuid/ModalAuthUuid'
import { apiErrorCodes } from '../../../AppConfig'

/**
 * Example url
 * https://partner.k-stage.sbermarket-mobile.ru/partners/tinkoffid?state=fullprofile&code=fullprofile&session_state=ZHd9a_LeQl4A1A9m0hOsV2Sm1Sp84G2I8A5i_GCfIEM.H32D7ZqFjjjzvsfBrhue
 * /partners/tinkoffid?state=4D5D6E36EBBA19A65A5D27AFE5EE4079&code=mockcode
 */

const initalState = {
  loading: true,
  isModalAuthUuid: false
}

function PartnersTinkoffId() {
  const history = useHistory()
  const location = useLocation()
  const [state, setState] = useReducer(simpleReducer, initalState)

  const {
    data: dataFinish,
    mutate: finishTinkoffId,
    isLoading: isLoadingFinish,
    isError: isErrorFinish,
    isSuccess: isSuccessFinish
  } = useMutateFinishTinkoffPartner()

  useEffect(() => {
    if (isSuccessFinish && dataFinish?.isSuccess) {
      goToProfile()
    } else if (
      !dataFinish?.isSuccess &&
      dataFinish?.errorCode === apiErrorCodes.partnerUuidNotFound
    ) {
      setState({ isModalAuthUuid: true })
    } else if (isErrorFinish || dataFinish?.errorMessage) {
      message.error(dataFinish?.errorMessage || 'Ошибка запроса профиля TinkoffId')
      goToAuth()
    }
  }, [dataFinish, isSuccessFinish, isErrorFinish])

  const goToAuth = () => setTimeout(() => history.replace('/'), 1000)
  const goToProfile = () => history.replace(PROFILE_MAIN_PAGE)
  const onOkModal = () => {
    setState({ isModalAuthUuid: false })
    goToAuth()
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const stateCode = params.get('state')
    const authCode = params.get('code')
    const errorCode = params.get('errorCode')
    const errorDescription = params.get('errorDescription')
    if (stateCode && authCode) {
      finishTinkoffId({
        state: stateCode,
        authCode
      })
    } else if (errorCode === 'access_denied' && errorDescription) {
      message.error('Превышено количество попыток. Попробуйте позднее.')
      goToAuth()
    } else {
      goToAuth()
    }
  }, [location.search])

  return (
    <div style={{ marginTop: '5rem' }}>
      <Spin spinning={state.loading || isLoadingFinish} style={{ marginRight: '1rem' }} />
      Обработка данных
      <ModalAuthUuid isModalOpen={state.isModalAuthUuid} onOk={onOkModal} />
    </div>
  )
}

export default PartnersTinkoffId
