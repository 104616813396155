import React from 'react'
import { Typography } from 'antd'

const { Paragraph } = Typography
const ellipsis = {
  rows: 2,
  expandable: true,
  symbol: '▼'
}

function AssetStatus({ item }) {
  return item.documentLink ? (
    <div
      className="asset-status"
      onClick={() => window.open(item.documentLink, '_blank', 'noreferrer')}
    >
      <Paragraph
        ellipsis={{
          ...ellipsis,
          onExpand: e => e.stopPropagation()
        }}
      >
        {item.documentStatusReason || item.documentStatus}
      </Paragraph>
    </div>
  ) : (
    <Paragraph ellipsis={ellipsis}>{item.documentStatusReason || item.documentStatus}</Paragraph>
  )
}

export default AssetStatus
