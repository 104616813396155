import React, { useEffect } from 'react'
import { Button, message, Space, Modal } from 'antd'
import { generatePath, useHistory } from 'react-router-dom'
import { ADMIN_REPORTS_PAGE } from '../../../routes'
import { laquo, nbsp, raquo } from '../../../Constants'
import { useMutateDispatchReportToQueue } from '../../../api/react-query/reports'
import { AppConfig } from '../../../AppConfig'

const titles = {
  [AppConfig.report.types.bankDetails]: 'Банковские реквизиты',
  [AppConfig.report.types.passportDetails]: 'Паспортные данные'
}

function AdminDetailsExport({ detailType }) {
  const history = useHistory()

  const info = () => {
    const infoModal = Modal.info({
      title: `Отчет ${titles[detailType]}`,
      content: (
        <div>
          Формирование отчета поставлено в очередь, скачать файл можно в разделе{nbsp}
          {laquo}
          <Button
            style={{ padding: 0 }}
            type="link"
            onClick={() => {
              infoModal.destroy()
              history.replace(generatePath(ADMIN_REPORTS_PAGE))
            }}
          >
            Отчеты
          </Button>
          {raquo}
        </div>
      )
    })
  }

  const {
    mutate: dispatchReport,
    data: dispatchData,
    isLoading,
    isSuccess,
    isError
  } = useMutateDispatchReportToQueue()

  useEffect(() => {
    if (isSuccess && dispatchData?.item) {
      info()
    } else if (isError || dispatchData?.errorMessage) {
      message.error(dispatchData?.errorMessage || 'Ошибка при генерации отчета.')
    }
  }, [isSuccess, isError, dispatchData])

  const handleExport = () => {
    dispatchReport({ type: detailType })
  }

  return (
    <Space style={{ marginBottom: 16, marginRight: 16 }}>
      <Button onClick={handleExport} loading={isLoading}>
        {titles[detailType]}
      </Button>
    </Space>
  )
}

export default AdminDetailsExport
