import React, { useEffect, useState } from 'react'
import { Button, Modal, Row, Col, message, Table, Typography } from 'antd'
import { useQueryAdminPartnerOffers } from '../../../../api/react-query/adminPartners'
import './Offers.css'
import moment from 'moment'
import { AppConfig } from '../../../../AppConfig'

const { Paragraph } = Typography

function Offers({ linkText, partnerId }) {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)

  const {
    data: offersData,
    isLoading: offersLoading,
    isError: offersError
  } = useQueryAdminPartnerOffers(partnerId, {
    enabled: !!partnerId && isModalVisible
  })

  useEffect(() => {
    if (offersError || (offersData && offersData.errorMessage)) {
      message.error(offersData.errorMessage || 'Ошибка получения оферт партнера')
    }
  }, [offersData, offersError])

  const columns = [
    {
      title: 'Ид оферты \n(offerId)',
      dataIndex: 'offerId',
      width: 100
    },
    {
      title: 'Дата подписания \n(signedAt)',
      dataIndex: 'signedAt',
      render: timestamp =>
        timestamp ? moment(timestamp).format(AppConfig.formats.shortDateAndTime) : '',
      width: 150
    },
    {
      title: 'Hash Оферты \n(offerHash)',
      dataIndex: 'offerHash',
      width: 300
    },
    {
      title: 'Ид Партнера \n(partnerId)',
      dataIndex: 'partnerId',
      width: 270
    },
    {
      title: 'Имя партнера \n(partnerName)',
      dataIndex: 'partnerName'
    },
    {
      title: 'Телефон',
      dataIndex: 'partnerPhone',
      width: 150
    }
  ]

  return (
    <div className="Offers">
      <Button onClick={showModal} type="link">
        {linkText}
      </Button>
      <Modal
        width={1200}
        style={{ top: 20 }}
        title={
          <Row gutter={[12, 6]} align="middle">
            <Col className="mr-3">
              <b>Оферты, подписанные партнёром</b>
            </Col>
          </Row>
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="Offers-modal"
      >
        {offersData?.query && <Paragraph code>{offersData?.query}</Paragraph>}
        <Table
          size="small"
          columns={columns}
          loading={offersLoading}
          dataSource={offersData?.rows || []}
        />
      </Modal>
    </div>
  )
}

export default Offers
