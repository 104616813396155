import React, { useEffect } from 'react'
import { Button, Col, Divider, message, Row, Typography } from 'antd'
import { useGetReportsQueue } from '../../../api/react-query/reports'
import ReportList from '../../../components/Admin/Reports/ReportList'

const { Title } = Typography

function Reports() {
  const {
    isError: isErrorReportsQueue,
    data: dataReportsQueue,
    isLoading: isLoadingReportsQueue,
    refetch: refetchReportsQueue
  } = useGetReportsQueue()

  useEffect(() => {
    if (isErrorReportsQueue || dataReportsQueue?.errorMessage) {
      message.error(dataReportsQueue?.errorMessage || 'Не удалось получить отчеты')
    }
  }, [isErrorReportsQueue, dataReportsQueue])

  useEffect(() => {
    const idInterval = setInterval(refetchReportsQueue, 60000)
    return () => {
      clearInterval(idInterval)
    }
  }, [])

  return (
    <>
      <Title level={3} className="title-management">
        Отчеты
      </Title>
      <Divider />
      <Row className="mt-3" gutter={15} align="middle">
        <Col>
          <Title level={5}>Отчеты</Title>
        </Col>
        <Col>
          <Button onClick={() => refetchReportsQueue()}>Обновить</Button>
        </Col>
      </Row>
      <ReportList
        isLoadingReports={isLoadingReportsQueue}
        dataReports={dataReportsQueue?.items}
        showTypeColumn
      />
    </>
  )
}

export default Reports
