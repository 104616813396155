import React, { useEffect, useReducer } from 'react'
import { Button, message } from 'antd'
import { simpleReducer } from '../../../helper'
import { useMutateStartTinkoffPartner } from '../../../api/react-query/partners'
import { AppConfig } from '../../../AppConfig'

const initialState = {
  tinkoffLink: null,
  tinkoffParams: null,
  isLoadedTinkoffWidget: false,
  isTinkoffWidget: false,
  loading: false
}

function AuthTinkoffId({ type = AppConfig.tinkoffId.buttonType.auth, noRecognized = false }) {
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    data: dataStartTinkoffId,
    mutate: startTinkoffId,
    mutateAsync: startTinkoffIdAsync,
    isLoading: isLoadingStartTinkoffId,
    isError: isErrorStartTinkoffId,
    isSuccess: isSuccessStartTinkoffId
  } = useMutateStartTinkoffPartner()

  /**
   * https://id.tinkoff.ru/auth/authorize?client_id=tid_sbermarket_delivery&redirect_uri=https://partner.k-stage.sbermarket-mobile.ru/partners/tinkoffid&state=4C0EAC3686788F8CD58F3643B29F8C47&response_type=code
   * {
   *   "tinkoff_host": "https://id.tinkoff.ru",
   *   "client_id": "tid_sbermarket_delivery",
   *   "redirect_uri": "https://partner.k-stage.sbermarket-mobile.ru/partners/tinkoffid",
   *   "response_type": "code",
   *   "state": "7589E0DFC545535986CF3EB19DC406A4",
   *   "isSuccess": true
   * }
   */

  const getTinkoffParams = data => ({
    clientId: data.client_id,
    redirectUri: data.redirect_uri,
    state: data.state,
    responseType: data.response_type,
    tinkoff_host: data.tinkoff_host
  })

  const getTinkoffLink = data => {
    const urlParams = new URLSearchParams({
      client_id: data.clientId,
      redirect_uri: data.redirectUri,
      state: data.state,
      response_type: data.responseType
    })
    return `${data.tinkoff_host}/auth/authorize?${urlParams.toString()}`
  }

  useEffect(() => {
    if (isSuccessStartTinkoffId && dataStartTinkoffId?.isSuccess) {
      setState({
        tinkoffParams: getTinkoffParams(dataStartTinkoffId)
      })
    } else if (isErrorStartTinkoffId || dataStartTinkoffId?.errorMessage) {
      message.error(dataStartTinkoffId?.errorMessage || 'Ошибка запроса данных к TinkoffId')
      setState({ loading: false })
    }
  }, [dataStartTinkoffId, isSuccessStartTinkoffId, isErrorStartTinkoffId])

  useEffect(() => {
    if (state.tinkoffLink) {
      window.location = state.tinkoffLink
      setState({ loading: false })
    }
  }, [state.tinkoffLink])

  const handleClick = async () => {
    setState({ loading: true })
    if (state.tinkoffParams) {
      setState({
        tinkoffLink: getTinkoffLink(state.tinkoffParams)
      })
    } else {
      const data = await startTinkoffIdAsync()
      setState({
        tinkoffLink: getTinkoffLink(data)
      })
    }
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = AppConfig.tinkoffId.widgetSrc
    script.async = true
    script.onload = () => setState({ isLoadedTinkoffWidget: true })
    document.head.appendChild(script)
    startTinkoffId()
    return () => {
      document.head.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (state.tinkoffParams && window.TidSDK && state.isLoadedTinkoffWidget) {
      const authParams = {
        ...state.tinkoffParams
      }
      delete authParams.tinkoff_host

      const uiParams = {
        container: '#idTinkoffBlock',
        size: 'm',
        color: 'primary',
        text: AppConfig.tinkoffId.buttonLabel[type],
        target: '_self',
        recognized: !noRecognized
      }

      const tidSdk = new window.TidSDK(authParams)
      tidSdk.addButton(uiParams)
    }
    setState({
      isTinkoffWidget: Boolean(state.tinkoffParams && window.TidSDK)
    })
  }, [state.tinkoffParams, state.isLoadedTinkoffWidget])

  return (
    <div id="idTinkoffBlock">
      {!state.isTinkoffWidget && (
        <Button
          className="tinkoffId"
          block
          size="large"
          type="primary"
          onClick={handleClick}
          loading={state.loading || isLoadingStartTinkoffId}
        >
          {AppConfig.tinkoffId.buttonLabel[type]}
          <img src="/img/tinkoff-icon.png" />
        </Button>
      )}
    </div>
  )
}

export default AuthTinkoffId
