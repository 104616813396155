import React, { useEffect, useReducer } from 'react'
import { Modal, List, Checkbox, Button, Spin, message, Input, Space } from 'antd'
import { simpleReducer, sortByParam } from '../../../helper'
import {
  useMutatePriorityCitiesByUser,
  useMutateUserPriorityCities,
  usePriorityCitiesByUser,
  useUserPriorityCities
} from '../../../api/react-query/users'

const { Search } = Input

const initialState = {
  cityList: null,
  checkedList: []
}

function UserCitiesModal({ visible = false, onCancel = () => {}, title = '', userId = null }) {
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    data: dataPriorityCities,
    isSuccess: isSuccessPriorityCities,
    isError: isErrorPriorityCities,
    isLoading: isLoadingPriorityCities
  } = useUserPriorityCities({
    enabled: !userId,
    refetchOnWindowFocus: false,
    staleTime: 0,
    cacheTime: 0
  })

  const {
    data: dataPriorityCitiesByUser,
    isSuccess: isSuccessPriorityCitiesByUser,
    isError: isErrorPriorityCitiesByUser,
    isLoading: isLoadingPriorityCitiesByUser
  } = usePriorityCitiesByUser(userId, {
    enabled: !!userId,
    refetchOnWindowFocus: false,
    staleTime: 0,
    cacheTime: 0
  })

  useEffect(() => {
    if (isSuccessPriorityCities && dataPriorityCities?.isSuccess) {
      setState({
        cityList: sortByParam(dataPriorityCities?.cities, 'cityName'),
        checkedList: dataPriorityCities?.cities
          ?.filter(item => item?.isPriority)
          .map(item => item.cityId)
      })
    } else if (isErrorPriorityCities || dataPriorityCities?.errorMessage) {
      message.error(
        dataPriorityCities?.errorMessage || 'Ошибка получения данных о приоритетных городах'
      )
    }
  }, [dataPriorityCities, isSuccessPriorityCities, isErrorPriorityCities])

  useEffect(() => {
    if (isSuccessPriorityCitiesByUser && dataPriorityCitiesByUser?.isSuccess) {
      setState({
        cityList: sortByParam(dataPriorityCitiesByUser?.cities, 'cityName'),
        checkedList: dataPriorityCitiesByUser?.cities
          ?.filter(item => item?.isPriority)
          .map(item => item.cityId)
      })
    } else if (isErrorPriorityCitiesByUser || dataPriorityCitiesByUser?.errorMessage) {
      message.error(
        dataPriorityCitiesByUser?.errorMessage || 'Ошибка получения данных о приоритетных городах'
      )
    }
  }, [dataPriorityCitiesByUser, isSuccessPriorityCitiesByUser, isErrorPriorityCitiesByUser])

  const {
    mutate: setPriorityCities,
    data: dataSetPriorityCities,
    isSuccess: isSuccessSetPriorityCities,
    isError: isErrorSetPriorityCities,
    isLoading: isLoadingSetPriorityCities
  } = useMutateUserPriorityCities()

  const {
    mutate: setPriorityCitiesByUser,
    data: dataSetPriorityCitiesByUser,
    isSuccess: isSuccessSetPriorityCitiesByUser,
    isError: isErrorSetPriorityCitiesByUser,
    isLoading: isLoadingSetPriorityCitiesByUser
  } = useMutatePriorityCitiesByUser()

  useEffect(() => {
    if (isSuccessSetPriorityCities && dataSetPriorityCities?.isSuccess) {
      message.success('Приоритетные города сохранены')
      onCancel()
    } else if (isErrorSetPriorityCities) {
      message.error('Ошибка сохранения данных о приоритетных городах')
    }
  }, [isSuccessSetPriorityCities, isErrorSetPriorityCities, dataSetPriorityCities])

  useEffect(() => {
    if (isSuccessSetPriorityCitiesByUser && dataSetPriorityCitiesByUser?.isSuccess) {
      message.success('Приоритетные города сохранены')
      onCancel()
    } else if (isErrorSetPriorityCitiesByUser) {
      message.error('Ошибка сохранения данных о приоритетных городах')
    }
  }, [
    isSuccessSetPriorityCitiesByUser,
    isErrorSetPriorityCitiesByUser,
    dataSetPriorityCitiesByUser
  ])

  const handleChange = event => {
    const checkedList = [...state.checkedList]
    if (event.target.checked) {
      checkedList.push(event.target.value)
    } else {
      const index = checkedList.indexOf(event.target.value)
      checkedList.splice(index, 1)
    }
    setState({ checkedList })
  }

  const handleSave = () => {
    if (userId) {
      setPriorityCitiesByUser({
        id: userId,
        data: { cityIds: state.checkedList }
      })
    } else {
      setPriorityCities({
        cityIds: state.checkedList
      })
    }
  }

  const handleResetAll = () => {
    setState({ checkedList: [] })
  }

  const onSearch = search => {
    const data = userId ? dataPriorityCitiesByUser : dataPriorityCities
    setState({
      cityList: sortByParam(data?.cities, 'cityName').filter(city =>
        city.cityName?.toLowerCase().includes(search?.toLowerCase())
      )
    })
  }

  return (
    <Modal
      className="partners-stats-list"
      visible={visible}
      onCancel={onCancel}
      onOk={handleSave}
      title={title}
      destroyOnClose
    >
      <Spin
        spinning={
          isLoadingPriorityCities ||
          isLoadingSetPriorityCities ||
          isLoadingPriorityCitiesByUser ||
          isLoadingSetPriorityCitiesByUser
        }
      >
        <Space style={{ marginBottom: '1em' }}>
          <Search
            placeholder="Поиск города"
            onSearch={onSearch}
            allowClear
            style={{
              width: 200
            }}
          />
          <Button onClick={handleResetAll} type="link">
            Сбросить все
          </Button>
        </Space>
        <List
          className="list"
          bordered
          dataSource={state.cityList?.length ? state.cityList : []}
          renderItem={item => (
            <List.Item>
              <label>
                <Checkbox
                  style={{ marginRight: 10 }}
                  checked={Boolean(state.checkedList.includes(item.cityId))}
                  onChange={handleChange}
                  value={item.cityId}
                />
                {item.cityName}
              </label>
            </List.Item>
          )}
        />
      </Spin>
    </Modal>
  )
}

export default UserCitiesModal
