import { Form, Input } from 'antd'
import React from 'react'
import { profileScreen } from '../../../../translates'
import { validateTitleRus } from '../../../../helper'

const DrivingIssuerNameFormItem = ({
  label = profileScreen.input.drivingLicenceIssuerName.label,
  rules = undefined,
  requiredRule = true,
  messageRule = profileScreen.input.drivingLicenceIssuerName.message,
  size = 'large',
  placeholder = profileScreen.input.drivingLicenceIssuerName.placeholder,
  validator,
  classNameInput = '',
  onChange = () => {},
  disabled = false
}) => {
  return (
    <Form.Item
      name={['personalData', 'drivingLicence', profileScreen.input.drivingLicenceIssuerName.name]}
      label={label}
      rules={
        rules ?? [
          {
            required: requiredRule,
            message: messageRule,
            ...(validator ? { validator } : { validator: validateTitleRus })
          }
        ]
      }
    >
      <Input
        size={size}
        placeholder={placeholder}
        className={classNameInput}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  )
}
export default DrivingIssuerNameFormItem
