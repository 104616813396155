import { Button, message, Space, Spin, Typography, Table, ConfigProvider } from 'antd'
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import ruRu from 'antd/es/locale/ru_RU'
import moment from 'moment-timezone'

import './ActTemplates.css'
import { useGetActTemplatesListQuery } from '../../../api/react-query/acts'
import NewActTemplate from './NewActTemplate'
import { twoMinutes } from '../../../Constants'
import { simpleReducer } from '../../../helper'
import { AppConfig } from '../../../AppConfig'
import { useActTypesQuery, useActTemplatesStatusesQuery } from '../../../api/react-query/dict'
import ActTemplatePreview from './ActTemplatePreview'

const { Text } = Typography

const initState = {
  actTemplatesList: {
    result: [],
    pageSize: 20,
    pageNumber: 1,
    resultsTotal: 0,
    isSuccess: true,
    errorMessage: '',
    ...JSON.parse(localStorage.getItem('actTemplatesRequest') || '{}')
  },
  groups: [],
  actTemplatesStatuses: [],
  actTemplatesTypes: [],
  request: {},
  activeCollapse: []
}

export default function ActTemplates() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [state, setState] = useReducer(simpleReducer, initState)

  const { data, isError, isSuccess, isLoading } = useGetActTemplatesListQuery(
    {
      // pagination
      pageSize: state.actTemplatesList.pageSize,
      pageNumber: state.actTemplatesList.pageNumber,
      // sorting
      sortBy: state.actTemplatesList.orderBy,
      sortDir: state.actTemplatesList.orderDir,
      // filtering
      status: state.actTemplatesList.filter?.status,
      actType: state.actTemplatesList.filter?.actType
    },
    {
      staleTime: twoMinutes
    }
  )

  useEffect(() => {
    if (isSuccess) {
      setState({
        actTemplatesList: {
          ...JSON.parse(localStorage.getItem('actTemplatesRequest') || '{}'),
          ...data
        }
      })
    } else if (isError) {
      console.error(data)
      message.error('Ошибка получения списка шаблонов актов')
    }
  }, [isSuccess, isError, data])

  const handleOpenNewModal = () => setIsModalOpen(true)
  const handleCloseNewModal = () => setIsModalOpen(false)

  const {
    data: actTemplatesTypesData,
    isError: isErrorActTemplatesTypes,
    isSuccess: isSuccessActTemplatesTypes
  } = useActTypesQuery()

  useEffect(() => {
    if (isSuccessActTemplatesTypes) {
      setState({ actTemplatesTypes: actTemplatesTypesData?.result })
    } else if (isErrorActTemplatesTypes) {
      console.error(actTemplatesTypesData)
      message.error('Ошибка получения списка типов актов')
    }
  }, [actTemplatesTypesData, isErrorActTemplatesTypes, isSuccessActTemplatesTypes])

  const {
    data: actTemplatesStatusesData,
    isError: isErrorActTemplatesStatuses,
    isSuccess: isSuccessActTemplatesStatuses
  } = useActTemplatesStatusesQuery({})

  useEffect(() => {
    if (isSuccessActTemplatesStatuses) {
      setState({
        actTemplatesStatuses: actTemplatesStatusesData?.result
      })
    } else if (isErrorActTemplatesStatuses) {
      console.error(actTemplatesStatusesData)
      message.error('Ошибка получения списка статусов актов')
    }
  }, [actTemplatesStatusesData, isErrorActTemplatesStatuses, isSuccessActTemplatesStatuses])

  const columns = [
    {
      title: 'Тип акта',
      dataIndex: 'actType',
      render: key => {
        const type = state.actTemplatesTypes.find(type => type.id === key)
        return <Text>{type?.name}</Text>
      },
      filters: state.actTemplatesTypes.map(type => ({ text: type.name, value: type.id })),
      sorter: true,
      sortOrder:
        state.actTemplatesList?.orderBy === 'actType'
          ? state.actTemplatesList?.orderDir + 'end'
          : false,
      filteredValue: state.actTemplatesList?.filter?.actType || null
    },
    {
      title: 'Дата редакции',
      dataIndex: 'createdAt',
      render: date => (date ? moment(date).format(AppConfig.formats.shortDate) : ''),
      sorter: true,
      sortOrder:
        state.actTemplatesList?.orderBy === 'createdAt'
          ? state.actTemplatesList?.orderDir + 'end'
          : false
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      // width: 50
      render: key => {
        const status = state.actTemplatesStatuses.find(status => status.id === key)
        return <Text type={AppConfig.assetStatusColors[status?.id]}>{status?.name}</Text>
      },
      sorter: true,
      sortOrder:
        state.actTemplatesList?.orderBy === 'status'
          ? state.actTemplatesList?.orderDir + 'end'
          : false,
      filters: state.actTemplatesStatuses.map(status => ({ text: status.name, value: status.id })),
      filteredValue: state.actTemplatesList?.filter?.status || null
    },
    {
      title: 'Версия редакции',
      // width: 100,
      dataIndex: 'version',
      sorter: true,
      sortOrder:
        state.actTemplatesList?.orderBy === 'version'
          ? state.actTemplatesList?.orderDir + 'end'
          : false
    }
  ]

  const pagination = useMemo(
    () => ({
      current: state.actTemplatesList.pageNumber,
      total: state.actTemplatesList.resultsTotal,
      pageSize: state.actTemplatesList.pageSize
    }),
    [
      state.actTemplatesList.pageNumber,
      state.actTemplatesList.resultsTotal,
      state.actTemplatesList.pageSize
    ]
  )

  const handleTableChange = useCallback((pagination, filters, sorter) => {
    const filterArrType = ['status', 'actType']
    let orderDir
    if (sorter?.order) {
      orderDir = sorter.order === 'ascend' ? 'asc' : 'desc'
    }
    const actTemplatesList = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      filter: Object.entries(filters || {}).reduce(
        (r, c) => ({
          ...r,
          [c[0]]: c[1] ? (filterArrType.includes(c[0]) ? c[1] : c[1][0]) : undefined
        }),
        {}
      ),
      orderBy: sorter.field,
      orderDir
    }
    setState({
      actTemplatesList
    })
    localStorage.setItem('actTemplatesRequest', JSON.stringify(actTemplatesList))
  }, [])

  const handleSelectRow = record => ({
    onClick: () => setSelectedId(record.id)
  })

  return (
    <div className="ActTemplates">
      <Spin spinning={isLoading} size="large">
        <Space style={{ marginBottom: 16 }}>
          <Button onClick={handleOpenNewModal}>Добавить шаблон</Button>
        </Space>
        <ConfigProvider locale={ruRu}>
          <Table
            columns={columns}
            loadind={isLoading}
            pagination={pagination}
            rowKey="id"
            dataSource={state.actTemplatesList?.result}
            onChange={handleTableChange}
            onRow={handleSelectRow}
          />
        </ConfigProvider>
      </Spin>

      {isModalOpen && <NewActTemplate onClose={handleCloseNewModal} visible={isModalOpen} />}
      {selectedId && <ActTemplatePreview id={selectedId} onClose={() => setSelectedId('')} />}
    </div>
  )
}
