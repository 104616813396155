import { Form, Select } from 'antd'
import React from 'react'
import { profileScreen } from '../../../translates'
import { useCities } from '../../../hooks'

const { Option } = Select

const CitySelectorFormItem = ({
  label = profileScreen.input.city.label,
  rules = undefined,
  requiredRule = true,
  messageRule = profileScreen.input.city.message,
  size = 'large',
  placeholder = profileScreen.input.city.placeholder,
  onChange = () => {},
  withOnDemandStores = false,
  hidden = false,
  disabledQuery = false
}) => {
  const { data: cities, isLoading } = useCities(
    {
      status: 'active',
      ...(withOnDemandStores && { withOnDemandStores })
    },
    { enabled: !disabledQuery }
  )
  return (
    <Form.Item
      name={['personalData', 'city']}
      label={label}
      rules={rules ?? [{ required: requiredRule, message: messageRule }]}
      hidden={hidden}
    >
      <Select
        showSearch
        size={size}
        placeholder={placeholder}
        onChange={onChange}
        loading={isLoading}
      >
        {cities.map(c => (
          <Option key={c.id} value={c.name}>
            {c.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}
export default CitySelectorFormItem
