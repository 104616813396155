import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'

import { AppConfig } from '../../../../AppConfig'
import { CreateActWithAssets } from './'
import { useGetAssetsActiveListQuery } from '../../../../api/react-query/assets'
import { Button, message } from 'antd'

function TransferAssetsModalButton({ partner }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [assets, setAssets] = useState([])

  const handleShowModal = () => setIsModalVisible(true)
  const handleHideModal = () => setIsModalVisible(false)

  const { data, isError, isSuccess, isLoading } = useGetAssetsActiveListQuery({
    enabled: !!isModalVisible
  })

  useEffect(() => {
    if (isSuccess) {
      setAssets(data?.result)
    } else if (isError) {
      console.error(data)
      message.error('Ошибка получения списка имущества')
    }
  }, [isSuccess, isError, data])
  return (
    <>
      <Button
        type="primary"
        onClick={handleShowModal}
        size="small"
        disabled={!partner?.profile?.assets?.allowTransfer}
      >
        Передать имущество
      </Button>
      <CreateActWithAssets
        visible={isModalVisible}
        loading={isLoading}
        onClose={handleHideModal}
        list={assets}
        partner={partner}
        actType={AppConfig.assetActTypes.transfer}
        title="Передать имущество"
        confirmTitle="Создать акт приема-передачи?"
        listTitle="Список передаваемого имущества:"
        content={`Акт приема-передачи имущества от ${moment().format(AppConfig.formats.shortDate)}`}
      />
    </>
  )
}

export default TransferAssetsModalButton
