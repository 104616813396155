import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import {
  Button,
  Col,
  // Radio,
  Row,
  Space,
  Typography,
  Divider,
  Alert,
  Modal,
  message,
  Tooltip,
  Input
} from 'antd'
import {
  selfEmployed,
  individual,
  // partnerSecurityStatusCodes,
  // partnerSecurityStatusesByKey,
  // partnerKinds,
  // partnerKindsByKey,
  partnerEditInfoStatusCodes,
  // cardStatusCodes,
  lmkStatusCodes,
  // AppConfig,
  fireBriefingStatusCodes,
  employmentTypeStatusCodes,
  seVerificationStatusCodes,
  partnerStatusCodes,
  requisitesStatusCodes,
  AppConfig
} from '../../../../AppConfig'
import './PartnerCommonData.css'
import { ConfigContext } from '../../../../context/configContext'
import {
  // useMutateAdminPartnerYoudoRestart,
  useMutateAdminPartnerVerified,
  useMutateAdminUpdateTerminateOffer,
  useMutateRestartSmz
} from '../../../../api/react-query/adminPartners'
import VerificationStatus from '../PartnerCommonDataItems/VerificationStatus'
import EditProfileInfoRequest from '../EditModals/EditProfileInfoRequest'
import SEButtons from '../SEVerification/SEButtons'
import { QuestionCircleOutlined } from '@ant-design/icons'
import Offers from '../Offers/Offers'
// import EditCardRequest from '../EditModals/EditCardRequest'
// import ReissueCardRequest from '../EditModals/ReissueCardRequest'
import UnlinkPartnerButton from '../../UnlinkPartnerButton/UnlinkPartnerButton'
// import IssueCardRequest from '../EditModals/IssueCardRequest'
import EditMedicalRequest from '../EditModals/EditMedicalRequest'
// import ReturnCardRequest from '../EditModals/ReturnCardRequest'
import EditFireBriefingRequest from '../EditModals/EditFireBriefingRequest'
// import ShopperStatus from '../PartnerCommonDataItems/ShopperStatus'
import EditEmploymentTypeRequest from '../EditModals/EditEmploymentTypeRequest'
import EditRequisitesRequest from '../EditModals/EditRequisitesRequest'
import TakePartnerButton from '../../TakePartnerButton/TakePartnerButton'
import ShopperStatusButton from '../PartnerCommonDataItems/ShopperStatusButton'
import ToBan from '../ActionButtonModals/ToBan'

const { Text } = Typography
const dateToString = d => (d ? moment(d).format('DD MMMM yyyy') : '')

function PartnerCommonData({
  profile,
  loading,
  onPartnerActions,
  // onChangeSecurityStatus,
  // onSubmitPartnerKind,
  partnerId,
  verifier,
  setPartner
}) {
  // const [isSecurityCheck, setIsSecurityCheck] = useState(false)
  // const [isModalOpen, setIsModalOpen] = useState(false)
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false)
  const [isTerminateOfferModalOpen, setIsTerminateOfferModalOpen] = useState(false)
  const [terminateReason, setTerminateReason] = useState('')
  // const [isChangePartnerKind, setIsChangePartnerKind] = useState(false)
  // const [partnerKind, setPartnerKind] = useState()
  const {
    user,
    isAdmin,
    isCoordinators,
    isCoordinator,
    isPartnerSupport,
    isSupervisor,
    isCityManager,
    isSecurity,
    isDivisionalInstructor
  } = useContext(ConfigContext)
  // const {
  //   mutate: mutatePartnerYoudoRestart,
  //   isError: isErrorPartnerYoudoRestart,
  //   isLoading: isLoadingPartnerYoudoRestart,
  //   isSuccess: isSuccessPartnerYoudoRestart
  // } = useMutateAdminPartnerYoudoRestart()

  // useEffect(() => {
  //   if (isErrorPartnerYoudoRestart) {
  //     message.error('Ошибка повторного отправления партнера в Юду.')
  //   }
  // }, [isErrorPartnerYoudoRestart])
  // useEffect(() => {
  //   if (isSuccessPartnerYoudoRestart) {
  //     message.success('Партнер повторно отправлен в Юду.')
  //     setIsModalOpen(false)
  //   }
  // }, [isSuccessPartnerYoudoRestart])

  const {
    mutate: mutateRestartTaxStatus,
    isSuccess: isSuccessTaxStatus,
    isError: isErrorTaxStatus,
    isLoading: isLoadingTaxStatus
  } = useMutateRestartSmz()

  useEffect(() => {
    if (isSuccessTaxStatus) {
      message.success('Партнер повторно отправлен в self-fee')
    } else if (isErrorTaxStatus) {
      message.error('Ошибка запроса в ФНС')
    }
  }, [isSuccessTaxStatus, isErrorTaxStatus])

  const {
    mutate: mutatePartnerVerified,
    isError: isErrorPartnerVerified,
    isLoading: isLoadingPartnerVerified,
    isSuccess: isSuccessPartnerVerified
  } = useMutateAdminPartnerVerified()

  const {
    mutate: mutateTerminateOffer,
    data: dataTerminateOffer,
    isError: isErrorTerminateOffer,
    isLoading: isLoadingTerminateOffer,
    isSuccess: isSuccessTerminateOffer
  } = useMutateAdminUpdateTerminateOffer()

  useEffect(() => {
    if (isErrorPartnerVerified) {
      message.error('Ошибка изменения статуса партнера.')
    }
  }, [isErrorPartnerVerified])
  useEffect(() => {
    if (isSuccessPartnerVerified) {
      message.success('Статус партнера успешно изменен.')
      // setIsModalOpen(false)
    }
  }, [isSuccessPartnerVerified])

  useEffect(() => {
    if (isErrorTerminateOffer) {
      message.error('Ошибка расторжения оферты партнера.')
    }
  }, [isErrorTerminateOffer])

  useEffect(() => {
    if (isSuccessTerminateOffer) {
      if (dataTerminateOffer?.errorMessage) {
        return message.error(dataTerminateOffer?.errorMessage)
      }
      message.success('Оферта партнера успешно расторгнута.')
    }
  }, [dataTerminateOffer, isSuccessTerminateOffer])

  // const handleYoudoRestart = () => mutatePartnerYoudoRestart({ id: partnerId })
  const handleTaxStatusRestart = () => mutateRestartTaxStatus({ id: partnerId })
  const handleVerified = () => mutatePartnerVerified({ id: partnerId })
  const handleBlockAction = (type, action) => onPartnerActions(type, action)
  const handleTerminateOffer = () =>
    mutateTerminateOffer({ id: partnerId, reason: terminateReason })

  // const handleOpenBlockModal = () => setIsModalOpen(true)
  // const handleCloseBlockModal = () => setIsModalOpen(false)
  const handleCloseVerifyModal = () => setIsVerifyModalOpen(false)
  const handleCloseTerminateOfferModal = () => setIsTerminateOfferModalOpen(false)

  // const handleSubmitPartnerKind = () => {
  //   onSubmitPartnerKind({ id: partnerId, kind: partnerKind }, {
  //     onSuccess: () => {
  //       setIsChangePartnerKind(false)
  //     }
  //   })
  // }
  // const handleChangeSecurityStatus = decision => {
  //   onChangeSecurityStatus({ id: partnerId, decision }, {
  //     onSuccess: () => {
  //       setIsSecurityCheck(false)
  //     }
  //   })
  // }

  const payoutSuspended = !!profile?.payoutSuspended

  const showErrorBlock = () => {
    const errors = [
      profile?.personalData?.statusReason,
      profile?.bankDetails?.statusReason,
      profile?.innDetails?.statusReason,
      profile?.passportPhoto1?.statusReason,
      profile?.passportPhoto2?.statusReason,
      profile?.passportPhoto3?.statusReason,
      profile?.passportPhotoExtraReg?.statusReason,
      profile?.passportPhotoTmpReg?.statusReason,
      profile?.personalData?.legalForm === selfEmployed && profile?.mnregPhoto?.statusReason,
      profile?.personalData?.legalForm === individual && profile?.egripPhoto?.statusReason,
      profile?.trusteeAgreement?.statusReason,
      profile?.trusteeConfirmation?.statusReason,
      profile?.legalCapacityConfirmation?.statusReason,
      profile?.trusteePassport?.statusReason,
      profile?.personalData?.vaccinated === true && profile?.vaccinacionPhoto?.statusReason
    ].filter(Boolean)

    if (!errors.length) return null
    const description = errors.map((error, index) => error && <li key={index + error}>{error}</li>)
    return (
      <Alert
        message={<Text type="secondary">Причины отказа</Text>}
        description={<ul>{description}</ul>}
        type="error"
      />
    )
  }

  // const renderChangeKind = () => (
  //   <Space style={{ width: '100%' }}>
  //     <Radio.Group onChange={e => setPartnerKind(e.target.value)} value={partnerKind}>
  //       <Radio value={partnerKinds.new}>
  //         {partnerKindsByKey[partnerKinds.new]}
  //       </Radio>
  //       <Radio value={partnerKinds.old}>
  //         {partnerKindsByKey[partnerKinds.old]}
  //       </Radio>
  //     </Radio.Group>
  //     <Button
  //       type='primary'
  //       loading={loading}
  //       disabled={!partnerKind}
  //       onClick={handleSubmitPartnerKind}
  //     >
  //       Сохранить
  //     </Button>
  //     {profile?.partnerKind?.value !== partnerKinds.none && (
  //       <Button
  //         className='button-link'
  //         type='link'
  //         onClick={() => setIsChangePartnerKind(false)}
  //       >
  //         отмена
  //       </Button>
  //     )}
  //   </Space>
  // )
  const firstCols = [
    {
      label: 'Статус партнера:',
      value: (
        <>
          {profile?.status}
          {profile?.statusCode === partnerStatusCodes.loginNotFound && profile?.declineReason && (
            <Tooltip placement="top" title={profile?.declineReason}>
              <QuestionCircleOutlined style={{ marginLeft: 4, marginTop: 4 }} />
            </Tooltip>
          )}
        </>
      )
    },
    {
      label: 'Дата регистрации:',
      value: dateToString(profile?.registrationDate)
    },
    {
      label: 'Дата начала соглашения:',
      value: user?.permissions?.includes('partner.offers:list') ? (
        <Offers partnerId={partnerId} linkText={dateToString(profile?.agreementStartDate)} />
      ) : (
        dateToString(profile?.agreementStartDate)
      )
    },
    {
      label: 'Дата окончания соглашения:',
      value: profile?.agreementExpireDate
        ? dateToString(profile?.agreementExpireDate)
        : profile?.agreementStartDate
          ? 'Бессрочно'
          : ''
    }
  ]
  const secondCol = [
    {
      label: 'Последнее изменение:',
      value: dateToString(profile?.lastModifiedAt)
    },
    {
      label: 'Пользователь:',
      value: profile?.lastModifiedBy
    }
  ]
  if (profile?.allowVerify) {
    secondCol.unshift({
      label: (
        <Button
          disabled={isLoadingPartnerVerified}
          className="button-link"
          type="link"
          onClick={() => setIsVerifyModalOpen(true)}
        >
          изменить
        </Button>
      )
    })
  }
  if (verifier?.name) {
    secondCol.push({
      label: 'В работе:',
      value: (
        <>
          {verifier?.name}
          <UnlinkPartnerButton partnerId={partnerId} />
        </>
      )
    })
  }
  if (isSecurity) {
    secondCol.push({
      label: (
        <>
          <TakePartnerButton partnerId={partnerId} profile={profile} verifier={verifier} />
        </>
      ),
      value: ''
    })
  }

  return (
    <Space direction="vertical" className="spin">
      {firstCols.map((col, index) => (
        <Row key={col.label + index}>
          <Col span={6}>
            <Text type="secondary">{col.label}</Text>
          </Col>
          <Col span={6}>
            <Text>{col.value}</Text>
          </Col>
          {secondCol?.[index] && (
            <>
              <Col span={6}>
                <Text type="secondary">{secondCol[index].label}</Text>
              </Col>
              <Col span={6}>
                <Text>{secondCol[index].value}</Text>
              </Col>
            </>
          )}
        </Row>
      ))}
      <Divider className="small" />

      {/* <Row align='middle'> */}
      {/*  <Col span={6}><Text type='secondary'>Статус в Юду:</Text></Col> */}
      {/*  <Col span={5}> */}
      {/*    <Text> */}
      {/*      {profile?.youdoAccount?.status} */}
      {/*    </Text> */}
      {/*  </Col> */}
      {/*  {profile?.youdoAccount?.allowRestart && */}
      {/*    <Col span={6}> */}
      {/*      <Button */}
      {/*        disabled={isLoadingPartnerYoudoRestart} */}
      {/*        className='button-link' */}
      {/*        type='link' */}
      {/*        onClick={handleYoudoRestart} */}
      {/*      > */}
      {/*        отправить повторно */}
      {/*      </Button> */}
      {/*    </Col>} */}
      {/* </Row> */}
      <Row align="middle">
        <Col span={6}>
          <Text type="secondary">Статус ФНС:</Text>
        </Col>
        <Col span={5}>
          <Text>
            {profile?.smz?.status}
            {profile?.smz?.statusReason && (
              <Tooltip placement="top" title={profile?.smz?.statusReason}>
                <QuestionCircleOutlined className="ml-2 mr-3" />
              </Tooltip>
            )}
          </Text>
        </Col>
        {profile?.smz?.allowRestart && (
          <Col span={6}>
            <Button
              disabled={isLoadingTaxStatus}
              className="button-link"
              type="link"
              onClick={handleTaxStatusRestart}
            >
              отправить повторно
            </Button>
          </Col>
        )}
      </Row>
      {/* <Row align='middle'> */}
      {/*  <Col span={6}><Text type='secondary'>Статус проверки СБ:</Text></Col> */}
      {/*  <Col span={5}> */}
      {/*    <Text>{profile?.securityCheck?.status}</Text> */}
      {/*  </Col> */}
      {/*  {isSecurity && profile?.securityCheck?.allowReview && */}
      {/*    <> */}
      {/*      {!isSecurityCheck */}
      {/*        ? ( */}
      {/*          <Col span={6}> */}
      {/*            <Button */}
      {/*              className='button-link' */}
      {/*              type='link' */}
      {/*              onClick={() => setIsSecurityCheck(true)} */}
      {/*            >изменить */}
      {/*            </Button> */}
      {/*          </Col> */}
      {/*        ) : ( */}
      {/*          <Col span={13}> */}
      {/*            <Row> */}
      {/*              {profile?.securityCheck?.status !== partnerSecurityStatusesByKey[partnerSecurityStatusCodes.approved] && ( */}
      {/*                <Button */}
      {/*                  style={{ width: '110px', 'margin-right': '10px' }} */}
      {/*                  disabled={loading} */}
      {/*                  type='primary' */}
      {/*                  className='ant-btn-success mr-3' */}
      {/*                  onClick={() => { */}
      {/*                    handleChangeSecurityStatus(partnerSecurityStatusCodes.approved) */}
      {/*                  }} */}
      {/*                >Принято СБ */}
      {/*                </Button> */}
      {/*              )} */}
      {/*              {profile?.securityCheck?.status !== partnerSecurityStatusesByKey[partnerSecurityStatusCodes.rejected] && ( */}
      {/*                <Button */}
      {/*                  style={{ width: '110px' }} */}
      {/*                  className='mr-3' */}
      {/*                  disabled={loading} */}
      {/*                  danger */}
      {/*                  type='primary' */}
      {/*                  onClick={handleOpenBlockModal} */}
      {/*                >Отказ СБ */}
      {/*                </Button> */}
      {/*              )} */}
      {/*              <Button */}
      {/*                className='button-link' */}
      {/*                type='link' */}
      {/*                onClick={() => setIsSecurityCheck(false)} */}
      {/*              >отмена */}
      {/*              </Button> */}
      {/*            </Row> */}
      {/*          </Col> */}
      {/*        )} */}
      {/*    </>} */}
      {/* </Row> */}
      {/* <Col className={isChangePartnerKind && 'kind-edit'}> */}
      {/*  <Row align='middle'> */}
      {/*    <Col span={6}><Text type='secondary'>Тип партнера:</Text></Col> */}
      {/*    {profile?.partnerKind?.value === partnerKinds.none ? ( */}
      {/*      <Col> */}
      {/*        {profile?.partnerKind?.allowEdit ? renderChangeKind() : ( */}
      {/*          <Text> */}
      {/*            {partnerKindsByKey[profile?.partnerKind?.value]} */}
      {/*          </Text> */}
      {/*        )} */}
      {/*      </Col> */}
      {/*    ) : ( */}
      {/*      <> */}
      {/*        <Col span={5}> */}
      {/*          <Text> */}
      {/*            {partnerKindsByKey[profile?.partnerKind?.value]} */}
      {/*          </Text> */}
      {/*        </Col> */}
      {/*        {!isChangePartnerKind && profile?.partnerKind?.allowEdit && ( */}
      {/*          <Col span={6}> */}
      {/*            <Button */}
      {/*              className='button-link' */}
      {/*              type='link' */}
      {/*              onClick={() => setIsChangePartnerKind(true)} */}
      {/*            >изменить */}
      {/*            </Button> */}
      {/*          </Col> */}
      {/*        )} */}
      {/*      </> */}
      {/*    )} */}
      {/*  </Row> */}
      {/*  <Row align='middle' className='padding-row'> */}
      {/*    {isChangePartnerKind && ( */}
      {/*      <Col offset={6}> */}
      {/*        {renderChangeKind()} */}
      {/*      </Col> */}
      {/*    )} */}
      {/*  </Row> */}
      {/* </Col> */}
      {showErrorBlock()}
      {profile?.shopperAccount?.login && (
        <Row>
          <Col span={6}>
            <Text type="secondary">Логин:</Text>
          </Col>
          <Col span={6}>
            <Text>{profile?.shopperAccount?.login}</Text>
          </Col>
        </Row>
      )}
      <>
        <Divider />
        <Row className="partner-bank-data">
          <Col span={6}>
            <Text type="secondary">Оферта</Text>
          </Col>
          <Col span={6}>
            <Text>{profile?.offerActive ? 'Активна' : 'Не активна'}</Text>
          </Col>
          {profile?.allowTerminate && (
            <Col span={6}>
              <Button
                disabled={loading || isLoadingTerminateOffer}
                danger
                type="primary"
                onClick={() => setIsTerminateOfferModalOpen(true)}
              >
                Расторгнуть оферту
              </Button>
            </Col>
          )}
        </Row>

        {profile?.allowManagePayout && (
          <Row className="partner-bank-data">
            <Col span={6}>
              <Text type="secondary">Выплаты</Text>
            </Col>
            <Col span={6}>
              <Text>{payoutSuspended ? 'Приостановлены' : 'Да'}</Text>
            </Col>
            <Col span={6}>
              <Button
                disabled={loading}
                type="primary"
                className={`w-150 ${payoutSuspended && 'ant-btn-success'}`}
                danger={!payoutSuspended}
                onClick={() => {
                  handleBlockAction('payout', payoutSuspended ? 'release' : 'suspend')
                }}
              >
                {payoutSuspended ? 'Возобновить' : 'Приостановить'}
              </Button>
            </Col>
          </Row>
        )}
        {(([partnerEditInfoStatusCodes.filled, partnerEditInfoStatusCodes.declined].includes(
          profile?.profileFormStatus
        ) &&
          isCoordinator) ||
          ([partnerEditInfoStatusCodes.approved].includes(profile?.profileFormStatus) &&
            isSecurity) ||
          ([
            partnerEditInfoStatusCodes.filled,
            partnerEditInfoStatusCodes.approved,
            partnerEditInfoStatusCodes.declined
          ].includes(profile?.profileFormStatus) &&
            isAdmin)) &&
          profile?.statusCode === partnerStatusCodes.loginIssued && (
            <Row className="partner-bank-data">
              <Col span={6}>
                <Text type="secondary">Корректировка анкеты</Text>
              </Col>
              <Col span={6}> </Col>
              <Col span={6}>
                <EditProfileInfoRequest
                  disabled={loading}
                  profile={profile}
                  partnerId={partnerId}
                  viewOnly={
                    profile?.profileFormStatus === partnerEditInfoStatusCodes.declined ||
                    isPartnerSupport
                  }
                  isNoEdit={isSecurity}
                  setPartner={setPartner}
                />
              </Col>
            </Row>
          )}
        {[
          employmentTypeStatusCodes.verification,
          employmentTypeStatusCodes.declined,
          employmentTypeStatusCodes.declinedSmz
        ].includes(profile?.employmentTypeFormStatus) &&
          (isCoordinators || isCoordinator || isAdmin || isPartnerSupport) && (
            <Row className="partner-bank-data">
              <Col span={6}>
                <Text type="secondary">Смена формы занятости</Text>
              </Col>
              <Col span={6}> </Col>
              <Col span={6}>
                <EditEmploymentTypeRequest
                  disabled={loading}
                  profile={profile}
                  partnerId={partnerId}
                  viewOnly={
                    [
                      employmentTypeStatusCodes.declined,
                      employmentTypeStatusCodes.declinedSmz
                    ].includes(profile?.employmentTypeFormStatus) || isPartnerSupport
                  }
                  setPartner={setPartner}
                />
              </Col>
            </Row>
          )}
        {[lmkStatusCodes.verification, lmkStatusCodes.declined].includes(profile?.lmkFormStatus) &&
          (isSupervisor ||
            isAdmin ||
            isPartnerSupport ||
            isCityManager ||
            isDivisionalInstructor) && (
            <Row className="partner-bank-data">
              <Col span={6}>
                <Text type="secondary">Корректировка ЛМК</Text>
              </Col>
              <Col span={6}> </Col>
              <Col span={6}>
                <EditMedicalRequest
                  disabled={loading}
                  profile={profile}
                  partnerId={partnerId}
                  viewOnly={profile?.lmkFormStatus === lmkStatusCodes.declined || isPartnerSupport}
                  setPartner={setPartner}
                />
              </Col>
            </Row>
          )}
        {[
          fireBriefingStatusCodes.verification,
          fireBriefingStatusCodes.declined,
          fireBriefingStatusCodes.accepted
        ].includes(profile?.fireBriefingFormStatus) &&
          (isSupervisor || isAdmin || isCityManager || isPartnerSupport) && (
            <Row className="partner-bank-data">
              <Col span={6}>
                <Text type="secondary">Пожарный инструктаж</Text>
              </Col>
              <Col span={6}>
                {profile?.fireBriefingFormStatus === fireBriefingStatusCodes.accepted
                  ? profile?.fireBriefingDetails?.briefingDate
                  : ' '}
              </Col>
              <Col span={6}>
                {[fireBriefingStatusCodes.verification, fireBriefingStatusCodes.declined].includes(
                  profile?.fireBriefingFormStatus
                ) && (
                  <EditFireBriefingRequest
                    disabled={loading}
                    profile={profile}
                    partnerId={partnerId}
                    viewOnly={
                      profile?.fireBriefingFormStatus === fireBriefingStatusCodes.declined ||
                      isPartnerSupport
                    }
                  />
                )}
              </Col>
            </Row>
          )}
        {profile?.seVerification && (
          <Row className="partner-bank-data">
            <Col span={6}>
              <Text type="secondary">Проверка в 1С</Text>
            </Col>
            <Col span={6}>
              {profile.seVerification.status}
              {profile.seVerification.statusCode !== seVerificationStatusCodes.allowed &&
                profile.seVerification.statusReason && (
                  <Tooltip placement="top" title={profile.seVerification.statusReason}>
                    <QuestionCircleOutlined className="ml-2 mr-3" />
                  </Tooltip>
                )}
            </Col>
            <Col span={12}>
              <SEButtons verification={profile.seVerification} partnerId={partnerId} />
            </Col>
          </Row>
        )}
        {/* <Row className='partner-bank-data'> */}
        {/*  <Col span={6}> */}
        {/*    <Text type='secondary'>Банковская карта</Text> */}
        {/*  </Col> */}
        {/*  <Col span={6}> */}
        {/*    {profile?.cardIssueStatus} */}
        {/*    {profile?.cardIssueStatusCode === AppConfig.cardIssueStatusCodes.error && ( */}
        {/*      <Tooltip placement='top' title={profile?.cardIssueStatusReason || 'Ошибка не указана'} trigger='click' color='white' overlayInnerStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}> */}
        {/*        <QuestionCircleOutlined className='ml-2 mr-3' /> */}
        {/*      </Tooltip> */}
        {/*    )} */}
        {/*  </Col> */}
        {/*  <Col span={12}> */}
        {/*    {profile?.cardFormCondition && ( */}
        {/*      <div className='mr-3'> */}
        {/*        <QuestionCircleOutlined className='ml-2 mr-1' /> {profile?.cardFormCondition} */}
        {/*      </div> */}
        {/*    )} */}
        {/*    {(isAdmin || isCoordinators || isCoordinator || isPartnerSupport) && ( */}
        {/*      <> */}
        {/*        {[cardStatusCodes.verification, cardStatusCodes.declined].includes(profile?.cardFormStatus) && ( */}
        {/*          <EditCardRequest */}
        {/*            disabled={loading} */}
        {/*            profile={profile} */}
        {/*            partnerId={partnerId} */}
        {/*            viewOnly={profile?.cardFormStatus === cardStatusCodes.declined || isPartnerSupport} */}
        {/*            setPartner={setPartner} */}
        {/*          /> */}
        {/*        )} */}
        {/*        {!isPartnerSupport && ( */}
        {/*          <> */}
        {/*            {profile?.allowCardReissue && <ReissueCardRequest partnerId={partnerId} />} */}
        {/*            {[cardStatusCodes.canCreateDeclined].includes(profile?.cardFormStatus) && ( */}
        {/*              <ReturnCardRequest partnerId={partnerId} /> */}
        {/*            )} */}
        {/*            {profile?.allowCardIssue && <IssueCardRequest partnerId={partnerId} />} */}
        {/*          </> */}
        {/*        )} */}
        {/*      </> */}
        {/*    )} */}
        {/*  </Col> */}
        {/* </Row> */}
        {[requisitesStatusCodes.verification, requisitesStatusCodes.declined].includes(
          profile?.bankRequisiteFormStatus
        ) &&
          (isSupervisor ||
            isAdmin ||
            isPartnerSupport ||
            isCityManager ||
            isDivisionalInstructor ||
            isCoordinators ||
            isCoordinator) && (
            <Row className="partner-bank-data">
              <Col span={6}>
                <Text type="secondary">Банковские реквизиты</Text>
              </Col>
              <Col span={6}> </Col>
              <Col span={6}>
                <EditRequisitesRequest
                  disabled={loading}
                  profile={profile}
                  partnerId={partnerId}
                  viewOnly={
                    profile?.bankRequisiteFormStatus === requisitesStatusCodes.declined ||
                    isPartnerSupport
                  }
                  setPartner={setPartner}
                />
              </Col>
            </Row>
          )}
      </>
      {(profile?.shopperAccount?.statusDescription || profile?.shopperAccount?.allowEdit) && (
        <Divider className="small" />
      )}
      {profile?.shopperAccount?.statusDescription && (
        <Row>
          <Text>{profile?.shopperAccount?.statusDescription}</Text>
        </Row>
      )}

      {/* {profile?.shopperAccount?.allowEdit && ( */}
      {/*  <ShopperStatus */}
      {/*    profile={profile} */}
      {/*    loading={loading} */}
      {/*    partnerId={partnerId} */}
      {/*    setPartnerData={setPartner} */}
      {/*  /> */}
      {/* )} */}

      <Row>
        {profile?.shopperAccount?.allowEdit && (
          <ShopperStatusButton
            profile={profile}
            loading={loading}
            partnerId={partnerId}
            setPartnerData={setPartner}
            statusType={AppConfig.shopperStatuses.verified}
          />
        )}

        <ToBan allowBan={profile?.allowBan} />

        {profile?.shopperAccount?.allowEdit && (
          <ShopperStatusButton
            profile={profile}
            loading={loading}
            partnerId={partnerId}
            setPartnerData={setPartner}
            statusType={AppConfig.shopperStatuses.notfound}
          />
        )}
      </Row>

      {profile?.verificationStatus?.allowEdit && <Divider className="small" />}
      <VerificationStatus profile={profile} />
      {/* <Modal */}
      {/*  visible={isModalOpen} */}
      {/*  title='Отказ СБ партнеру' */}
      {/*  onCancel={handleCloseBlockModal} */}
      {/*  okText='Отказ СБ' */}
      {/*  cancelText='Отменить' */}
      {/*  okButtonProps={{ className: 'green' }} */}
      {/*  onOk={() => { */}
      {/*    handleChangeSecurityStatus(partnerSecurityStatusCodes.rejected) */}
      {/*    handleCloseBlockModal() */}
      {/*  }} */}
      {/* > */}
      {/*  <Text>После нажатия "Отказ СБ" у партнера будет:<br /> */}
      {/*    1. Анулирована принятая оферта (соглашение)<br /> */}
      {/*    2. Запрещено продление оферты<br /> */}
      {/*    3. Запрещены выплаты */}
      {/*  </Text> */}
      {/* </Modal> */}
      <Modal
        visible={isVerifyModalOpen}
        title="Изменение статуса партнеру"
        onCancel={handleCloseVerifyModal}
        okText="Изменить"
        cancelText="Отменить"
        okButtonProps={{ className: 'green' }}
        onOk={() => {
          handleVerified()
          handleCloseVerifyModal()
        }}
      >
        <Text>Статус партнера будет изменен на &quot;Документы проверены&quot;</Text>
      </Modal>
      <Modal
        visible={isTerminateOfferModalOpen}
        title="Расторжение оферты"
        onCancel={handleCloseTerminateOfferModal}
        okText="Расторгнуть"
        cancelText="Отменить"
        okButtonProps={{ className: 'green' }}
        onOk={() => {
          handleTerminateOffer()
          handleCloseTerminateOfferModal()
        }}
      >
        <Text>
          У партнера будет расторгнута оферта. Номер телефона Партнера станет доступным для
          повторной регистрации на Портале.
        </Text>

        <Row style={{ marginTop: '1rem' }}>
          <Col span={4}>Причина:</Col>
          <Col span={20}>
            <Input
              value={terminateReason}
              onChange={e => {
                setTerminateReason(e.target.value)
              }}
              placeholder="Введите причину"
            />
          </Col>
        </Row>
      </Modal>
    </Space>
  )
}

export default PartnerCommonData
