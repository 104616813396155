import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, Divider, message, Modal, Row, Spin, Table, Typography } from 'antd'
import Approval from '../../Approval/Approval'
import PartnerDocuments from '../Sections/PartnerDocuments'
import EditableTable from '../../../Common/EditableTable/EditableTable'
import { errors, profileScreen } from '../../../../translates'
import {
  useMutateAdminAcceptLmkForm,
  useMutateAdminDeclineLmkForm,
  useQueryAdminLmkForm
} from '../../../../api/react-query/adminPartners'
import { ACCEPT, ACCEPTED, NONE } from '../../../../Constants'
import {
  arraySortByArray,
  createApiDataFromForm,
  createFormDataFromApi,
  flattenObject,
  restoreFlattenObject,
  validateDate1BeforeDate2,
  validateDateNotFuture
} from '../../../../helper'
import './EditModals.css'
import moment from 'moment-timezone'
import { AppConfig } from '../../../../AppConfig'

const { Text } = Typography
const columns = [
  {
    title: 'Название поля',
    dataIndex: 'fieldName',
    width: 300
  },
  {
    title: 'Значение',
    dataIndex: 'newValue',
    editable: true
  }
]
const documents = ['lmkMainPhoto', 'lmkAllowancePhoto', 'lmkAttestationPhoto']

function EditMedicalRequest({ profile, setPartner, disabled, partnerId, viewOnly = false }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [profileData, setProfileData] = useState([])
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})
  const [fieldsHasErrors, setFieldsHasErrors] = useState([])
  const [closeModalAfterAccept, setCloseModalAfterAccept] = useState(false)

  const { data: lmkFormData, isFetching: isLoadingLmkFormData } = useQueryAdminLmkForm(partnerId, {
    enabled: isModalVisible,
    refetchOnWindowFocus: false,
    staleTime: 0,
    cacheTime: 0
  })

  const {
    mutate: acceptRequest,
    data: acceptRequestData,
    isLoading: acceptRequestLoading,
    isSuccess: acceptRequestSuccess,
    isError: acceptRequestError
  } = useMutateAdminAcceptLmkForm()

  const {
    mutate: declineRequest,
    data: declineRequestData,
    isLoading: declineRequestLoading,
    isSuccess: declineRequestSuccess,
    isError: declineRequestError
  } = useMutateAdminDeclineLmkForm()

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)

  const prepareAcceptRequestData = newValues => {
    const inputs = profileScreen.input
    newValues = restoreFlattenObject(newValues)
    const data = createApiDataFromForm({
      lmkDetails: Object.fromEntries(
        Object.entries({
          ...newValues
        }).filter(([, value]) => value !== undefined)
      )
    })?.lmkDetails
    return {
      data: {
        [inputs.medicalBookNumber.name]: data[inputs.medicalBookNumber.name],
        [inputs.medicalexaminationDate.name]: data[inputs.medicalexaminationDate.name],
        [inputs.medicalattestationDate.name]: data[inputs.medicalattestationDate.name],
        [inputs.lmkMainPhoto.name]: data[inputs.lmkMainPhoto.name],
        [inputs.lmkAllowancePhoto.name]: data[inputs.lmkAllowancePhoto.name],
        [inputs.lmkAttestationPhoto.name]: data[inputs.lmkAttestationPhoto.name],
        registrationAddress: Object.fromEntries(
          Object.entries({
            ...newValues?.registrationAddress
          }).map(([key, value]) => (value === null ? [key, ''] : [key, value]))
        )
      }
    }
  }

  const handleCommit = ({ outcome, comment: reason }) => {
    setBackendFieldsErrors({})
    if (outcome === ACCEPT) {
      const newValues = Object.fromEntries(profileData.map(item => [item.key, item.newValue]))
      setCloseModalAfterAccept(true)
      acceptRequest({
        id: partnerId,
        data: prepareAcceptRequestData({
          ...newValues,
          lmkMainPhoto: lmkFormData?.form?.lmkMainPhoto?.fileId,
          lmkAllowancePhoto: lmkFormData?.form?.lmkAllowancePhoto?.fileId,
          lmkAttestationPhoto: lmkFormData?.form?.lmkAttestationPhoto?.fileId
        })
      })
    } else {
      declineRequest({ id: partnerId, reason })
    }
  }
  const flattenProfileData = useMemo(
    () => flattenObject(createFormDataFromApi({ lmkDetails: profile?.lmkDetails })?.lmkDetails),
    [profile]
  )
  useEffect(() => {
    const data = []
    const formData = { ...(lmkFormData?.form || {}) }
    delete formData.lmkMainPhoto
    delete formData.lmkAllowancePhoto
    delete formData.lmkAttestationPhoto
    delete formData.status
    delete formData.statusCode
    delete formData.statusReason
    const flattenData = flattenObject(createFormDataFromApi({ lmkDetails: formData })?.lmkDetails)

    const medicalKeysList = [
      profileScreen.input.medicalBookNumber.name,
      profileScreen.input.medicalexaminationDate.name,
      profileScreen.input.medicalattestationDate.name,
      `registrationAddress.${profileScreen.input.registrationAddressCountry.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressZipCode.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressRegion.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressDistrict.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressCity.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressSettlement.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressStreet.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressHouse.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressBuilding.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressApartment.name}`
    ]

    const requiredList = [
      profileScreen.input.medicalBookNumber.name,
      profileScreen.input.medicalexaminationDate.name,
      profileScreen.input.medicalattestationDate.name,
      `registrationAddress.${profileScreen.input.registrationAddressCountry.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressZipCode.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressRegion.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressStreet.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressHouse.name}`
    ]

    const keys = arraySortByArray(Object.keys(flattenData), medicalKeysList)
    keys
      ?.filter(key => medicalKeysList.includes(key))
      .map(key => {
        const fieldName = key.startsWith('registrationAddress')
          ? profileScreen.input[key.replace('.', '')]?.label
          : profileScreen.input[`medical${key.replace('medical', '')}`]?.label
        data.push({
          key,
          required: requiredList.includes(key),
          fieldName,
          newValue: flattenData?.[key],
          isDifferentFromProfile: flattenProfileData?.[key] !== flattenData?.[key],
          specialValidation: [
            ...([
              profileScreen.input.medicalexaminationDate.name,
              profileScreen.input.medicalattestationDate.name
            ].includes(key)
              ? [
                  {
                    message: errors.oldDate,
                    validator: (rule, value) =>
                      validateDate1BeforeDate2({
                        rule,
                        date1: moment()
                          .subtract(
                            profileScreen.input[`medical${key.replace('medical', '')}`]?.yearExpire,
                            'year'
                          )
                          .format(AppConfig.formats.shortDate),
                        date2: value
                      })
                  },
                  {
                    message: errors.futureDate,
                    validator: (rule, value) => validateDateNotFuture({ rule, date: value })
                  }
                ]
              : [])
          ]
        })
      })
    setProfileData(data)
  }, [lmkFormData?.form, flattenProfileData, profile])

  useEffect(() => {
    if (acceptRequestError) {
      message.error('Не удалось принять запрос.')
    }
  }, [acceptRequestError])

  useEffect(() => {
    if (acceptRequestSuccess) {
      if (acceptRequestData?.errorMessage) {
        return message.error(acceptRequestData?.errorMessage || 'Не удалось принять запрос.')
      }
      if (closeModalAfterAccept) {
        handleCancel()
        setCloseModalAfterAccept(false)
      }
      message.success('Запрос на изменение данных принят.')
    }
  }, [acceptRequestData, acceptRequestSuccess])

  useEffect(() => {
    if (declineRequestError) {
      message.error('Не удалось отклонить запрос.')
    }
  }, [declineRequestError])

  useEffect(() => {
    if (declineRequestSuccess) {
      if (declineRequestData?.errorMessage) {
        return message.error(declineRequestData?.errorMessage || 'Не удалось отклонить запрос.')
      }
      handleCancel()
      message.success('Запрос на изменение данных отклонен.')
    }
  }, [declineRequestData, declineRequestSuccess])

  const tableProps = useMemo(
    () => ({
      backendFieldsErrors,
      setHasErrors: setFieldsHasErrors,
      columns,
      dataSource: profileData,
      setDataSource: setProfileData,
      pagination: false
    }),
    [backendFieldsErrors, profileData, viewOnly]
  )

  return (
    <div>
      <Button ghost type="primary" className="w-150" disabled={disabled} onClick={showModal}>
        {viewOnly ? 'Посмотреть' : 'Проверить'}
      </Button>
      <Modal
        width={1200}
        style={{ top: 20 }}
        title={
          <Row gutter={[12, 6]} align="middle">
            <Col className="mr-3">
              <b>Данные о личной медицинской книжке</b>
            </Col>
          </Row>
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="assetsModalForm"
      >
        <Spin spinning={isLoadingLmkFormData || acceptRequestLoading || declineRequestLoading}>
          {lmkFormData?.form?.statusReason && (
            <Alert
              className="mb-3"
              message={<Text type="secondary">Причины отказа</Text>}
              description={lmkFormData?.form.statusReason}
              type="error"
            />
          )}
          {viewOnly ? <Table {...tableProps} /> : <EditableTable {...tableProps} />}
          <Divider style={{ border: 0 }} />
          <PartnerDocuments
            partnerId={partnerId}
            documents={documents}
            setPartner={setPartner}
            profile={lmkFormData?.form}
            getDocumentStatus={d =>
              profile?.[d]?.fileId === lmkFormData?.form?.[d]?.fileId ? ACCEPTED : NONE
            }
            getDocumentButton={() => null}
            correctForm={AppConfig.updateDocFormNames.lmkForm}
          />
          <Divider />
          {viewOnly ? (
            <Button type="primary" onClick={handleCancel}>
              Закрыть
            </Button>
          ) : (
            <Approval
              onCommit={handleCommit}
              onCancel={handleCancel}
              multiline
              disabled={fieldsHasErrors?.length}
            />
          )}
        </Spin>
      </Modal>
    </div>
  )
}

export default EditMedicalRequest
