import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu } from 'antd'

import { routes } from '../../../routes'
import './LeftMenuStyle.css'

export default function LeftMenu({ authRole, userRole }) {
  const { pathname } = useLocation()

  return (
    <Menu
      className="left-menu"
      style={{ marginTop: '25px' }}
      mode="inline"
      defaultSelectedKeys={['partners']}
      selectedKeys={[pathname]}
    >
      {routes[authRole]
        ?.filter(route => !route.hide && route.roles?.includes(userRole))
        ?.map(route => (
          <Menu.Item key={route.path}>
            {route.title}
            <Link to={route.path} />
          </Menu.Item>
        ))}
    </Menu>
  )
}
