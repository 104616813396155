import { Form, Modal } from 'antd'
import React, { useEffect, useMemo } from 'react'

import {
  BankAccountFormItem,
  BankNameFormItem,
  BankBikFormItem,
  NameFormItem
} from '../../../Common'
import {
  validateBankAccount,
  validateBankAccountIndividual,
  validateBankAccountSelfEmployed,
  validateBankBik,
  validateFullBankAccount,
  validateTitle
} from '../../../../helper'
import { errors, profileScreen } from '../../../../translates'

const layout = {
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  },
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 }
  }
}

const EditBankDetailsData = ({
  visible = false,
  onCancel = () => {},
  loading = false,
  bankDetails,
  partnerId,
  onChange,
  isIndividual = false
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (bankDetails) {
      form.setFieldsValue({ bankDetails })
    }
  }, [bankDetails])

  const handleOnSave = () => {
    form.validateFields().then(fields => {
      if (!form.isFieldsTouched()) return onCancel()
      onChange({ data: fields.bankDetails, id: partnerId })
    })
  }
  const okButtonProps = useMemo(() => ({ className: 'green', loading }), [loading])

  const handleChangeBik = () => {
    if (form?.getFieldValue(['bankDetails', profileScreen.input.bankAccount.name])) {
      form.validateFields([['bankDetails', profileScreen.input.bankAccount.name]])
    }
  }

  return (
    <Modal
      width={700}
      visible={visible}
      title="Редактирование банковских данных партнера"
      onCancel={onCancel}
      okText="Сохранить"
      cancelText="Отменить"
      okButtonProps={okButtonProps}
      onOk={handleOnSave}
      destroyOnClose
    >
      <Form {...layout} name="editPartnerBankDetailsData" form={form} scrollToFirstError>
        <NameFormItem
          name={['bankDetails', 'name']}
          requiredRule={false}
          placeholder="Иванов Иван Иванович"
          label="ФИО Банковских реквизитов"
          size="middle"
        />
        <BankAccountFormItem
          size="middle"
          rules={[
            {
              required: true,
              message: profileScreen.input.bankAccount.message,
              validator: validateBankAccount
            },
            ...(isIndividual
              ? [
                  {
                    message: errors.bankAccount,
                    validator: validateBankAccountIndividual
                  }
                ]
              : [
                  {
                    // selfEmployed
                    message: errors.bankAccountSelf,
                    validator: validateBankAccountSelfEmployed
                  }
                ]),
            {
              message: errors.bankBikAccount,
              validator: (rule, value) =>
                validateFullBankAccount({
                  rule,
                  account: value,
                  bik: form?.getFieldValue(['bankDetails', profileScreen.input.bankBik.name])
                })
            }
          ]}
          classNameInput="noScrollNumber"
        />
        <BankBikFormItem
          size="middle"
          validator={validateBankBik}
          onChange={handleChangeBik}
          classNameInput="noScrollNumber"
        />
        <BankNameFormItem size="middle" validator={validateTitle} />
      </Form>
    </Modal>
  )
}
export default EditBankDetailsData
