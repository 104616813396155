import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { Input, Row, Col, Typography, Divider, message, Button, Space, Checkbox } from 'antd'

import {
  createCheckedKeysForOrgTree,
  createUserScopeFromCheckedKeys,
  simpleReducer,
  sortByName
} from '../../../helper'
import OrgTree from '../../../components/Admin/Tree/OrgTree'
import { ConfigContext } from '../../../context/configContext'
import { useOrgTreeQuery } from '../../../api/react-query/org'
import { twoMinutes } from '../../../Constants'
import { useMutateUserChangeScope } from '../../../api/react-query/users'
import ChainSelector from '../../../components/ChainSelector/ChainSelector'
import { useChains } from '../../../hooks'

const { Title } = Typography
const inputGroupAndTitleHeight = 232
const titleHeight = 110

export default function UserStores() {
  const { user } = useContext(ConfigContext)
  const initState = {
    chains: [],
    orgTree: [],
    checkedKeys: [],
    filteredCity: '',
    filteredChain: '',
    isEditMode: false
  }
  const [state, setState] = useReducer(simpleReducer, initState)
  const [countOfStores, setCountOfStores] = useState(0)

  const containerRef = useRef(null)

  useEffect(() => {
    const checkedKeys = createCheckedKeysForOrgTree(user?.visibilityScope?.cities, state.orgTree)
    setState({ checkedKeys })
  }, [user])

  const {
    data: treeData,
    isError: isErrorTree,
    isLoading: isLoadingTree,
    isSuccess: isSuccessTree
  } = useOrgTreeQuery(
    {
      withClosed: true,
      withEmptyCity: true,
      withOpened: true
    },
    {
      staleTime: twoMinutes,
      enabled: state.isEditMode
    }
  )
  useEffect(() => {
    if (isErrorTree) {
      message.error('Ошибка доступа к данным')
    }
  }, [isErrorTree])
  useEffect(() => {
    if (isSuccessTree) {
      setState({
        orgTree: sortByName(treeData?.cities)
      })
      setCountOfStores(
        treeData?.cities?.length +
          treeData?.cities?.reduce((prev, curr) => {
            prev +=
              curr.chains?.length +
              curr.chains.reduce((prev, curr) => {
                prev += curr.stores?.length
                return prev
              }, 0)
            return prev
          }, 0)
      )
    }
  }, [isSuccessTree, treeData])

  const { data: chains } = useChains(
    { city: '' },
    {
      staleTime: twoMinutes,
      enabled: state.isEditMode
    }
  )
  useEffect(() => {
    if (chains) {
      setState({ chains: chains?.map(c => c.name) })
    }
  }, [chains])

  const {
    mutate: mutateUserScope,
    isError: isErrorUserScope,
    isLoading: isLoadingUserScope,
    isSuccess: isSuccessUserScope
  } = useMutateUserChangeScope()
  useEffect(() => {
    if (isErrorUserScope) {
      message.error('Ошибка обновления магазинов')
    }
  }, [isErrorUserScope])
  useEffect(() => {
    if (isSuccessUserScope) {
      message.success('Магазины успешно обновлены')
      setState({
        isEditMode: false
      })
    }
  }, [isSuccessUserScope])

  const handleUpdateScope = () => {
    const newScope = createUserScopeFromCheckedKeys(state.checkedKeys)
    const body = {
      visibilityScope: { cities: newScope }
    }
    mutateUserScope(body)
  }

  const handleChangeCity = ({ target }) => setState({ filteredCity: target.value })
  const handleSetEditMode = () => setState({ isEditMode: true })
  const onCheck = checkedKeys => setState({ checkedKeys })
  const handleOnSelect = useCallback(value => setState({ filteredChain: value }), [])
  const handleOnClear = useCallback(() => setState({ filteredChain: '' }), [])
  const handleCheckAll = ({ target }) => {
    if (target.checked) {
      const data = createCheckedKeysForOrgTree(state.orgTree, state.orgTree)
      setState({ checkedKeys: data })
    } else {
      const data = createCheckedKeysForOrgTree([], state.orgTree)
      setState({ checkedKeys: data })
    }
  }

  return (
    <div ref={containerRef} className="tree-container">
      <Title level={3} className="title-management">
        Мои магазины
      </Title>
      {state.isEditMode && (
        <>
          <Input.Group size="large">
            <Row gutter={8}>
              <Col span={5}>
                <Input placeholder="Введите город" onChange={handleChangeCity} allowClear />
              </Col>
              <Col span={5}>
                <ChainSelector
                  onSelect={handleOnSelect}
                  onClear={handleOnClear}
                  chains={state.chains}
                />
              </Col>
            </Row>
          </Input.Group>
          <Divider />
        </>
      )}
      {state.isEditMode ? (
        <>
          {!isErrorTree && (
            <Checkbox
              disabled={isLoadingTree}
              className="mb-3"
              onChange={handleCheckAll}
              checked={state.checkedKeys.length === countOfStores}
            >
              Выбрать все
            </Checkbox>
          )}
          <OrgTree
            checkable
            onCheck={onCheck}
            checkedKeys={state.checkedKeys}
            filteredCity={state.filteredCity}
            filteredChain={state.filteredChain}
            height={containerRef?.current?.offsetHeight - inputGroupAndTitleHeight || 100}
          />
        </>
      ) : (
        <OrgTree
          treeData={user?.visibilityScope}
          height={containerRef?.current?.offsetHeight - titleHeight || 100}
        />
      )}
      <Space className="mt-3">
        <Button
          onClick={state.isEditMode ? handleUpdateScope : handleSetEditMode}
          type="primary"
          className="mt-3"
          loading={isLoadingUserScope}
        >
          {state.isEditMode ? 'Сохранить' : 'Редактировать магазины'}
        </Button>
        {state.isEditMode && (
          <Button
            danger
            onClick={() => setState({ isEditMode: false })}
            className="mt-3"
            disabled={isLoadingUserScope}
          >
            Отмена
          </Button>
        )}
      </Space>
    </div>
  )
}
