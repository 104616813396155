import React from 'react'
import { Layout, Image } from 'antd'
import './MobileHeader.css'

const { Header } = Layout

const MobileHeader = () => (
  <Header className="MobileHeader">
    <Image src="/img/logo-sbl-2.png" preview={false} />
  </Header>
)
export default MobileHeader
