import React, { useEffect, useReducer } from 'react'
import './DeclineReasons.css'
import { Button, ConfigProvider, message, Space, Table, Modal } from 'antd'
import { EditOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { getTargetsForDeclineReasons, simpleReducer } from '../../../helper'
import ReasonModal from './ReasonModal'
import ruRu from 'antd/es/locale/ru_RU'
import { useDeclineReasonsQuery, useMutateDeclineReasonDelete } from '../../../api/react-query/dict'

const { confirm } = Modal

const initialState = {
  isOpenModal: false,
  reasonList: null,
  targets: {},
  currentReason: null
}

function DeclineReasons() {
  const [state, setState] = useReducer(simpleReducer, initialState)

  const handleOpenModal = () => {
    setState({ isOpenModal: true })
  }

  const handleCloseModal = () => {
    setState({ isOpenModal: false, currentReason: null })
  }

  const {
    data: dataDeclineReasons,
    isSuccess: isSuccessReasons,
    isError: isErrorReasons,
    isLoading: isLoadingReasons
  } = useDeclineReasonsQuery()

  useEffect(() => {
    if (isSuccessReasons && dataDeclineReasons?.isSuccess) {
      setState({ reasonList: dataDeclineReasons.result })
    } else if (isErrorReasons || dataDeclineReasons?.errorMessage) {
      message.error('Ошибка получения причин отказа')
    }
  }, [dataDeclineReasons, isSuccessReasons, isErrorReasons])

  const {
    mutate: deleteDeclineReason,
    data: dataDelete,
    isSuccess: isSuccessDelete,
    isError: isErrorDelete,
    isLoading: isLoadingDelete
  } = useMutateDeclineReasonDelete()

  useEffect(() => {
    if (isSuccessDelete && dataDelete?.isSuccess) {
      message.success('Элемент удален')
    } else if (isErrorDelete || dataDelete?.errorMessage) {
      message.error(dataDelete?.errorMessage || 'Ошибка удаления причины отказа')
    }
  }, [dataDelete, isSuccessDelete, isErrorDelete])

  useEffect(() => {
    setState({ targets: getTargetsForDeclineReasons() })
  }, [getTargetsForDeclineReasons])

  const handleDelete = row => {
    confirm({
      title: 'Удалить элемент?',
      icon: <ExclamationCircleFilled />,
      onOk() {
        deleteDeclineReason(row.id)
      }
    })
  }

  const handleEdit = row => {
    setState({ currentReason: row })
    handleOpenModal()
  }

  const columns = [
    {
      title: 'Раздел',
      dataIndex: 'target',
      render: target => state.targets[target] || target,
      width: 400
    },
    {
      title: 'Причина',
      dataIndex: 'reason',
      render: (reason, record) =>
        `${reason}${record?.description ? ` - ${record?.description}` : ''}`
    },
    {
      title: 'Действия',
      render: row => (
        <>
          <Button icon={<EditOutlined />} onClick={() => handleEdit(row)} className="mr-3" />
          <Button icon={<DeleteOutlined />} onClick={() => handleDelete(row)} />
        </>
      )
    }
  ]

  return (
    <div className="decline-reasons">
      <Space style={{ marginBottom: 16 }}>
        <Button onClick={handleOpenModal}>Добавить шаблон отказа</Button>
      </Space>
      <ConfigProvider locale={ruRu}>
        <Table
          columns={columns}
          loading={isLoadingReasons || isLoadingDelete}
          rowKey="id"
          dataSource={state.reasonList}
        />
      </ConfigProvider>
      {state.isOpenModal && (
        <ReasonModal
          visible={state.isOpenModal}
          onClose={handleCloseModal}
          reasonData={state.currentReason}
        />
      )}
    </div>
  )
}

export default DeclineReasons
