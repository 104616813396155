import { Form, Input } from 'antd'
import React from 'react'
import { adminValidationErrors } from '../../../translates/errors'
import { profileScreen } from '../../../translates'

const BankBikFormItem = ({
  label = 'БИК',
  rules = undefined,
  requiredRule = true,
  messageRule = adminValidationErrors.bankBik,
  size = 'large',
  placeholder = '044540132',
  validator,
  classNameInput = '',
  onChange = () => {},
  disabled = false
}) => {
  return (
    <Form.Item
      name={['bankDetails', profileScreen.input.bankBik.name]}
      label={label}
      rules={
        rules ?? [
          { required: requiredRule, message: messageRule, ...(validator ? { validator } : {}) }
        ]
      }
    >
      <Input
        type="number"
        size={size}
        placeholder={placeholder}
        className={classNameInput}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  )
}
export default BankBikFormItem
