import { Form, Select } from 'antd'
import React from 'react'
import { profileScreen } from '../../../translates'
import { useStores as useOrgStores } from '../../../hooks'

const { Option } = Select

const StoreFormItem = ({
  city = '',
  chain = '',
  label = profileScreen.input.store.label,
  rules = undefined,
  requiredRule = true,
  messageRule = profileScreen.input.store.message,
  size = 'large',
  disabled = false,
  placeholder = profileScreen.input.store.placeholder,
  onChange = () => {},
  onDemand = false,
  hidden = false,
  disabledQuery = false
}) => {
  const { data: stores, isLoading } = useOrgStores(
    {
      filter: {
        ...(onDemand && { onDemand }),
        city,
        chain
      }
    },
    {
      enabled: !!(city && chain) && !disabledQuery
    }
  )
  return (
    <Form.Item
      name={['personalData', 'store']}
      label={label}
      rules={rules ?? [{ required: requiredRule, message: messageRule }]}
      hidden={hidden}
    >
      <Select
        disabled={disabled}
        showSearch
        size={size}
        placeholder={placeholder}
        onChange={value => onChange(stores.find(item => item.name === value))}
        loading={isLoading}
      >
        {!isLoading &&
          stores.map(c => (
            <Option key={c.name} value={c.name}>
              {c.name}
            </Option>
          ))}
      </Select>
    </Form.Item>
  )
}
export default StoreFormItem
