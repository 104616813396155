import React, { useState } from 'react'
import { api } from '../../../../api/react-query/api'
import { Button, message, Upload } from 'antd'
import { maxSizeUploadFileMB } from '../../../../Constants'
import { AppConfig } from '../../../../AppConfig'
import { isValidFileSize, isValidFileType } from '../../../../helper'

function UploadDocumentButton({ document, name = 'Загрузить', uploadUrl, onSuccess, onError }) {
  const [isUploading, setIsUploading] = useState(false)

  const uploadProps = {
    accept: Object.values(AppConfig.uploadTypes).join(', '),
    maxCount: 1,
    name: 'file',
    customRequest: ({ file }) => {
      const formData = new FormData()
      formData.append('file', file)
      setIsUploading(true)
      api
        .put({
          path: uploadUrl,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(data => {
          setIsUploading(false)
          if (data.isSuccess) {
            onSuccess(data)
          } else if (data.errorMessage) {
            onError(data.errorMessage)
          }
        })
        .catch(e => {
          console.log(e)
          setIsUploading(false)
          onError('Не удалось загрузить фото')
        })
    },
    beforeUpload: file => {
      const maxSize = maxSizeUploadFileMB[document] || maxSizeUploadFileMB.default
      if (!isValidFileSize(file.size, maxSize)) {
        message.error(
          file?.size === 0
            ? 'Размер файла должен быть больше 0 МБ'
            : `Превышен максимальный размер файла ${maxSize} МБ.`
        )
      }
      if (!isValidFileType(file.type, AppConfig.uploadTypes)) {
        message.error(
          `${file.name} имеет неверный формат (доступно: ${Object.keys(AppConfig.uploadTypes).join(', ')})`
        )
      }
      return (
        isValidFileSize(file.size, maxSize) && isValidFileType(file.type, AppConfig.uploadTypes)
      )
    },
    itemRender: () => null
  }

  return (
    <Upload {...uploadProps}>
      <Button type="primary" size="small" loading={isUploading}>
        {name}
      </Button>
    </Upload>
  )
}

export default UploadDocumentButton
