import { Select } from 'antd'
import React from 'react'

const { Option } = Select
const filterOption = (input, option) =>
  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
const filterSort = (optionA, optionB) =>
  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())

export default function ChainSelector({
  onSelect = () => {},
  onClear = () => {},
  chains = [],
  styles = { width: '100%' },
  showSearch = true,
  allowClear = true,
  placeholder = 'Введите сеть',
  optionFilterProp = 'children'
}) {
  return (
    <Select
      showSearch={showSearch}
      allowClear={allowClear}
      style={styles}
      placeholder={placeholder}
      optionFilterProp={optionFilterProp}
      filterOption={filterOption}
      filterSort={filterSort}
      onSelect={onSelect}
      onClear={onClear}
    >
      {chains.map(chain => (
        <Option value={chain} key={chain}>
          {chain}
        </Option>
      ))}
    </Select>
  )
}
