import React, { useEffect, useMemo, useRef, useContext } from 'react'
import { Button, Carousel, Divider, List, Space, Spin, message, Row, Col } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import Approval from '../../Approval/Approval'
import { UNKNOWN_DOC } from '../../../../Constants'
import DocumentStatus from './DocumentStatus'
import './PartnerDocuments.css'
import { useDocumentTypesQuery } from '../../../../api/react-query/dict'
import { profileScreen } from '../../../../translates'
import { partnerStatusCodes } from '../../../../AppConfig'
import UploadDocumentButton from './UploadDocumentButton'
import DocumentPreview from './DocumentPreview'
import { ConfigContext } from '../../../../context/configContext'

function PartnerDocuments({
  partnerId,
  profile,
  documents,
  loading,
  onCommit,
  setPartner,
  getDocumentStatus,
  getDocumentButton,
  correctForm
}) {
  const {
    isAdmin,
    isCoordinators,
    isCoordinator,
    isSupervisor,
    isPersonalManager,
    isSecurity,
    isContentCoordinator,
    isResearcherCoordinator,
    isPartnerSupport,
    isExternalCoordinator
  } = useContext(ConfigContext)
  const slider = useRef(null)
  const handleCommit = (d, o, c) => onCommit(d, o, c, () => slider?.current?.next())

  const iconStyle = { fontSize: '40px' }
  const buttonStyle = { height: '64px' }

  const { data, isLoading, isError } = useDocumentTypesQuery()
  const documentsTypes = useMemo(
    () => Object.fromEntries(data?.result?.map(item => [item.id, item.name]) || []),
    [data]
  )

  useEffect(() => {
    if (isError || data?.errorMessage) {
      message.error('Не удалось получить типы документов')
    }
  }, [isError, data])

  if (isLoading) {
    return <Spin spinning />
  }

  return (
    <Space direction="vertical" className="PartnerDocuments">
      <List
        size="small"
        bordered
        dataSource={documents
          .filter(d => profile?.[d]?.fileId || d === profileScreen.input.vaccinacionPhoto.name)
          .filter(
            d =>
              d !== profileScreen.input.vaccinacionPhoto.name ||
              profile.statusCode !== partnerStatusCodes.archive ||
              profile.vaccinacionPhoto?.fileId
          )
          .filter(
            // вакцину показываем для всех кто не СВ, ПМ, СБ, KK. для СВ или ПМ или СБ или КК показываем только если есть сам файл
            d =>
              d !== profileScreen.input.vaccinacionPhoto.name ||
              //   d === profileScreen.input.vaccinacionPhoto.name &&
              //   !isSupervisor &&
              //   !isPersonalManager &&
              //   !isSecurity &&
              //   !isContentCoordinator &&
              //   !isResearcherCoordinator
              // ) || (
              (d === profileScreen.input.vaccinacionPhoto.name &&
                (isSupervisor ||
                  isPersonalManager ||
                  isSecurity ||
                  isContentCoordinator ||
                  isResearcherCoordinator) &&
                profile?.[d]?.fileId)
          )
          .map(d => ({
            id: d,
            name: documentsTypes[d] ?? UNKNOWN_DOC,
            status: getDocumentStatus?.(d)
          }))}
        renderItem={item => (
          <List.Item>
            <Row align="middle" justify="space-between" className="w-100">
              <Col>
                {(item.id !== profileScreen.input.vaccinacionPhoto.name ||
                  profile?.[item.id]?.fileId) && <DocumentStatus status={item.status} />}
                <span className="ml-2">{item.name}</span>
              </Col>
              {item.id === profileScreen.input.vaccinacionPhoto.name &&
                !getDocumentButton &&
                !isSupervisor &&
                !isPersonalManager &&
                !isSecurity &&
                !isExternalCoordinator && (
                  <Col>
                    <UploadDocumentButton
                      document={item.id}
                      name={profile?.[item.id]?.fileId ? 'Обновить' : 'Загрузить'}
                      uploadUrl={`admin/partners/${partnerId}/document/vaccinacionPhoto`}
                      onSuccess={data => setPartner(data.partner)}
                      onError={error => message.error(error)}
                    />
                  </Col>
                )}
              {getDocumentButton && <Col>{getDocumentButton(item.id)}</Col>}
            </Row>
          </List.Item>
        )}
      />
      <Carousel ref={slider}>
        {documents
          .filter(d => profile?.[d]?.fileId)
          .map(d => (
            <div key={d}>
              <h2>{documentsTypes[d] ?? UNKNOWN_DOC}</h2>
              <DocumentPreview
                document={profile?.[d]}
                title={documentsTypes[d] ?? UNKNOWN_DOC}
                partnerId={partnerId}
                documentType={d}
                correctForm={correctForm}
              />
              <div>
                <Button style={buttonStyle} onClick={() => slider?.current?.prev()} type="link">
                  <ArrowLeftOutlined style={iconStyle} />
                </Button>
                <Button style={buttonStyle} onClick={() => slider?.current?.next()} type="link">
                  <ArrowRightOutlined style={iconStyle} />
                </Button>
              </div>
              {profile?.[d]?.allowReview && (
                <>
                  <Divider />
                  <Approval
                    defaultOutcome={profile?.[d]?.status}
                    defaultComment={profile?.[d]?.statusReason}
                    loading={loading}
                    onCommit={({ outcome, comment }) => handleCommit(d, outcome, comment)}
                    targetType={d}
                    autocomplete={Boolean(
                      isAdmin ||
                        isCoordinators ||
                        isCoordinator ||
                        isPartnerSupport ||
                        isExternalCoordinator
                    )}
                  />
                </>
              )}
            </div>
          ))}
      </Carousel>
    </Space>
  )
}

export default PartnerDocuments
