import { message, Modal, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'

import { useActByIdQuery, useActTemplateTagsQuery } from '../../../api/react-query/acts'
import { useActTypesQuery } from '../../../api/react-query/dict'
import { AppConfig } from '../../../AppConfig'

const { Paragraph } = Typography

const ActTemplatePreview = ({ id, onClose }) => {
  const [actTypes, setActTypes] = useState([])
  const [replacementTags, setReplacementTags] = useState([])
  const [templatePreview, setTemplatePreview] = useState('')
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)

  const handleCloseViewModal = () => {
    onClose()
    setIsViewModalOpen(false)
  }

  useEffect(() => {
    if (id) setIsViewModalOpen(true)
  }, [id])

  const {
    data: actByIdData,
    isError: isErrorActById,
    isLoading: isLoadingActById,
    isSuccess: isSuccessActById
  } = useActByIdQuery(id, { enabled: !!id })

  useEffect(() => {
    if (isSuccessActById) {
      let content = actByIdData?.template?.content
      replacementTags.forEach(tag => {
        const re = new RegExp(tag.token.replace(/[{}|]/g, '\\$&'), 'g')
        content = content.replace(re, tag.example)
      })

      setTemplatePreview(content)
    } else if (isErrorActById) {
      console.error(actByIdData)
      message.error('Ошибка получения шаблона акта')
    }
  }, [replacementTags, actByIdData, isSuccessActById, isErrorActById])

  const {
    data: actTypesData,
    isError: isErrorActTypes,
    isSuccess: isSuccessActTypes
  } = useActTypesQuery({})

  useEffect(() => {
    if (isSuccessActTypes) {
      setActTypes(actTypesData?.result)
    } else if (isErrorActTypes) {
      console.error(actTypesData)
      message.error('Ошибка получения списка типов актов')
    }
  }, [actTypesData, isErrorActTypes, isSuccessActTypes])

  const {
    data: actTemplateTagsData,
    isError: isErrorActTemplateTags,
    isSuccess: isSuccessActTemplateTags
  } = useActTemplateTagsQuery({})

  useEffect(() => {
    if (isSuccessActTemplateTags) {
      const insertedTags = actTemplateTagsData?.result?.map(tag => ({
        value: tag.name
      }))
      setReplacementTags(
        actTemplateTagsData?.result?.map(tag => ({
          pattern: '[[' + JSON.stringify(insertedTags.find(t => tag.name === t.value)) + ']]',
          example: tag.example,
          token: tag.token
        }))
      )
    } else if (isErrorActTemplateTags) {
      console.error(actTemplateTagsData)
      message.error('Ошибка получения списка тэгов шаблона')
    }
  }, [actTemplateTagsData, isErrorActTemplateTags, isSuccessActTemplateTags])

  return (
    <Modal
      width={850}
      style={{ top: 40 }}
      visible={isViewModalOpen}
      title={`${actTypes.find(type => type.id === actByIdData?.template?.actType)?.name} от ${moment(actByIdData?.template?.createdAt).format(AppConfig.formats.shortDate)}`}
      onCancel={handleCloseViewModal}
      okText="Ок"
      cancelButtonProps={{ className: 'hide' }}
      onOk={handleCloseViewModal}
      destroyOnClose
    >
      <Spin spinning={isLoadingActById}>
        <Paragraph className="document">{templatePreview}</Paragraph>
      </Spin>
    </Modal>
  )
}
export default ActTemplatePreview
