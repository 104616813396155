import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import {
  Typography,
  Modal,
  Divider,
  Table,
  ConfigProvider,
  Button,
  Space,
  Row,
  Col,
  Input,
  message
} from 'antd'
import { CheckCircleFilled, EditOutlined } from '@ant-design/icons'
import ruRu from 'antd/es/locale/ru_RU'

import './CityManagement.css'
import { simpleReducer } from '../../../helper'
import getColumnSearchProps from '../../../components/SearchFilter/SearchFilter'
import { useCities } from '../../../hooks'
import {
  useMutateAddCity,
  useMutateDeleteCity,
  useMutateUpdateCity
} from '../../../api/react-query/org'

const { Title, Text } = Typography

export default function CityManagement() {
  const initState = {
    cities: [],
    filtered: {},
    isModalOpen: false,
    cityName: '',
    selectedCity: null,
    isNew: false,
    isEdit: false
  }
  const [state, setState] = useReducer(simpleReducer, initState)

  const containerRef = useRef(null)

  const { data: dataCities, isLoading: isLoadingCities } = useCities()

  useEffect(() => {
    if (dataCities) {
      setState({
        cities: dataCities
      })
    }
  }, [dataCities])

  const {
    mutate: mutateAddCity,
    data: dataAddCity,
    isError: isErrorAddCity,
    isLoading: isLoadingAddCity,
    isSuccess: isSuccessAddCity
  } = useMutateAddCity()

  useEffect(() => {
    if (isErrorAddCity || dataAddCity?.errorMessage) {
      message.error(dataAddCity?.errorMessage || 'Ошибка добавления города')
    } else if (isSuccessAddCity) {
      setState({
        isModalOpen: false,
        cityName: '',
        isNew: false
      })
      message.success('Город успешно добавлен')
    }
  }, [isErrorAddCity, isSuccessAddCity, dataAddCity])

  const {
    mutate: mutateUpdateCity,
    data: dataUpdateCity,
    isError: isErrorUpdateCity,
    isLoading: isLoadingUpdateCity,
    isSuccess: isSuccessUpdateCity
  } = useMutateUpdateCity()

  useEffect(() => {
    if (isErrorUpdateCity || dataUpdateCity?.errorMessage) {
      message.error(dataUpdateCity?.errorMessage || 'Ошибка обновления города')
    } else if (isSuccessUpdateCity) {
      setState({
        isModalOpen: false,
        cityName: '',
        selectedCity: null,
        isEdit: false
      })
      message.success('Город успешно обновлен')
    }
  }, [isErrorUpdateCity, isSuccessUpdateCity, dataUpdateCity])

  const {
    mutate: mutateDeleteCity,
    data: dataDeleteCity,
    isError: isErrorDeleteCity,
    isLoading: isLoadingDeleteCity,
    isSuccess: isSuccessDeleteCity
  } = useMutateDeleteCity()

  useEffect(() => {
    if (isErrorDeleteCity || dataDeleteCity?.errorMessage) {
      message.error(dataDeleteCity?.errorMessage || 'Ошибка удаления города')
    } else if (isSuccessDeleteCity) {
      setState({
        isModalOpen: false,
        cityName: '',
        selectedCity: null,
        isEdit: false
      })
      message.success('Город успешно удален')
    }
  }, [isErrorDeleteCity, isSuccessDeleteCity, dataDeleteCity])

  const handleOpenNewModal = useCallback(
    () =>
      setState({
        isModalOpen: true,
        cityName: '',
        isNew: true,
        isEdit: false
      }),
    []
  )

  const handleEditCity = useCallback(
    city =>
      setState({
        isModalOpen: true,
        cityName: city.name,
        selectedCity: city,
        isNew: false,
        isEdit: true
      }),
    []
  )

  const handleOnCancel = useCallback(
    () =>
      setState({
        isModalOpen: false,
        cityName: '',
        selectedCity: null,
        isNew: false,
        isEdit: false
      }),
    []
  )

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      ...getColumnSearchProps('name', 'по наименованию'),
      sorter: true,
      sortOrder:
        state.filtered?.orderBy === 'name' && state.filtered?.orderDir
          ? state.filtered?.orderDir + 'end'
          : false,
      filteredValue: state.filtered?.filter?.name ? [state.filtered?.filter?.name] : null
    },
    {
      title: 'Заблокирован',
      dataIndex: 'status',
      width: 260,
      align: 'center',
      render: value =>
        value === 'active' ? (
          <Text>нет</Text>
        ) : (
          <CheckCircleFilled style={{ color: 'red', fontSize: 18 }} />
        )
    },
    {
      title: '',
      dataIndex: 'id',
      render: (value, item) => (
        <div onClick={() => handleEditCity(item)} className="full-block">
          <EditOutlined style={{ color: 'blue', fontSize: 18 }} />
        </div>
      ),
      width: 60
    }
  ]

  const handleOnSave = () => {
    state.isNew
      ? mutateAddCity({ name: state.cityName })
      : mutateUpdateCity({ name: state.cityName, id: state.selectedCity.id })
  }
  const handleEditStatus = () => {
    mutateUpdateCity({
      name: state.cityName,
      id: state.selectedCity.id,
      status: state.selectedCity.status === 'blocked' ? 'active' : 'blocked'
    })
  }
  const handleDeleteCity = () => {
    mutateDeleteCity({
      id: state.selectedCity.id
    })
  }

  const okButtonProps = useMemo(
    () => ({
      className: 'green',
      loading: isLoadingAddCity || isLoadingUpdateCity || isLoadingDeleteCity
    }),
    [isLoadingUpdateCity, isLoadingAddCity, isLoadingDeleteCity]
  )

  const cancelButtonProps = useMemo(
    () => ({
      disabled: isLoadingAddCity || isLoadingUpdateCity || isLoadingDeleteCity
    }),
    [isLoadingUpdateCity, isLoadingAddCity, isLoadingDeleteCity]
  )

  const handleTableChange = useCallback((pagination, filters, sorter) => {
    const filterArrType = ['city']
    let orderDir
    if (sorter?.order) {
      orderDir = sorter.order && (sorter.order === 'ascend' ? 'asc' : 'desc')
    }
    const filter = Object.entries(filters || {}).reduce(
      (r, c) => ({
        ...r,
        [c[0]]: c[1] ? (filterArrType.includes(c[0]) ? c[1] : c[1][0]) : undefined
      }),
      {}
    )

    const filtered = {
      // pageNumber: pagination.current,
      // pageSize: pagination.pageSize,
      filter,
      orderBy: sorter.field,
      orderDir
    }
    setState({
      filtered
    })
    // localStorage.setItem('actTemplatesRequest', JSON.stringify(actTemplatesList))
  }, [])

  const filteredList = useMemo(() => {
    const list = state?.filtered?.filter?.name
      ? state.cities?.filter(city =>
          city.name.toLowerCase().includes(state?.filtered?.filter.name.toLowerCase())
        )
      : undefined
    return state?.filtered?.orderDir
      ? [...(list || state.cities)]?.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? state?.filtered?.orderDir === 'asc'
              ? 1
              : -1
            : b.name.toLowerCase() > a.name.toLowerCase()
              ? state?.filtered?.orderDir === 'asc'
                ? -1
                : 1
              : 0
        )
      : list
  }, [state])

  return (
    <div ref={containerRef} className="tree-container">
      <Title level={3} className="title-management">
        Города
      </Title>
      <Divider />
      <Space style={{ marginBottom: 16 }}>
        <Button onClick={handleOpenNewModal}>Добавить город</Button>
      </Space>
      <ConfigProvider locale={ruRu}>
        <Table
          columns={columns}
          loading={isLoadingCities}
          // pagination={pagination}
          onChange={handleTableChange}
          rowKey="id"
          dataSource={filteredList || state.cities}
        />
      </ConfigProvider>
      <Modal
        width={500}
        visible={state.isModalOpen}
        title={state.isNew ? 'Добавление города' : 'Редактирование города'}
        onCancel={handleOnCancel}
        okText={state.isNew ? 'Добавить' : 'Изменить'}
        okButtonProps={okButtonProps}
        cancelButtonProps={cancelButtonProps}
        onOk={handleOnSave}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Input
              value={state.cityName}
              onChange={e => {
                setState({ cityName: e.target.value })
              }}
              placeholder="Название города"
            />
          </Col>
          {state.isEdit && (
            <Col span={24}>
              <Row gutter={24}>
                <Col>
                  <Button
                    danger={state.selectedCity.status !== 'blocked'}
                    onClick={handleEditStatus}
                    loading={isLoadingUpdateCity}
                    disabled={isLoadingDeleteCity}
                  >
                    {state.selectedCity.status === 'blocked' ? 'Разблокировать' : 'Заблокировать'}
                  </Button>
                </Col>
                <Col>
                  <Button
                    danger
                    onClick={handleDeleteCity}
                    loading={isLoadingDeleteCity}
                    disabled={isLoadingUpdateCity}
                  >
                    Удалить
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Modal>
    </div>
  )
}
